<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">連携履歴検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <brand-select v-model="brandId" :noSelectFlag="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-show="!this.shopFlag">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="this.superFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              連携情報種別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="LogKinds">
                <md-select v-model="kinds" name="kinds">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="initialization">初期化</md-option>
                  <md-option value="order">追加オーダー</md-option>
                  <md-option value="confirmation">会計確認</md-option>
                  <md-option value="report">品切れ報告</md-option>
                  <md-option value="callRequest">店員呼出要求</md-option>
                  <md-option value="synchronizationStart">データ同期開始</md-option>
                  <md-option value="synchronizationComplete">データ同期完了</md-option>
                  <md-option value="updateNotice">メニュー更新通知（おすすめ）</md-option>
                  <md-option value="checkOut">チェックアウト</md-option>
                  <md-option value="constructionNotice">メニュー構築通知</md-option>
                  <md-option value="rsync">rsync</md-option>
                  <md-option value="dbRebuildingComplete">DB再構築完了</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="LogStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="success">成功</md-option>
                  <md-option value="error">失敗</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              連携日時
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
          
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getHistories(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">連携履歴一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable">
                <div class="tHead">
                  <div class="tHead_th">ブランド名</div>
                  <div class="tHead_th">店舗名</div>
                  <div class="tHead_th">卓番</div>
                  <div class="tHead_th">連携情報種別</div>
                  <div class="tHead_th">連携日時</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.brandId }}</div>
                  <div class="tItem_td">{{ item.shopId }}</div>
                  <div class="tItem_td">{{ item.tableName }}</div>
                  <div class="tItem_td">{{ item.kind }}</div>
                  <div class="tItem_td">{{ item.createdAt | timeFormat }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg2"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from}}件から {{to}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getHistories">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import role from "../mixins/role.js"
  import swal from 'sweetalert2'

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect,
      BrandSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        brandId: '選択しない',
        shopId: '選択しない',
        kinds: '選択しない',
        status: '選択しない',
        selectedStart: '',
        selectedEnd: '',
        tableData: [],
        resultFlag: false,
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      getHistories (page) {
        var condition = {
          page: !isNaN(page) ? page : 1,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          kind: this.kinds === '選択しない' ? '' : this.kinds,
          status: this.status === '選択しない' ? '' : this.status,
          created_at: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          updated_at: this.selectedEnd === '' ? '' :  this.initDate(this.selectedEnd)
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/telegram_histories/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('連携履歴を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            brandId: res[i].attributes.brand && res[i].attributes.brand['name'],
            shopId: res[i].attributes.shop && res[i].attributes.shop['name'],
            tableName: res[i].attributes['table-name'],
            createdAt: res[i].attributes['created-at']
          }
          switch (res[i].attributes['kind']) {
            case 'initialization':
              item.kind = '初期化'
              break;
            case 'order':
              item.kind = '追加オーダー'
              break;
            case 'confirmation':
              item.kind = '会計確認'
              break;
            case 'report':
              item.kind = '品切れ報告'
              break;
            case 'callRequest':
              item.kind = '店員呼出要求'
              break;
            case 'synchronizationStart':
              item.kind = 'データ同期開始'
              break;
            case 'synchronizationComplete':
              item.kind = 'データ同期完了'
              break;
            case 'updateNotice':
              item.kind = 'メニュー更新通知（おすすめ）'
              break;
            case 'checkOut':
              item.kind = 'チェックアウト'
              break;
            case 'constructionNotice':
              item.kind = 'メニュー構築通知'
              break;
            case 'rsync':
              item.kind = 'rsync'
              break;
            case 'dbRebuildingComplete':
              item.kind = 'DB再構築完了'
              break;
            default:
              item.kind = '識別不明'
          }
          switch (res[i].attributes['status']) {
            case 'success':
              item.status = '成功'
              break;
            case 'error':
              item.status = '失敗'
              break;
            default:
              item.kind = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleEdit (id) {
        router.push({ path: '/admin/telegram_history/edit/' + id })
      },
      watch: {
        brandId () {
          this.$refs.shop.brandId = this.brandId
          this.$refs.shop.resetShop()
        }
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.wth100{
  width: 100px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.betweenTime{
  line-height: 59px;
}

.md-card{
  z-index: inherit;
}

.commentBody{
  max-width: 100px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
