<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">ブランド情報</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランドコード
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】16文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド名
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              対象事業者
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <company-select v-model="companyId" :touchedFlag="true"></company-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド紹介サイトURL
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('siteUrl')},
                {'md-valid': !errors.has('siteUrl') && touched.siteUrl}]">
                <md-input
                  v-model="siteUrl"
                  data-vv-name="siteUrl"
                  type="text"
                  v-validate= "modelValidations.siteUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('siteUrl')">半角英数記号のURL形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('siteUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('siteUrl') && touched.siteUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランドイメージカラー
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('color')},
                {'md-valid': !errors.has('color') && touched.color}]">
                <md-input
                  v-model="color"
                  data-vv-name="color"
                  type="text"
                  v-validate= "modelValidations.color">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('color')">【必須】16進数カラーコードを入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('color')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('color') && touched.color">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageInfo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageInfo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageInfo" data-id="imageInfo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageInfo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageInfo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              提携サービス名称
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subService')},
                {'md-valid': !errors.has('subService') && touched.subService}]">
                <md-input
                  v-model="subService"
                  data-vv-name="subService"
                  type="text"
                  v-validate= "modelValidations.subService">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subService')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subService')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subService') && touched.subService">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              提携サービス画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!subServiceLogo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="subServiceLogo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="subServiceLogo" data-id="subServiceLogo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!subServiceLogo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="subServiceLogo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              提携サービスURL
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subServiceUrl')},
                {'md-valid': !errors.has('subServiceUrl') && touched.subServiceUrl}]">
                <md-input
                  v-model="subServiceUrl"
                  data-vv-name="subServiceUrl"
                  type="text"
                  v-validate= "modelValidations.subServiceUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subServiceUrl')">半角英数記号のURL形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subServiceUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subServiceUrl') && touched.subServiceUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              かざPayアプリ
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('kazapayApp')},
                {'md-valid': !errors.has('kazapayApp') && touched.kazapayApp}]">
                <md-select
                  v-model="kazapayApp"
                  data-vv-name="kazapayApp"
                  v-validate= "modelValidations.kazapayApp"
                  required>
                  <md-option value="able">利用可能</md-option>
                  <md-option value="disable">利用不可</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kazapayApp')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kazapayApp')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kazapayApp') && touched.kazapayApp" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              REFD(返金)実行
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('isRefdEnable')},
                {'md-valid': !errors.has('isRefdEnable') && touched.isRefdEnable}]">
                <md-select
                  v-model="isRefdEnable"
                  data-vv-name="isRefdEnable"
                  v-validate= "modelValidations.isRefdEnable"
                  required>
                  <md-option value="true">実行可能</md-option>
                  <md-option value="false">実行不可</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('isRefdEnable')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('isRefdEnable')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('isRefdEnable') && touched.isRefdEnable" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              QRコード期限
            </label>
            <div class="md-layout-item md-size-20  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('qrPeriod')},
                {'md-valid': !errors.has('qrPeriod') && touched.qrPeriod}]">
                <md-input
                  v-model="qrPeriod"
                  data-vv-name="qrPeriod"
                  type="text"
                  v-validate= "modelValidations.qrPeriod">
                </md-input>
                <div>分</div>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('qrPeriod')">【必須】期限を入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('qrPeriod')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('qrPeriod') && touched.qrPeriod">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アプリの使い方
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('appInstruction')},
                {'md-valid': !errors.has('appInstruction') && touched.appInstruction}]">
                <md-input
                  v-model="appInstruction"
                  data-vv-name="appInstruction"
                  type="text"
                  v-validate= "modelValidations.appInstruction">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('appInstruction')">半角英数記号のURL形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('appInstruction')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('appInstruction') && touched.appInstruction">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スマートオーダー用ブランドコード
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('smartOrderCode')},
                {'md-valid': !errors.has('smartOrderCode') && touched.smartOrderCode}]">
                <md-input
                  v-model="smartOrderCode"
                  data-vv-name="smartOrderCode"
                  type="text"
                  v-validate= "modelValidations.smartOrderCode">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('smartOrderCode')">【必須】スマートオーダー用ブランドコードを入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('smartOrderCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('smartOrderCode') && touched.smartOrderCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スマートオーダー用ブランドイメージカラー
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('smartOrderColor')},
                {'md-valid': !errors.has('smartOrderColor') && touched.smartOrderColor}]">
                <md-input
                  v-model="smartOrderColor"
                  data-vv-name="smartOrderColor"
                  type="text"
                  v-validate= "modelValidations.smartOrderColor">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スマートオーダー用ブランド画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20 pl20">
              <div class="file-input">
                <div v-if="!smartOrderImage">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="smartOrderImage" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="smartOrderImage" data-id="smartOrderImage"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!smartOrderImage">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="smartOrderImage">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import swal from 'sweetalert2'
  import EventBus from '../mixins/event-bus.js';

  export default {
    mixins: [ role ],
    components: {
      CompanySelect
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        tableData: [],
        code: '',
        name: '',
        companyId: '',
        siteUrl: '',
        color: '',
        status: '',
        imageInfo: '',
        subService: '',
        subServiceLogo: '',
        subServiceUrl: '',
        kazapayApp: '',
        isRefdEnable: '',
        qrPeriod: '',
        appInstruction: '',
        smartOrderCode: '',
        smartOrderColor: '',
        smartOrderImage: '',
        touched: {
          code: false,
          name: false,
          companyId: false,
          siteUrl: false,
          color: false,
          status: false,
          subService: false,
          subServiceLogo: false,
          subServiceUrl: false,
          kazapayApp: false,
          isRefdEnable: false,
          qrPeriod: false,
          appInstruction: false,
          smartOrderCode: false,
          smartOrderColor: false,
          smartOrderImage: false
        },
        modelValidations: {
          code: {
            required: true,
            max: 16,
            alpha_num: true
          },
          name: {
            required: true,
            max: 255
          },
          companyId: {
            required: true
          },
          siteUrl: {
            url: true
          },
          color: {
            required: true
          },
          status:{
            required: true
          },
          subService: {
            max: 255
          },
          subServiceUrl: {
            url: true
          },
          kazapayApp:{
            required: true
          },
          isRefdEnable:{
            required: true
          },
          qrPeriod:{
            required: true
          },
          appInstruction:{
            url: true
          },
          smartOrderCode:{
            required: true
          },
          smartOrderColor:{
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/brand_list/' })
      },
      validate() {
        EventBus.$emit('validate', this.errors)
        this.$validator.validateAll().then(isValid => {
          if (this.errors.items.length == 0 ) {
            this.updateBrand()
          } else {
            this.validateError()
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      getBrands () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/brands/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.code = response.data.code
          this.name = response.data.name
          this.status = response.data.status
          this.companyId = response.data.company_id
          this.siteUrl = response.data.site_url
          this.color = response.data.color
          this.imageInfo = response.data.image.url
          this.subService = response.data.sub_service
          this.subServiceLogo = response.data.sub_service_logo.url
          this.subServiceUrl = response.data.sub_service_url
          this.kazapayApp = response.data.kazapay_app
          this.isRefdEnable = response.data.is_refd_enable
          this.qrPeriod = response.data.qr_period
          this.appInstruction = response.data.app_instruction
          this.smartOrderCode = response.data.smart_order_code
          this.smartOrderColor = response.data.smart_order_color
          this.smartOrderImage = response.data.smart_order_image.url
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ブランド情報を正しく取得できませんでした')
          }
        })
      },
      updateBrand () {
        var condition = {
          brand: {
            code: this.code,
            name: this.name,
            company_id: this.companyId,
            site_url: this.siteUrl,
            color: this.color,
            status: this.status,
            sub_service: this.subService,
            sub_service_url: this.subServiceUrl,
            kazapay_app: this.kazapayApp,
            is_refd_enable: this.isRefdEnable,
            qr_period: this.qrPeriod,
            app_instruction: this.appInstruction,
            smart_order_code: this.smartOrderCode,
            smart_order_color: this.smartOrderColor
          }
        }
        // 画像がURLかどうか判別して追加
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.brand.image = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.brand.image = this.imageInfo
        }
        if(this.subServiceLogo === '' || this.subServiceLogo === null){
          condition.brand.sub_service_logo = ''
        } else if (this.subServiceLogo.indexOf('/uploads/') == -1) {
          condition.brand.sub_service_logo = this.subServiceLogo
        }
        if(this.smartOrderImage === '' || this.smartOrderImage === null){
          condition.brand.smart_order_image = ''
        } else if (this.smartOrderImage.indexOf('/uploads/') == -1) {
          condition.brand.smart_order_image = this.smartOrderImage
        }
        console.log(this.smartOrderImage.indexOf('/uploads/') == -1)
        console.log(this.smartOrderImage.indexOf('/uploads/'))
        console.log(this.smartOrderImage)
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/brands/' + this.$route.params.id,
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal({
            title: `ブランド情報を更新しました`,
            buttonsStyling: false,
            type: 'success',
            confirmButtonClass: 'md-button md-success'
          }).then(response => {
              sessionStorage.removeItem('brandSelect')
              this.getBrands()
              router.push({ path: '/admin/brand_list/' })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ブランド情報を更新できませんでした')
          }
        })
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getBrands()
    },
    watch: {
      code () {
        this.touched.code = true
      },
      name () {
        this.touched.name = true
      },
      companyName () {
        this.touched.companyName = true
      },
      siteUrl () {
        this.touched.siteUrl = true
      },
      color () {
        this.touched.color = true
      },
      status () {
        this.touched.status = true
      },
      subService () {
        this.touched.subService = true
      },
      subServiceLogo () {
        this.touched.subServiceLogo = true
      },
      subServiceUrl () {
        this.touched.subServiceUrl = true
      },
      kazapayApp () {
        this.touched.kazapayApp = true
      },
      isRefdEnable () {
        this.touched.isRefdEnable = true
      },
      qrPeriod () {
        this.touched.qrPeriod = true
      },
      appInstruction () {
        this.touched.appInstruction = true
      },
      smartOrderCode () {
        this.touched.smartOrderCode = true
      },
      smartOrderColor () {
        this.touched.smartOrderColor = true
      },
      smartOrderImage () {
        this.touched.smartOrderImage = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mg2{
  margin: 0 2px;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.errorMsg{
  bottom: -15px !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
