<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">連携履歴詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド名
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ brandName }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ shopName }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              卓番
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ tableName }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ status }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              連携情報種別
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ kind }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              連携日時
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ createdAt | timeFormat }}</p>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  export default {
    data () {
      return {
        brandName: '',
        shopName: '',
        tableName: '',
        status: '',
        createdAt: ''
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/telegram_history_list/' })
      },
      getHistory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/telegram_histories/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.brandName = response.data.brand && response.data.brand.name
          this.shopName = response.data.shop && response.data.shop.name
          this.tableName = response.data.table_name
          this.status = this.statusChecker(response.data.status)
          this.kind = this.kindChecker(response.data.kind)
          this.createdAt = response.data.created_at
        })
        .catch(error => {
          this.dangerNotify('連携履歴の取得に失敗しました')
        })
      },
      statusChecker (status) {
        var telegramStatus
        switch (status) {
          case 'success':
              telegramStatus = '成功'
              break;
          case 'error':
            telegramStatus = '失敗'
            break;
          default:
            telegramStatus = '識別不明'
        }
        return telegramStatus
      },
      kindChecker (kind) {
        var kinds
        switch (kind) {
          case 'initialization':
            kinds = '初期化'
            break;
          case 'order':
            kinds = '追加オーダー'
            break;
          case 'confirmation':
            kinds = '会計確認'
            break;
          case 'report':
            kinds = '品切れ報告'
            break;
          case 'callRequest':
            kinds = '店員呼出要求'
            break;
          case 'synchronizationStart':
            kinds = 'データ同期開始'
            break;
          case 'synchronizationComplete':
            kinds = 'データ同期完了'
            break;
          case 'updateNotice':
            kinds = 'メニュー更新通知（おすすめ）'
            break;
          case 'checkOut':
            kinds = 'チェックアウト'
            break;
          case 'constructionNotice':
            kinds = 'メニュー構築通知'
            break;
          case 'rsync':
            kinds = 'rsync'
            break;
          default:
            kinds = '識別不明'
        }
        return kinds
      },
    },
    mounted () {
      this.getHistory()
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  min-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
