<template>
  <div class="md-layout md-layout-item md-size-100">
    <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
      {{categoryData.title}}
    </label>
    <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
      <div class="categoryContainer">
        <div class="bigCategory" v-for="item, index in categories" :key="item.id">
          <div class="categoryDetail">
            <div class="checkArea">
              <md-checkbox v-model="item.check" class="categoryCheck">{{item.name}}</md-checkbox>
              <div class="toggleBox">
                <md-button
                  class="md-just-icon md-simple"
                  @click.native="item.openFlag = !item.openFlag">
                  <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                  <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                </md-button>
              </div>
            </div>
          </div>
          <slide-y-up-transition>
            <div class="progenyCategory" v-show="item.openFlag">
              <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                <div class="categoryDetail">
                  <div class="checkArea">
                    <md-checkbox v-model="itemC.check" class="categoryCheck">{{itemC.name}}</md-checkbox>
                    <div class="toggleBox">
                      <md-button
                        class="md-just-icon md-simple"
                        @click.native="itemC.openFlag = !itemC.openFlag">
                        <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                        <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                      </md-button>
                    </div>
                  </div>
                </div>
                <slide-y-up-transition>
                  <div class="progenyCategory" v-show="itemC.openFlag">
                    <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                      <div class="categoryDetail">
                        <div class="checkArea">
                          <md-checkbox v-model="itemCC.check" class="categoryCheck">{{itemCC.name}}</md-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </slide-y-up-transition>
              </div>
            </div>
          </slide-y-up-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import router from '../../packs/router.js'

  export default {
    props: {
      categoryData: Object
    },
    data () {
      return {
        categories: [],
      }
    },
    methods: {
      getCategory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + this.categoryData.apiUrl,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createNewCategoryList(response.data)
          this.$emit('get-categories', this.categories);//親へイベント発火を伝える// 第二引数で子のデータを格納
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.categoryData.title + '情報が取得できませんでした')
          }
        })
      },
      createNewCategoryList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false,
            check: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
      }
    },
    mounted () {
      this.getCategory()
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.pt20{
  padding-top: 20px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}

</style>