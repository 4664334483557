import {en, ja} from 'vuejs-datepicker/dist/locale'
import vuejsDatepicker from 'vuejs-datepicker'

const VueJsDatePickerConfig = {
  install: function(Vue) {
    Vue.component( "vuejsDatepicker", vuejsDatepicker )
    Vue.prototype.ja = ja
    Vue.prototype.DatePickerFormat = 'yyyy-MM-dd'
  }
}
export default VueJsDatePickerConfig