<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">事業者情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('companyName')},
                {'md-valid': !errors.has('companyName') && touched.companyName}]">
                <md-input
                  v-model="companyName"
                  data-vv-name="companyName"
                  type="text"
                  v-validate= "modelValidations.companyName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者コード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('companyCode')},
                {'md-valid': !errors.has('companyCode') && touched.companyCode}]">
                <md-input
                  v-model="companyCode"
                  data-vv-name="companyCode"
                  type="text"
                  v-validate= "modelValidations.companyCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyCode')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyCode') && touched.companyCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者WEBサイトURL
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('siteUrl')},
                {'md-valid': !errors.has('siteUrl') && touched.siteUrl}]">
                <md-input
                  v-model="siteUrl"
                  data-vv-name="siteUrl"
                  type="text"
                  v-validate= "modelValidations.siteUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('siteUrl')">半角英数記号のURL形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('siteUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('siteUrl') && touched.siteUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済情報保有期間
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('retentionPeriod')},
                {'md-valid': !errors.has('retentionPeriod') && touched.retentionPeriod}]">
                <md-input
                  v-model="retentionPeriod"
                  data-vv-name="retentionPeriod"
                  type="number"
                  v-validate= "modelValidations.retentionPeriod"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('retentionPeriod')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('retentionPeriod')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('retentionPeriod') && touched.retentionPeriod">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントコード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spsCode')},
                {'md-valid': !errors.has('spsCode') && touched.spsCode}]">
                <md-input
                  v-model="spsCode"
                  data-vv-name="spsCode"
                  type="text"
                  v-validate= "modelValidations.spsCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spsCode')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spsCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spsCode') && touched.spsCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントパスワード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spsPassword')},
                {'md-valid': !errors.has('spsPassword') && touched.spsPassword}]">
                <md-input
                  v-model="spsPassword"
                  data-vv-name="spsPassword"
                  type="text"
                  v-validate= "modelValidations.spsPassword"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spsPassword')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spsPassword')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spsPassword') && touched.spsPassword">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントトークン
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spsToken')},
                {'md-valid': !errors.has('spsToken') && touched.spsToken}]">
                <md-input
                  v-model="spsToken"
                  data-vv-name="spsToken"
                  type="text"
                  v-validate= "modelValidations.spsToken"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spsToken')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spsToken')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spsToken') && touched.spsToken">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              e-SCOTTパスワード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spsEscottPassword')},
                {'md-valid': !errors.has('spsEscottPassword') && touched.spsEscottPassword}]">
                <md-input
                  v-model="spsEscottPassword"
                  data-vv-name="spsEscottPassword"
                  type="text"
                  v-validate= "modelValidations.spsEscottPassword"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spsEscottPassword')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spsEscottPassword')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spsEscottPassword') && touched.spsEscottPassword">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スマートオーダー事業者コード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('smartOrderCode')},
                {'md-valid': !errors.has('smartOrderCode') && touched.smartOrderCode}]">
                <md-input
                  v-model="smartOrderCode"
                  data-vv-name="smartOrderCode"
                  type="text"
                  v-validate= "modelValidations.smartOrderCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('smartOrderCode')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('smartOrderCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('smartOrderCode') && touched.smartOrderCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              Firebase認証鍵
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('fcmKey')},
                {'md-valid': !errors.has('fcmKey') && touched.fcmKey}]">
                <md-input
                  v-model="fcmKey"
                  data-vv-name="fcmKey"
                  type="text"
                  v-validate= "modelValidations.fcmKey">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('fcmKey')">255文字以内の半角英数記号で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('fcmKey')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('fcmKey') && touched.fcmKey">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              利用規約
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="serviceTerms"></froala>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">事業者画像</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageInfo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageInfo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageInfo" data-id="imageInfo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageInfo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageInfo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import router from '../packs/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        buttonWord: '登録',
        companyName: '',
        companyCode: '',
        siteUrl: '',
        status: '',
        imageInfo: '',
        spsCode: '',
        spsPassword: '',
        spsToken: '',
        spsEscottPassword: '',
        smartOrderCode: '',
        fcmKey: '',
        serviceTerms: '',
        touched:{
          companyName: false,
          companyCode: false,
          retentionPeriod: false,
          siteUrl: false,
          status: false,
          spsCode: false,
          spsPassword: false,
          spsToken: false,
          spsEscottPassword: false,
          smartOrderCode: false,
          fcmKey: false,
          serviceTerms: false
        },
        modelValidations:{
          companyName: {
            required: true,
            max: 255
          },
          companyCode: {
            required: true,
            alpha_num: true,
            max: 255
          },
          retentionPeriod: {
            required: true,
            max: 3,
            numeric: true
          },
          siteUrl: {
            url: true
          },
          status:{
            required: true
          },
          spsCode:{
            required: true,
            alpha_num: true,
            max: 255
          },
          spsPassword:{
            required: true
          },
          spsToken:{
            required: true
          },
          spsEscottPassword:{
            required: true
          },
          smartOrderCode:{
            required: true
          },
          fcmKey:{
            required: true
          },
          serviceTerms:{
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      getInfo () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.companyName = response.data.name
          this.companyCode = response.data.code
          this.siteUrl = response.data.site_url
          this.retentionPeriod = response.data.slip_detail_retention_period
          this.status = response.data.status
          this.spsCode = response.data.sps_code,
          this.spsPassword = response.data.sps_password,
          this.spsToken = response.data.sps_token,
          this.spsEscottPassword = response.data.sps_escott_password,
          this.smartOrderCode = response.data.smart_order_code,
          this.fcmKey = response.data.service_setting.fcm_key,
          this.serviceTerms = response.data.service_setting.terms_of_service,
          this.imageInfo = response.data.image.url
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('事業者情報を取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateCompany()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      updateCompany(){
        var condition = {
          company: {
            name: this.companyName,
            code: this.companyCode,
            slip_detail_retention_period: this.retentionPeriod,
            site_url: this.siteUrl,
            status: this.status,
            sps_code: this.spsCode,
            sps_password: this.spsPassword,
            sps_token: this.spsToken,
            sps_escott_password: this.spsEscottPassword,
            smart_order_code: this.smartOrderCode, 
          },
          service_setting: {
            fcm_key: this.fcmKey,
            terms_of_service: this.serviceTerms
          },  
        }
        // 画像がURLかどうか判別して追加
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.company.image = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.company.image = this.imageInfo
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/companies/' : this.AdminApiUrl + '/companies/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          sessionStorage.removeItem('companySelect')
          this.successAndBackPage(`事業者情報を`+this.buttonWord+`しました`,'/admin/company_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`事業者情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.companyName = ''
        this.companyCode = ''
        this.retentionPeriod = ''
        this.siteUrl = ''
        this.status = ''
        this.spsCode = ''
        this.spsPassword = ''
        this.spsToken = ''
        this.spsEscottPassword = ''
        this.smartOrderCode = ''
        this.fcmKey = ''
        this.serviceTerms = ''
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
        this.resetData()
      } else {
        this.buttonWord = '更新'
        this.getInfo()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getInfo()
        }
      },
      companyName(){
        this.touched.companyName = true
      },
      companyCode(){
        this.touched.companyCode = true
      },
      siteUrl(){
        this.touched.siteUrl = true
      },
      retentionPeriod(){
        this.touched.retentionPeriod = true
      },
      status(){
        this.touched.status = true
      },
      spsCode(){
        this.touched.spsCode = true
      },
      spsPassword(){
        this.touched.spsPassword = true
      },
      spsToken(){
        this.touched.spsToken = true
      },
      spsEscottPassword(){
        this.touched.spsEscottPassword = true
      },
      smartOrderCode(){
        this.touched.smartOrderCode = true
      },
      fcmKey(){
        this.touched.fcmKey = true
      },
      serviceTerms(){
        this.touched.serviceTerms = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.betweenTime{
  line-height: 59px;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.md-input{
  width: 100%;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}

.is-l-none{
  display: none;
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
