import { render, staticRenderFns } from "./HouseMoney.vue?vue&type=template&id=587303de&scoped=true&"
import script from "./HouseMoney.vue?vue&type=script&lang=js&"
export * from "./HouseMoney.vue?vue&type=script&lang=js&"
import style0 from "./HouseMoney.vue?vue&type=style&index=0&id=587303de&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587303de",
  null
  
)

export default component.exports