<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>local_atm</md-icon>
          </div>
          <h4 class="title">支払用QRコード出力</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout" >
          <div class="md-layout md-layout-item md-size-100" v-if="!shopFlag">
              <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                店舗名
              </label>
              <div class="md-layout-item md-size-40  md-xsmall-size-100">
                <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
              </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済金額
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :md-counter="false" :class="[
                {'md-error': errors.has('sales_price')},
                {'md-valid': !errors.has('sales_price') && touched.sales_price}]">
                <md-input
                  v-model="sales_price"
                  data-vv-name="sales_price"
                  type="number"
                  v-validate= "modelValidations.sales_price"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('sales_price')">6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('sales_price')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('sales_price') && touched.sales_price">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <md-button class="md-success md-lg mL40" @click="validate">発行する</md-button>
        </md-card-actions>
      </md-card>
      <md-card v-show="qr_flg">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">支払用QRコード</h4>
        </md-card-header>
          <md-card-content class="md-layout formLayout md-alignment-center">
            <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
              <div class="md-layout-item md-size-100">
                <div class="file-input imageWidth">
                  <div class="image-container">
                    <img :src="image_url" />
                  </div>
                </div>
              </div>
              <div class="md-layout md-layout-item md-size-100">
                <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                  支払金額
                </label>
                <div class="md-layout-item md-size-85 md-xsmall-size-100">
                  <p class="form-control-static">¥{{ qr_price | fewWithDelimiter}}円</p>
                </div>
              </div>
              <div class="md-layout md-layout-item md-size-100">
                <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                  有効期限
                </label>
                <div class="md-layout-item md-size-85 md-xsmall-size-100">
                  <p class="form-control-static">{{ sale_to_at | timeFormat}}まで</p>
                </div>
              </div>
            </div>
          </md-card-content>
      </md-card>
    </div>
  </div>

</template>

<script>
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      ShopSelect,
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        sales_price: '',
        qr_price: '',
        shopId: '',
        image_url: '',
        sale_to_at: '',
        touched: {
          sales_price: false
        },
        modelValidations: {
          sales_price: {
            required: true,
            max: 6,
            numeric: true
          }
        },
        qr_flg: false
      }
    },
    methods: {
      issueQrCode () {
        var param = {
          shop_id: this.shopId,
          sales_price: this.sales_price
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shop_orders/issue_qr',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            qr_code: param
          }
        }).then(response => {
          this.code = response.data.code
          this.qr_price = response.data.sales_price
          this.sale_to_at = response.data.sale_to_at
          this.image_url = response.data.image_url
          this.qr_flg = true
        })
        .catch(error => {
          this.dangerNotify('QRコードが作成できませんでした')
        })
      },
      validate() {
        EventBus.$emit('validate', this.errors)
        this.$validator.validateAll().then(isValid => {
          EventBus.$emit('validate', this.errors)
          if (this.errors.items.length == 0) {
            this.warningMessage("QRコードを作成しました");
            this.issueQrCode()
          } else {
            this.validateError();
          }
        })
      },
    },
    mounted () {
    },
    watch: {
      sales_price(){
        this.touched.sales_price = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  max-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
