<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">店舗カード情報設定</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗カード番号
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】32文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <company-select v-model="companyId" :noSelectFlag="false" :touchedFlag="true"></company-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <brand-select ref="brand" v-model="brandId" :noSelectFlag="false" :touchedFlag="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              利用店舗
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :noSelectFlag="false" :touchedFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              カード属性
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('kinds')},
                {'md-valid': !errors.has('kinds') && touched.kinds}]">
                <md-select
                  v-model="kinds"
                  data-vv-name="kinds"
                  v-validate= "modelValidations.kinds"
                  required>
                  <md-option value="factory">工場</md-option>
                  <md-option value="manager">店長</md-option>
                  <md-option value="staff">店員</md-option>
                  <md-option value="table_seat">テーブル</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kinds')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kinds')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kinds') && touched.kinds" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <submit-button class="md-success md-lg btnCenter" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>       
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../mixins/event-bus.js';
  import role from '../mixins/role.js'
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      CompanySelect,
      BrandSelect,
      ShopSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        buttonWord: '登録',
        number: '',
        companyId: '',
        brandId: '',
        shopId: '',
        kinds: '',
        status: '',
        touched: {
          number: false,
          kinds: false,
          status: false
        },
        modelValidations: {
          number: {
            required: true,
            max: 32
          },
          kinds: {
            required: true
          },
          status:{
            required: true,
          }
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateShopCard()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getShopCard () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_cards/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.companyId = response.data.company_id
          this.brandId = response.data.brand_id
          this.shopId = response.data.shop_id
          this.number = response.data.number
          this.kinds = response.data.kinds
          this.status = response.data.status
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗カード情報を正しく取得できませんでした')
          }
        })
      },
      updateShopCard(){
        var condition = {
          shop_card: {
            'company_id': this.companyId,
            'brand_id': this.brandId,
            'shop_id': this.shopId,
            'number': this.number,
            'kinds': this.kinds,
            'status': this.status
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/shop_cards/' : this.AdminApiUrl + '/shop_cards/' + this.$route.params.id,
          timeout : 30000,
          data    : condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`店舗カード情報を`+this.buttonWord+`しました`)
          router.push({ path: '/admin/shop_card_list'})
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`店舗カード情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.number = ''
        this.companyId = ''
        this.brandId = ''
        this.shopId = ''
        this.kinds = ''
        this.status = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getShopCard()
      }
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect')
      sessionStorage.removeItem('shopSelect')
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getShopCard()
        }
      },
      companyId () {
        this.$refs.brand.companyId = this.companyId
        this.$refs.brand.resetBrand()
        this.$refs.shop.companyId = this.companyId
        this.$refs.shop.resetShop()
      },
      brandId () {
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
      },
      number(){
        this.touched.number = true
      },
      kinds(){
        this.touched.kinds = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }
}
</style>