<template>
  <div>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon>close</md-icon></div>
        <h4 class="title">注文商品明細一覧</h4>
        <div class="dataTableWrapper">
          <div class="dataTable">
            <div class="tHead">
              <div class="tHead_th">商品名</div>
              <div class="tHead_th taR">商品金額</div>
              <div class="tHead_th taR">購入数</div>
              <div class="tHead_th taR">小計</div>
            </div>
            <div v-for="item in smartOrderDetailData" :data-id="item.id" class="tItem">
              <div class="tItem_td">{{ item.productName }}</div>
              <div class="tItem_td taR">¥{{ item.salesPrice | numberWithDelimiter }}</div>
              <div class="tItem_td taR">{{ item.quantity }}</div>
              <div class="tItem_td taR">¥{{ item.subtotal | numberWithDelimiter }}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  export default {
    components: {
    },
    data() {
      return {
        smartOrderDetailData: [],
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    methods: {
      getOrderDetails() {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/smart_order_details/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          var res = response.data.data
          this.smartOrderDetailData.splice(0)
          for (var i in res) {
            var item = {
              id: res[i].id,
              productName: res[i].attributes['product-name'],
              salesPrice: res[i].attributes['sales-price'],
              quantity: res[i].attributes['quantity'],
              subtotal: res[i].attributes['subtotal']
            }
            this.smartOrderDetailData.push(item)
          }
          this.detailBgFlag = true
          this.detailFlag = true
        })
        .catch(error => {
          this.dangerNotify('明細情報の取得に失敗しました')
        })
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    }
  }
</script>
<style scoped>

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>