<template>
  <div>
    <draggable @sort="sortList" :options="{handle:'.dragHandle',animation:200}">
      <div class="smallCategory" v-for="item, index in smallCategory" :key="item.id" :data-id='item.id'>
        <div class="md-layout categoryDetail">
          <div class="md-layout-item md-size-70 md-xsmall-size-60 categoryName">
            <div class="dragHandle"><md-icon>reorder</md-icon></div>
            <label style="word-break:break-all;">{{item.name}}</label>
          </div>
          <div class="md-layout-item md-size-30 btnArea">
            <drop-down direction="up" class="btnAlign">
              <md-button slot="title" class="md-just-icon md-simple md-warning dropdown-toggle" data-toggle="dropdown">
                <md-icon>edit</md-icon>
              </md-button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li><a @click="handleEdit(item.id)">編集</a></li>
                <li><a @click="handleDelete(item.id)">削除</a></li>
              </ul>
            </drop-down>
          </div>
        </div>
      </div>
    </draggable>
    <div class="addCategory">
      <md-button class="md-success" @click="handleAdd(parent_id)">新しい小{{categoryData.title}}を追加</md-button>
    </div>
    <category-editor
      ref="categoryEdit"
      @addCategory="addCategory"
      @editCategory="editCategory"
      :categoryData="categoryData"
    ></category-editor>
  </div>
</template>
<script>
  import swal from 'sweetalert2'
  import CategoryEditor from './CategoryEditor.vue'
  import Draggable from 'vuedraggable'
  export default {
    components: {
      CategoryEditor,
      Draggable
    },
    data() {
      return {
        category: [],
        categoryData: this.categoryData
      }
    },
    props: {
      parentId: [String, Number],
      childCategory: Array,
      categoryData: Object
    },
    computed: {
      smallCategory: {
        get() {
          return this.childCategory
        }
      },
      parent_id: {
        get() {
          return this.parentId
        }
      }
    },
    methods: {
      sortList (event) {
        var id = event.item.getAttribute('data-id')
        this.sortCategory(id, event.newIndex + 1)
      },
      sortCategory (id, pos) {
        var condition = {
          category: {
            position: Number(pos),
            parent_id: this.parent_id
          }
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + this.categoryData.apiUrl + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.categoryData.title + '表示順の入れ替えに失敗しました')
          }
        })
      },
      handleAdd (id) {
        this.$refs.categoryEdit.handleAdd(id)
      },
      addCategory (obj) {
        this.smallCategory.push({
          id: obj.id,
          name: obj.name,
          openFlag: false,
          children: []
        })
      },
      handleEdit (id) {
        this.$refs.categoryEdit.handleEdit(id)
      },
      editCategory (obj) {
        for (var i in this.smallCategory) {
          if (String(obj.id) === String(this.smallCategory[i].id)) {
            this.$set(this.smallCategory[i], 'name', obj.name)
            break
          }
        }
      },
      handleDelete (id) {
        swal({
          title: 'この' + this.categoryData.title + 'を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！子` + this.categoryData.title + `を持っている場合は、まとめて削除されます`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteCategory(id)
          }
        })
      },
      deleteCategory (id) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + this.categoryData.apiUrl + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(this.categoryData.title + `情報を削除しました`)
          this.removeCategory(id)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.categoryData.title + '情報の削除に失敗しました')
          }
        })
      },
      removeCategory (id) {
        for (var i in this.smallCategory) {
          if (String(id) === String(this.smallCategory[i].id)) {
            this.smallCategory.splice(i, 1)
            break
          }
        }
      }
    }
  }
</script>
<style scoped>
.categoryList{
  width: 100%;
  border-top: solid 1px #d2d2d2;
}

.progenyCategory{
  padding-left: 20px;
}

.dragHandle{
  position: absolute;
  cursor: grab;
  width: 51px;
  height: 51px;
  top: 0;
  left: 0;
}

.dragHandle i{
  height: 51px;
  width: 51px;
}

.categoryName{
  line-height: 51px;
  padding-left: 56px;
  position: relative;
}

.addCategory{
  background: #ffffff;
  border-left: solid 1px #d2d2d2;
  border-right: solid 1px #d2d2d2;
  border-bottom: solid 1px #d2d2d2;
  padding: 8px 0;
  text-align: center;
}

.btnAdd{
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.btnArea{
  text-align: right;
}

.btnAlign{
  display: inline-block;
  vertical-align: top;
}

.categoryDetail{
  padding: 5px;
  background: #ffffff;
  border-left: solid 1px #d2d2d2;
  border-right: solid 1px #d2d2d2;
  border-bottom: solid 1px #d2d2d2;
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.pt20{
  padding-top: 20px;
}

.btnModal{
  text-align: center;
  margin-top: 20px;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.imgContainer{
  width: 200px;
}

@media screen and (max-width:600px) {

  .dragHandle{
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
  }

  .dragHandle i{
    height: 40px;
    width: 40px;
  }

  .categoryName{
    line-height: 40px;
    padding-left: 45px;
    padding-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .btnArea{
    padding-left: 5px;
    padding-right: 5px;
  }

  .btnArea button{
    width: 35px;
    height: 40px;
    margin: 0;
    padding: 0;
  }

  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>
