<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>calendar_today</md-icon>
          </div>
          <h4 class="title">営業カレンダー</h4>
        </md-card-header>

        <md-card-content>
          <div id="fullCalendar"></div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60">
          <md-button class="md-success btnCenter" @click.native="updateHoliday">営業カレンダー登録</md-button>
        </md-card-actions>
      </md-card>
      <div class="linkCont">
        <div class="bottomLink" @click="goSetting">基本情報を編集する</div>
      </div>
    </div>
  </div>
</template>

<script>
  import router from '../packs/router.js'
  import $ from 'jquery'
  import 'fullcalendar'
  import role from "../mixins/role.js"
  var moment = require('moment')

  var today = new Date()
  var y = today.getFullYear()
  var m = today.getMonth()
  var d = today.getDate()

  export default {
    mixins: [ role ],
    components: {
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        id: '',
        horidayList: [],
        events: [],
        eventId: 0,
        staffFlag: false
      }
    },
    methods: {
      goSetting () {
        router.push({ path: this.staffFlag === true ? '/admin/staff/edit/' + this.$route.params.id : '/admin/shop/edit/' + this.$route.params.id})
      },
      initCalendar($) {
        var self = this
        var $calendar = $('#fullCalendar')
        $calendar.fullCalendar({
          header: {
            left: '',
            center: 'title',
            right: 'prev,next'
          },
          defaultDate: today,
          selectLongPressDelay: 0,
          selectable: true,
          selectHelper: true,
          views: {
            month: { // name of view
              titleFormat: 'YYYY年 MMMM'
            },
            week: {
              titleFormat: ' MMMM D YYYY'
            },
            day: {
              titleFormat: 'D MMM, YYYY'
            }
          },
          titleFormat: {
            month: 'yyyy年M月',
            week: "yyyy年M月d日{ ～ }{[yyyy年]}{[M月]d日}",
            day: "yyyy年M月d日'('ddd')'"
          },
          monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
          dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
          viewRender: function(view,element) {
            var now = new Date();
            var end = new Date();
            end.setMonth(now.getMonth() + 3); //Adjust as needed

            if ( end < view.end) {
                var doc = document.querySelector('.fc-next-button')
                doc.style.display = 'none'
            } else {
                $("#calendar .fc-next-button").show();
                var doc = document.querySelector('.fc-next-button')
                doc.style.display = 'block'
            }

            if ( view.start < now) {
                $("#calendar .fc-prev-button").hide();
                var doc = document.querySelector('.fc-prev-button')
                doc.style.display = 'none'
            } else {
                $("#calendar .fc-prev-button").show();
                var doc = document.querySelector('.fc-prev-button')
                doc.style.display = 'block'
            }
          },
          select: function (start,event,view) {
            var keys = false
            $calendar.fullCalendar("clientEvents", function (e) {
              if (moment(start).format("YYYY-MM-DD") === moment(e.start).format("YYYY-MM-DD")) {
                keys = true
              }
            });

            if (!keys) {
              var eventData = {
                id: self.eventId++,
                title: '休日',
                start: start,
                className: 'event-red',
                allDay: true
              }
              self.events.push(eventData)
              $calendar.fullCalendar('renderEvent', eventData, true)
            }
          },
          eventClick: function (event) {
            $calendar.fullCalendar('removeEvents',event.id)
            for (var i in self.events) {
              if (event.id === self.events[i].id) {
                self.events.splice(i, 1);
                break
              }
            }
          },
          editable: false,
          eventLimit: true,
          events: self.events
        })
      },
      getHoliday () {
        this.axios({
          method  : 'GET',
          url     : this.staffFlag === true ? this.AdminApiUrl + '/holidays_staff/' + this.$route.params.id   : this.AdminApiUrl + '/holidays/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.events.splice(0)
          for (var i in response.data) {
            var event = {
              id: this.eventId++,
              title: '休日',
              start: response.data[i].value,
              className: 'event-red',
              allDay: true
            }
            this.events.push(event)
          }
          this.initCalendar($)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('休日情報を正しく取得できませんでした')
          }
        })
      },
      updateHoliday () {
        var value = ''
        for (var i in this.events) {
          if ( typeof this.events[i].start === 'string') {
            value += ',' + this.events[i].start
          } else {
            value += ',' + moment(this.events[i].start).format('YYYY/MM/DD')
          }
        }
        value = value.slice(1)
        this.setHoliday (value)
      },
      setHoliday (value) {
        var condition = {
          holiday: {
            value : value
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.staffFlag === true ? this.AdminApiUrl + '/holidays_staff/' + this.$route.params.id  : this.AdminApiUrl + '/holidays/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`休日情報を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('休日情報を正しく登録できませんでした')
          }
        })
      }
    },
    mounted () {
      var url = location.href
      if(url.indexOf('staff') >= 0) {
        this.staffFlag = true
      }
      if ((this.staffFlag && !this.opt_reserveOrder) ||
          (this.shopFlag  && !this.opt_reserveOrder) || 
          (this.shopFlag  && !this.staffFlag)) {
        router.push({ path: '/admin/login'})
        return false
      }
      window.$ = window.jQuery = $
      this.getHoliday()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

#fullCalendar {
  min-height: 300px;
}

.md-card-calendar .md-card-content{
  padding: 0 !important;
}

</style>
