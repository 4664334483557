export default {
  filters: {
    numberWithDelimiter: function(num){
      num = Number(num)
      var yen = num.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
      return yen.split('.')[0]
    },
    fewWithDelimiter: function(num){
      var numArray = String(num).split('.')
      var yen = numArray[0].toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
      if (yen === '0') {
        return '0'
      } else {
        return yen
      }
    },
    timeFormat: function(date){
      date = new Date(date)
      var format = 'YYYY-MM-DD hh:mm:ss'
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('00' + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ('00' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('00' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('00' + date.getSeconds()).slice(-2));
      return format
    },
    timeFormat2: function(date){
      date = new Date(date)
      var format = 'YYYY-MM-DD hh:mm'
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('00' + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ('00' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('00' + date.getMinutes()).slice(-2));
      return format
    },
    dateFormat: function(date){
      date = new Date(date)
      var format = 'YYYY-MM-DD'
      format = format.replace(/YYYY/g, date.getFullYear())
      format = format.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('00' + date.getDate()).slice(-2));
      return format
    }
  },
  methods: {
    initDate: function(date, year = 0){
      date = new Date(date)
      var format = 'YYYY-MM-DD'
      format = format.replace(/YYYY/g, date.getFullYear() + year)
      format = format.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('00' + date.getDate()).slice(-2));
      return format
    },
    boolCharParse: function (txtBool) {
      if (txtBool === 'true') {
        return true
      } else {
        return false
      }
    },
    gender: function  (sex) {
      switch (sex) {
        case 'male':
          return '男性'
        case 'female':
          return '女性'
        case 'other':
        default:
          return 'その他'
      }
    }
  }
}