<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">スタッフ基本情報設定</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="superFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              事業者名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('companyId')},
                {'md-valid': !errors.has('companyId') && touched.companyId}]">
                <md-select
                  v-model="companyId"
                  data-vv-name="companyId"
                  v-validate= "superFlag || switch1 ? modelValidations.companyId : ''"
                  required>
                  <md-option v-for="item in companyList" :value="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyId')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyId')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyId') && touched.companyId" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="this.superFlag">
            <!-- レイアウト調整用 -->
          </div>


          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店鋪名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スタッフコード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastName')},
                {'md-valid': !errors.has('lastName') && touched.lastName}]">
                <md-input
                  v-model="lastName"
                  data-vv-name="lastName"
                  type="text"
                  v-validate= "modelValidations.lastName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastName')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstName')},
                {'md-valid': !errors.has('firstName') && touched.firstName}]">
                <md-input
                  v-model="firstName"
                  data-vv-name="firstName"
                  type="text"
                  v-validate= "modelValidations.firstName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstName')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓（カナ）
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastNameKana')},
                {'md-valid': !errors.has('lastNameKana') && touched.lastNameKana}]">
                <md-input
                  v-model="lastNameKana"
                  data-vv-name="lastNameKana"
                  type="text"
                  v-validate= "modelValidations.lastNameKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastNameKana')">【必須】50文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastNameKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastNameKana') && touched.lastNameKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名（カナ）
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstNameKana')},
                {'md-valid': !errors.has('firstNameKana') && touched.firstNameKana}]">
                <md-input
                  v-model="firstNameKana"
                  data-vv-name="firstNameKana"
                  type="text"
                  v-validate= "modelValidations.firstNameKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstNameKana')">【必須】50文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstNameKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstNameKana') && touched.firstNameKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>query_builder</md-icon>
          </div>
          <h4 class="title">シフト時間設定</h4>
        </md-card-header>

        <working-plan ref="wp"></working-plan>

      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">オプション設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageStaff">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageStaff" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageStaff" data-id="imageStaff"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageStaff">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageStaff">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              性別
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="sex" value="male">男性</md-radio>
              <md-radio v-model="sex" value="female">女性</md-radio>
              <md-radio v-model="sex" value="other">その他</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              経験年数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('career')},
                {'md-valid': !errors.has('career') && touched.career}]">
                <md-input
                  v-model="career"
                  data-vv-name="career"
                  v-validate= "modelValidations.career"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('career')">2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('career')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('career') && touched.career" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              生年月日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="birthDay"
                :language="ja"
                data-vv-name="birthDay"
                v-validate= "modelValidations.birthDay"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="displayDate = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ニックネーム
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('nickName')},
                {'md-valid': !errors.has('nickName') && touched.nickName}]">
                <md-input
                  v-model="nickName"
                  data-vv-name="nickName"
                  type="text"
                  v-validate= "modelValidations.nickName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('nickName')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('nickName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('nickName') && touched.login">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              プロフィール
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtAreaProfile" class="txtArea" v-model="profile" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon('txtAreaProfile','data-code1')">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag1" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code1="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特技
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtAreaSpecialty" class="txtArea" v-model="specialty" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon('txtAreaSpecialty','data-code2')">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag2" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code2="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              趣味
            </label>
              <div class="md-layout-item">
                <md-field>
                  <md-textarea id="txtAreaHobby" class="txtArea" v-model="hobby" md-counter="140" maxlength="140"></md-textarea>
                </md-field>
                <div class="iconWrapper">
                  <div class="iconTrigger" @click="openEmoticon('txtAreaHobby','data-code3')">顔文字を挿入する</div>
                  <transition name="fade">
                    <div class="iconModal" v-if="modalFlag3" @mouseleave="closeEmoticon">
                      <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code3="icons[index]"></div>
                    </div>
                  </transition>
                </div>
              </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native="validate">{{buttonWord}}</md-button>
        </md-card-actions>
      </md-card>
      <div class="linkCont is-xs-none" v-show="!newFlag">
        <div class="bottomLink" @click="goCalendar">休日カレンダーを編集する</div>
      </div>
    </div>
  </div>
</template>

<script>
  import router from '../packs/router.js'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import WorkingPlan from '../components/additional/WorkingPlan.vue'
  import TimeSetter from '../components/additional/TimeSetter.vue'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import qs from 'qs'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js';

  export default {
    mixins: [ role ],
    components: {
      WorkingPlan,
      TimeSelect,
      TimeSetter,
      ShopSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        companyId: '',
        companyList: [],
        shopId: '',
        code: '',
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        nickName: '',
        sex: '',
        profile: '',
        career: '',
        specialty: '',
        hobby: '',
        birthDay: '',
        status: '',
        imageStaff: '',
        newFlag: false,
        workingPlanEmpty: false,

        touched: {
          companyId: false,
          code: false,
          firstName: false,
          lastName: false,
          firstNameKana: false,
          lastNameKana: false,
          nickName: false,
          sex: false,
          profile: false,
          career: false,
          specialty: false,
          hobby: false,
          birthDay: false,
          status: false
        },
        modelValidations: {
          companyId: {
            required: true
          },
          shopId: {
            required: true
          },
          code: {
            required: true,
            max: 255
          },
          lastName: {
            max: 50,
            required: true
          },
          firstName: {
            max: 50,
            required: true
          },
          lastNameKana: {
            required: true,
            max: 50,
            regex: '^([ァ-ヴ][ァ-ヴー・]*)$'
          },
          firstNameKana: {
            required: true,
            max: 50,
            regex: '^([ァ-ヴ][ァ-ヴー・]*)$'
          },
          nickName:{
            max: 255
          },
          career:{
            max: 2,
            numeric: true
          },
          status:{
            required: true,
          }
        },
        modalFlag1: false,
        modalFlag2: false,
        modalFlag3: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/staff_list/' })
      },
      goCalendar () {
        router.push({ path: '/admin/staff/calendar/edit/' + this.$route.params.id })
      },
      getStaff () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/staff/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.companyId = response.data.company_id
          this.shopId = response.data.shop_id
          this.code = response.data.code
          this.imageStaff = response.data.image_url
          this.firstName = response.data.first_name
          this.lastName = response.data.last_name
          this.firstNameKana = response.data.first_name_kana
          this.lastNameKana = response.data.last_name_kana
          this.nickName = response.data.nick_name
          this.sex = response.data.sex
          this.profile = response.data.profile
          this.career = response.data.career
          this.specialty = response.data.specialty
          this.hobby = response.data.hobby
          if (response.data.birth_day !== null) this.birthDay = response.data.birth_day.split('T')[0]
          this.status = response.data.status
          this.$refs.wp.workingPlan(response.data.working_plan)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタッフ情報を正しく取得できませんでした')
          }
        })
      },
      openEmoticon (id, dataCode) {
        var doms = document.getElementById(id)
        this.cursorPos = doms.selectionStart
        this.id = id
        this.dataCode = dataCode
        switch (this.id) {
          case 'txtAreaProfile':
            if (this.profile.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
            this.modalFlag1 = true
            break;
          case 'txtAreaSpecialty':
            if (this.specialty.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
            this.modalFlag2 = true
            break;
          case 'txtAreaHobby':
            if (this.hobby.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
            this.modalFlag3 = true
            break;
        }
      },
      insertEmoticon (e) {
        var emoicons = e.target.getAttribute(this.dataCode)
        switch (this.id) {
          case 'txtAreaProfile':
            this.profile = this.profile.substr(0, this.cursorPos) + emoicons + this.profile.substr(this.cursorPos, this.profile.length)
            break;
          case 'txtAreaSpecialty':
            this.specialty = this.specialty.substr(0, this.cursorPos) + emoicons + this.specialty.substr(this.cursorPos, this.specialty.length)
            break;
          case 'txtAreaHobby':
            this.hobby = this.hobby.substr(0, this.cursorPos) + emoicons + this.hobby.substr(this.cursorPos, this.hobby.length)
            break;
        }
        this.closeEmoticon();
      },
      closeEmoticon () {
        this.modalFlag1 = false
        this.modalFlag2 = false
        this.modalFlag3 = false
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        EventBus.$emit('validate', this.errors)
        var emptyPlan = this.$refs.wp.emptyCheckPlan()
        var errorPlan = this.$refs.wp.errorCheckPlan()
        this.$validator.validateAll().then(isValid => {
          if (this.errors.items.length == 0 && emptyPlan && errorPlan) {
            this.updateStaff()
          } else {
            this.validateError()
          }
        })
      },
      updateStaff(){
        var condition = {
          staff: {
            company_id: this.superFlag === true ? this.companyId : this.$Cookies.get('companyId'),
            shop_id: this.shopId,
            code: this.code,
            first_name: this.firstName,
            last_name: this.lastName,
            first_name_kana: this.firstNameKana,
            last_name_kana: this.lastNameKana,
            status: this.status,
            nick_name: this.nickName,
            sex: this.sex,
            profile: this.profile,
            career: this.career,
            specialty: this.specialty,
            hobby: this.hobby,
            birth_day: this.birthDay,
            working_plan: JSON.stringify(this.$refs.wp.dates())
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageStaff === '' || this.imageStaff === null){
          condition.staff.image = ''
        } else if (this.imageStaff.indexOf('/uploads/') == -1) {
          condition.staff.image = this.imageStaff
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/staff/' : this.AdminApiUrl + '/staff/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スタッフ情報を`+this.buttonWord+`しました`,'/admin/staff_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スタッフ情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      getCompany () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.length === 0) {
            this.warningMessage(`選択できる事業者がありませんでした`)
          } else {
            this.createCompanySelect(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('事業者情報を正しく取得できませんでした')
          }
        })
      },
      createCompanySelect (obj) {
        this.companyList.splice(0)
        for (var i = 0;i < obj.length; i++) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.companyList.push(item)
        }
      },
      resetData () {
        this.id = ''
        this.companyId = ''
        this.shopId = ''
        this.code = ''
        this.firstName = ''
        this.lastName = ''
        this.firstNameKana = ''
        this.lastNameKana = ''
        this.nickName = ''
        this.sex = ''
        this.profile = ''
        this.career = ''
        this.specialty = ''
        this.hobby = ''
        this.birthDay = null
        this.status = ''
        this.imageStaff = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.opt_reserveOrder) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (this.superFlag) {
        this.getCompany()
      }
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getStaff()
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getStaff()
        }
      },
      code(){
        this.touched.code = true
      },
      firstName(){
        this.touched.firstName = true
      },
      lastName(){
        this.touched.lastName = true
      },
      firstNameKana(){
        this.touched.firstNameKana = true
      },
      lastNameKana(){
        this.touched.lastNameKana = true
      },
      nickName(){
        this.touched.nickName = true
      },
      sex(){
        this.touched.sex = true
      },
      profile(){
        this.touched.profile = true
      },
      career(){
        this.touched.career = true
      },
      specialty(){
        this.touched.specialty = true
      },
      hobby(){
        this.touched.hobby = true
      },
      birthDay(){
        this.touched.birthDay = true
      },
      status(){
        this.touched.status = true
      },
      imageStaff(){
        this.touched.imageStaff = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}


.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

}

.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>
