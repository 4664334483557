<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">スタンプクーポン基本情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スタンプクーポンコード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スタンプクーポン名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('title')},
                {'md-valid': !errors.has('title') && touched.title}]">
                <md-input
                  v-model="title"
                  data-vv-name="title"
                  type="text"
                  v-validate= "modelValidations.title"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('title')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('title')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('title') && touched.title">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スタンプクーポン内容
            </label>
            <div class="md-layout-item pt20">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="couponDetail" md-counter="1024" maxlength="1024"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              値引き
            </label>
            <div class="md-layout-item">
              <md-radio v-model="kind" value="fixed_amount">定額値引き</md-radio>
              <md-radio v-model="kind" value="fixed_rate">定率値引き</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_amount'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              定額値引き
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('kindFixedAmount')},
                {'md-valid': !errors.has('kindFixedAmount') && touched.kindFixedAmount}]">
                <md-input
                  v-model="kindFixedAmount"
                  data-vv-name="kindFixedAmount"
                  v-validate= "kind === 'fixed_amount' ? modelValidations.kindFixedAmount : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kindFixedAmount')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kindFixedAmount')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kindFixedAmount') && touched.kindFixedAmount" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_rate'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              定率値引き
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('kindFixedRate')},
                {'md-valid': !errors.has('kindFixedRate') && touched.kindFixedRate}]">
                <md-input
                  v-model="kindFixedRate"
                  data-vv-name="kindFixedRate"
                  v-validate= "kind === 'fixed_rate' ? modelValidations.kindFixedRate : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kindFixedRate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kindFixedRate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kindFixedRate') && touched.kindFixedRate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">％</div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">スタンプクーポン期間設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">スタンプクーポン画像設定</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageCoupon">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageCoupon" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageCoupon" data-id="imageCoupon"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageCoupon">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageCoupon">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import router from '../packs/router.js'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import qs from 'qs'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      TimeSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        number: null,
        title: '',
        code: '',
        imageCoupon: '',
        couponDetail: '',
        selectedStart: '',
        selectedEnd: '',
        startTime: '',
        endTime: '',
        status: '',
        kind: 'fixed_amount',
        kindFixedAmount: '',
        kindFixedRate: '',
        newFlag: false,
        listData: [],
        touched: {
          number: false,
          title: false,
          code: false,
          status: false,
          selectedStart: false,
          selectedEnd: false,
          kindFixedAmount: '',
          kindFixedRate: ''
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true,
            max: 3
          },
          title: {
            required: true,
            max: 255
          },
          code: {
            required: true,
            max: 255
          },
          status: {
            required: true
          },
          kindFixedAmount: {
            required: true,
            max: 6
          },
          kindFixedRate: {
            required: true,
            max: 2
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          }
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/stamp_coupon_list/' })
      },
      getStampCoupons () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/stamp_coupons/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.number = response.data.position
          this.code = response.data.code
          this.title = response.data.name
          this.couponDetail = response.data.description
          this.imageCoupon = response.data.image_url
          this.status = response.data.status
          this.kind = response.data.kind
          this.kindFixedAmount = response.data.kind_fixed_amount
          this.kindFixedRate = response.data.kind_fixed_rate
          if (response.data.public_from !== null) {
            this.selectedStart = this.dateDivider(response.data.public_from)[0]
            this.startTime = this.dateDivider(response.data.public_from)[1]
            this.$refs.startTimer.unlock()
          }
          if (response.data.public_to !== null) {
            this.selectedEnd = this.dateDivider(response.data.public_to)[0]
            this.endTime = this.dateDivider(response.data.public_to)[1]
            this.$refs.endTimer.unlock()
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプクーポン情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateStampCoupon()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateStampCoupon(){
        var condition = {
          stamp_coupon: {
            position: this.number,
            code: this.code,
            name: this.title,
            description: this.couponDetail,
            status: this.status,
            kind: this.kind,
            kind_fixed_amount: this.kindFixedAmount,
            kind_fixed_rate: this.kindFixedRate,
            public_from: this.initDate(this.selectedStart) + ' ' + this.startTime,
            public_to: this.initDate(this.selectedEnd) + ' ' + this.endTime,
          }
        }
        // 画像がURLかどうか判別して追加
        if(this.imageCoupon === '' || this.imageCoupon === null){
          condition.stamp_coupon.image = ''
        } else if (this.imageCoupon.indexOf('/uploads/') == -1) {
          condition.stamp_coupon.image = this.imageCoupon
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/stamp_coupons/' : this.AdminApiUrl + '/stamp_coupons/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スタンプクーポンを`+this.buttonWord+`しました`,'/admin/stamp_coupon_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スタンプクーポンを正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      resetData () {
        this.number = ''
        this.status = 'active'
        this.kind = ''
        this.kindFixedAmount = '',
        this.kindFixedRate = '',
        this.code = ''
        this.title = ''
        this.couponDetail = ''
        this.imageInfo = ''
        this.imageCoupon = ''
        this.selectedStart = null
        this.startTime = '00:00'
        this.$refs.startTimer.lock()
        this.selectedEnd = null
        this.endTime = '00:00'
        this.$refs.endTimer.lock()

        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.couponDetail
        this.couponDetail = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      }
    },
    mounted () {
      // role check
      if (this.shopFlag || !this.opt_coupon) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getStampCoupons()
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getStampCoupons()
        }
      },
      number () {
        this.touched.number = true
      },
      title(){
        this.touched.title = true
      },
      code(){
        this.touched.code = true
      },
      status(){
        this.touched.status = true
      },
      kindFixedAmount(){
        this.touched.kindFixedAmount = true
      },
      kindFixedRate(){
        this.touched.kindFixedRate = true
      },
      selectedStart () {
        this.$refs.startTimer.unlock()
      },
      selectedEnd () {
        this.$refs.endTimer.unlock()
      }
    }
  }
</script>



<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>