require('froala-editor/js/froala_editor.pkgd.min')
require('froala-editor/js/languages/ja')
require('froala-editor/css/themes/gray.min.css')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('font-awesome/css/font-awesome.css')
require('froala-editor/css/froala_style.min.css')
import VueFroala from 'vue-froala-wysiwyg'

const VueFroalaConfig = {
  install: function(Vue) {
    Vue.use(VueFroala)
    Vue.prototype.config = {
      key: 'SH3B14B10A7jC5D4C3E3J2A7A8C4C4D2A-9MFIZJNKLDXIREJI==',
      placeholderText: 'ここを編集してください',
      charCounterCount: true,
      toolbarButtons: [
        'fullscreen',
        'bold',
        'italic',
        'underline','|',
        'fontFamily',
        'fontSize',
        'color',
        'lineHeight', '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'embedly',
        'insertTable', '|',
        'emoticons',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'clearFormatting', '|',
        'spellChecker', 'help', 'html', '|', 'undo', 'redo'],
      quickInsertButtons: ['embedly', 'table', 'ul', 'ol', 'hr'],
      imageResizeWithPercent: true,
      imageDefaultWidth: 100,
      language: 'ja',
      theme: 'gray'
    }
  }
}
export default VueFroalaConfig