<template>
  <div class="user">
    <div class="photo">
      <i class="material-icons icons" style="color:#ffffff !important">account_circle</i>
    </div>
    <div class="user-info">
      <a class="users">
        <span>{{id}}</span>
        <span class="belongs">{{belongs}}</span>
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.$Cookies.get('loginId'),
        belongs: this.$Cookies.get('belongs')
      }
    }
  }
</script>
<style>
.users{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  cursor: default !important;
}

.users span{
  overflow: hidden;
  text-overflow: ellipsis;
}

.belongs{
  font-size: 12px;
  color: #cccccc;
}

.icons{
  font-size: 36px;
}
</style>
