<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">支払いマスタ情報登録</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払い情報コード
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】16文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払い名称（表示名）
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済種別
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('kinds')},
                {'md-valid': !errors.has('kinds') && touched.kinds}]">
                <md-select
                  v-model="kinds"
                  data-vv-name="kinds"
                  v-validate= "modelValidations.kinds"
                  required>
                  <md-option value="cash">現金</md-option>
                  <md-option value="point">ポイント</md-option>
                  <md-option value="credit_card">クレジットカード決済</md-option>
                  <md-option value="qr_code">QRコード決済</md-option>
                  <md-option value="e_money">電子マネー決済</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kinds')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kinds')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kinds') && touched.kinds" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>         
          <div class="md-layout md-layout-item md-size-100" v-show="kinds==='qr_code'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              QR×Drive決済コード
            </label>
            <div class="md-layout-item md-size-60  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('qrDriveCode')},
                {'md-valid': !errors.has('qrDriveCode') && touched.qrDriveCode}]">
                <md-input
                  v-model="qrDriveCode"
                  data-vv-name="qrDriveCode"
                  type="text"
                  v-validate= "modelValidations.qrDriveCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('qrDriveCode')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('qrDriveCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('qrDriveCode') && touched.qrDriveCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払い情報説明
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="description"
                  data-vv-name="description"
                  type="text"
                  v-validate= "modelValidations.description">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払い情報ロゴ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageInfo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageInfo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageInfo" data-id="imageInfo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageInfo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageInfo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">登録済み支払い情報</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">支払い情報コード</div>
                <div class="tHead_th">支払い名称</div>
                <div class="tHead_th">決済種別</div>
                <div class="tHead_th">登録日</div>
                <div class="tHead_th">更新日</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td">{{ item.code }}</div>
                <div class="tItem_td">{{ item.name }}</div>
                <div class="tItem_td">{{ item.kinds }}</div>
                <div class="tItem_td">{{ item.createdat | dateFormat}}</div>
                <div class="tItem_td">{{ item.updatedat | dateFormat}}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        buttonWord: '登録',
        tableData: [],
        code: '',
        qrDriveCode: '',
        name: '',
        kinds: '',
        description: '',
        status: '',
        imageInfo: '',
        touched: {
          code: false,
          qrDriveCode: false,
          name: false,
          kinds: false,
          status: false
        },
        modelValidations: {
          code: {
            required: true,
            alpha_num: true,
            max: 16
          },
          name: {
            required: true,
            max: 16
          },
          kinds: {
            required: true
          },
          status:{
            required: true
          }
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updatePayment()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      getPayments () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_payments',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
            this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お支払い情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            code: res[i].attributes.code,
            qrDriveCode: res[i].attributes.qr_drive_code,
            name: res[i].attributes.name,
            kinds: this.kindsChecker(res[i].attributes.kinds),
            createdat: res[i].attributes['created-at'],
            updatedat: res[i].attributes['updated-at']
          }
          this.tableData.push(item)
        }
      },
      kindsChecker (kind) {
        switch (kind) {
          case 'cash':
            kind = '現金'
            break;
          case 'point':
            kind = 'ポイント'
            break;
          case 'credit_card':
            kind = 'クレジットカード決済'
            break;
          case 'qr_code':
            kind = 'QRコード決済'
            break;
          case 'e_money':
            kind = '電子マネー決済'
            break;
        }
        return kind
      },
      handleDelete (item) {
        swal({
          title: 'この支払いマスタ情報を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deletePayment(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      deletePayment (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/shop_payments/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            order_status : status
          }
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`支払いマスタ情報を削除しました`)
          this.getPayments()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('支払いマスタ情報を正しく削除できませんでした')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/payment/edit/' + item.id })
      },
      updatePayment () {
        var condition = {
          shop_payment: {
            code: this.code,
            qr_drive_code: this.qrDriveCode,
            name: this.name,
            kinds: this.kinds,
            description: this.description,
            status: this.status
          }
        }
        // 画像がURLかどうか判別して追加
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.shop_payment.image = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.shop_payment.image = this.imageInfo
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shop_payments/',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal({
            title: `支払いマスタ情報を登録しました`,
            buttonsStyling: false,
            type: 'success',
            confirmButtonClass: 'md-button md-success'
          }).then(response => {
              this.resetData()
              this.getPayments()
              router.push({ path: '/admin/payment_list/' })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('支払いマスタ情報を登録できませんでした')
          }
        })
      },
      resetData () {
        this.code = ''
        this.name = ''
        this.qrDriveCode = ''
        this.kinds = ''
        this.description = ''
        this.status = ''
        this.imageInfo = ''
        this.$validator.reset()
        // this.errors.clear();

        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getPayments()
    },
    watch: {
      code () {
        this.touched.code = true
      },
      name () {
        this.touched.name = true
      },
      kinds () {
        this.touched.kinds = true
      },
      description () {
        this.touched.description = true
      },
      status () {
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.errorMsg{
  bottom: -15px !important;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>