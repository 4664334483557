<template>
  <category-list :categoryData="categoryData"></category-list>
</template>

<script>
import CategoryList from '../components/additional/CategoryList.vue'

export default {
  components: {
    CategoryList
  },
  data () {
    return {
      categoryData: {
        title: 'カテゴリ',
        apiUrl: '/product_categories/',
        description: true
      }}
  }
}
</script>