<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">{{ productData.title }}商品検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="itemName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              販売ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field>
                <md-select v-model="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">販売中</md-option>
                  <md-option value="not_active">販売停止中</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getItems">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">商品一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">商品番号</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">商品名</div>
                  <div class="tHead_th taR">金額</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.number }}</md-label></div>
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="active">販売中</md-option>
                        <md-option value="not_active">販売停止中</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter }}</div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-success mg0"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getItems">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from './Pagination.vue'
  import ShopSelect from './ShopSelect.vue'
  import router from '../../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    props: {
      productData: Object
    },
    data () {
      return {
        currentSort: '',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        resultFlag: false,
        status: '選択しない',
        itemCode: '',
        itemName: '',
        shopId: '',
      }
    },
    methods: {
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleLike (item) {
        this.successAndBackPage(`ステータスを更新しました`)
      },
      handleEdit (id) {
        router.push({ path: this.productData.editUrl+id })
      },
      handleDelete (item) {
        swal({
          title: 'この商品を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      getItems (page) {
        var condition = {
          page: page,
          status: this.status === '選択しない' ? '' : this.status,
          name: this.itemName,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + this.productData.apiUrl,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            number: res[i].attributes.code,
            status: res[i].attributes.status,
            name: res[i].attributes.name,
            price: res[i].attributes['sales-price']
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateStatus (id, status) {
        var condition = {
          product: {
            'status': status,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl+this.productData.apiUrl+id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl+this.productData.apiUrl+item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item);
          this.successAndBackPage(`商品情報を削除しました`)
          this.getItems(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報を削除出来ませんでした')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag || !this.opt_preOrder) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.wth100{
  width: 100px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
