<template>
  <md-button :disabled="processing" @click.native="handleClick">{{buttonWord}}</md-button>
</template>
<script>
  export default {
    props: {
      onclick: {
        type: Function,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      buttonWord: {
        type: String,
        default: "",
      }
    },
    data() {
      return {
        processing: false,
      };
    },
    methods: {
      handleClick(event) {
        if (this.processing) return;
        this.processing = true;
        this.onclick(event).then(() => {
          this.processing = false;
        });
      },
    },
  };
</script>