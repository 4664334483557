export default {
  data() {
    return {
      shopFlag: false,
      superFlag: false,
      orderChangeFlag: false,
      companyFlag: false,
      opt_preOrder: false,
      opt_qrPayment: false,
      opt_reserveOrder: false,
      opt_houseMoney: false,
      opt_stamp: false,
      opt_point: false,
      opt_coupon: false,
      opt_pushNotice: false,
      opt_mailMagazine: false,
      opt_ecOrder: false,
      opt_api: false,
      companyId: ''
    };
  },
  methods: {
    optChecker() {
      this.orderChangeFlag = this.boolCharParse(this.$Cookies.get('flag_order_change'));
      this.opt_preOrder = this.boolCharParse(this.$Cookies.get('flag_preOrder'));
      this.opt_qrPayment = this.boolCharParse(this.$Cookies.get('flag_qrPayment'));
      this.opt_reserveOrder = this.boolCharParse(this.$Cookies.get('flag_reserveOrder'));
      this.opt_houseMoney = this.boolCharParse(this.$Cookies.get('flag_houseMoney'));
      this.opt_stamp = this.boolCharParse(this.$Cookies.get('flag_stamp'));
      this.opt_point = this.boolCharParse(this.$Cookies.get('flag_point'));
      this.opt_pushNotice = this.boolCharParse(this.$Cookies.get('flag_pushNotice'));
      this.opt_coupon = this.boolCharParse(this.$Cookies.get('flag_coupon'));
      this.opt_mailMagazine = this.boolCharParse(this.$Cookies.get('flag_mailMagazine'));
      this.opt_ecOrder = this.boolCharParse(this.$Cookies.get('flag_ecOrder'));
      this.opt_api = this.boolCharParse(this.$Cookies.get('flag_api'));
    }
  },
  mounted () {
    // role check
    switch (this.$Cookies.get('role')) {
      case 'super':
        this.superFlag = true
        break
      case 'company':
        this.companyFlag = true
        this.companyId = this.$Cookies.get('companyId')
        break
      case 'shop':
        this.shopFlag = true
      default:
    }
    this.optChecker();
  }
}