<template>
  <md-field class="shopSelect">
    <md-select v-model="selectedId" name="shopName" @input="updateValue" multiple>
      <md-option v-for="item in listData" :value="item.id">{{item.name}}</md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    data() {
      return {
        listData: [],
        selectedId: []
      }
    },
    model: {
    },
    props: ['value'],
    methods: {
      getShops () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          this.dangerNotify('店舗リストを取得できませんでした')
        })
      },
      createList (obj) {
        for (var i in obj) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.listData.push(item)
        }
      },
      updateValue(e){
        this.$emit('input', this.selectedId)
        this.$emit('selected', this.selectedId)
      },
      setShop () {
        var shopSelect = sessionStorage.getItem('shopSelect')
        if (shopSelect === null) {
          this.getShops()
        } else {
          shopSelect = JSON.parse(shopSelect)
          for (var i in shopSelect) {
            var item = {
              id: shopSelect[i].id,
              name: shopSelect[i].name
            }
            this.listData.push(item)
          }
        }
      }
    },
    mounted () {
      this.setShop()
    },
  }
</script>
<style scoped>
.shopSelect{
  /* max-width: 120px; */
  width: 100%;
}
</style>
