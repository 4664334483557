<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">会員検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ニックネーム
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="nickName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              性別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="sex" name="sex">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="male">男性</md-option>
                  <md-option value="female">女性</md-option>
                  <md-option value="other">その他</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              生年月日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="birthdayStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="birthdayStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="birthdayEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="birthdayEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              入会日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              携帯電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="not_approve">SMS未認証</md-option>
                  <md-option value="sms_approved">仮会員</md-option>
                  <md-option value="approved">本会員</md-option>
                  <md-option value="unsubscribed">退会会員</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getUsers(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">会員一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable">
                <div class="tHead">
                  <div class="tHead_th">ニックネーム</div>
                  <div class="tHead_th">性別</div>
                  <div class="tHead_th">生年月日</div>
                  <div class="tHead_th">入会日</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.nickName }}</md-label></div>                 
                  <div class="tItem_td">{{gender(item.sex)}}</div>
                  <div class="tItem_td">{{item.birthday | dateFormat }}</div>
                  <div class="tItem_td">{{item.createdat | dateFormat }}</div>
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="not_approve">SMS未認証</md-option>
                        <md-option value="sms_approved">仮会員</md-option>
                        <md-option value="approved">本会員</md-option>
                        <md-option value="unsubscribed">退会会員</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-success mg0"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getUsers">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        nickName: '',
        sex: '選択しない',
        birthdayStart: '',
        birthdayEnd: '',
        selectedStart: '',
        selectedEnd: '',
        tel: '',
        status: '選択しない',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getUsers (page) {
        var condition = {
          page: page,
          nickname: this.nickName,
          sex: this.sex === '選択しない' ? '' : this.sex,
          birthday_from: this.birthdayStart === '' ? '' : this.initDate(this.birthdayStart),
          birthday_to: this.birthdayEnd === '' ? '' : this.initDate(this.birthdayEnd),
          public_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          public_to: this.selectedEnd === '' ? '' : this.initDate(this.selectedEnd),
          tel: this.tel,
          status: this.status === '選択しない' ? '' : this.status
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            nickName: res[i].attributes.nickname,
            sex: res[i].attributes.sex,
            birthday: res[i].attributes['birth-day'],
            createdat: res[i].attributes['created-at'],
            status: res[i].attributes.status
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleLike (item) {
        this.successAndBackPage(`ステータスを更新しました`)
      },
      handleDelete (item) {
        swal({
          title: 'この会員を削除してもよろしいですか?',
          text: `削除してしまうと、元に戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/users/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item);
          this.successAndBackPage(`会員情報を削除しました`)
          this.getUsers(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員を削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      updateStatus (id, status) {
        var condition = {
          user: {
            'status': status,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/users/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/user/edit/' + item.id })
      },
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.userStatus{
  max-width: 120px;
  width: 100%;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
