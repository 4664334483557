<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">ポイント失効設定</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ポイント失効設定
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="expireType">
                  <md-option value="use_one_year">最終利用から１年で失効</md-option>
                  <md-option value="use_two_year">最終利用から２年で失効</md-option>
                  <md-option value="issue_one_year">初回発行から１年で失効</md-option>
                  <md-option value="issue_two_year">初回発行から２年で失効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">来店時ポイント設定</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              ポイント付与
            </label>
            <div class="md-layout-item">
              <md-radio v-model="isVisitAvailable" :value="true">する </md-radio>
              <md-radio v-model="isVisitAvailable" :value="false">しない </md-radio>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="isVisitAvailable">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              付与ポイント
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('visitPoint')},
                {'md-valid': !errors.has('visitPoint') && touched.visitPoint}]">
                <md-input
                  v-model="visitPoint"
                  data-vv-name="visitPoint"
                  v-validate= "isVisitAvailable === true ? modelValidations.visitPoint : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('visitPoint')">【必須】5桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('visitPoint')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('visitPoint') && touched.visitPoint" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">ポイント</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">購買時ポイント設定</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              ポイント付与
            </label>
            <div class="md-layout-item">
              <md-radio v-model="isPurchaseAvailable" :value="true">する </md-radio>
              <md-radio v-model="isPurchaseAvailable" :value="false">しない </md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="isPurchaseAvailable">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              付与ポイント率
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('purchaseRate')},
                {'md-valid': !errors.has('purchaseRate') && touched.purchaseRate}]">
                <md-input
                  v-model="purchaseRate"
                  data-vv-name="purchaseRate"
                  type="number"
                  v-validate= "isPurchaseAvailable === true ? modelValidations.purchaseRate : ''"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('purchaseRate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('purchaseRate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('purchaseRate') && touched.purchaseRate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">%</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">新規会員時ポイント設定</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              付与ポイント率
            </label>
            <div class="md-layout-item">
              <md-radio v-model="isAdmissionAvailable" :value="true">する </md-radio>
              <md-radio v-model="isAdmissionAvailable" :value="false">しない </md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="isAdmissionAvailable">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              付与ポイント
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('admissionPoint')},
                {'md-valid': !errors.has('admissionPoint') && touched.admissionPoint}]">
                <md-input
                  v-model="admissionPoint"
                  data-vv-name="admissionPoint"
                  type="number"
                  v-validate= "isAdmissionAvailable === true ? modelValidations.admissionPoint : ''"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('admissionPoint')">【必須】5桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('admissionPoint')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('admissionPoint') && touched.admissionPoint" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">ポイント</div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import router from '../packs/router.js'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import qs from 'qs'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        isAdmissionAvailable: false,
        isVisitAvailable: false,
        isPurchaseAvailable: false,
        expireType: 0,
        admissionPoint: '',
        purchaseRate: '',
        visitPoint: '',
        touched: {
          expireType: 1,
          admissionPoint: false,
          purchaseRate: false,
          visitPoint: false
        },
        modelValidations: {
          admissionPoint: {
            required: true,
            numeric: true,
            max: 5
          },
          purchaseRate: {
            required: true,
            numeric: true,
            max: 2
          },
          visitPoint: {
            required: true,
            numeric: true,
            max: 5
          }
        }
      }
    },
    methods: {
      getPointSettings () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/point_settings/' +  this.$Cookies.get('companyId') ,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.expireType = response.data.expire_type
          this.isAdmissionAvailable = response.data.is_admission_available
          if (response.data.is_admission_available) {
            this.isAdmissionAvailable = true
            if (response.data.admission_point !== null) {
              this.admissionPoint = response.data.admission_point
            }
          }

          this.isVisitAvailable = response.data.is_visit_available
          if (response.data.is_visit_available) {
            this.isVisitAvailable = true
            if (response.data.visit_point !== null) {
              this.visitPoint = response.data.visit_point
            }
          }

          this.isPurchaseAvailable = response.data.is_purchase_available
          if (response.data.is_purchase_available) {
            this.isPurchaseAvailable = true
            if (response.data.purchase_rate !== null) {
              this.purchaseRate = response.data.purchase_rate
            }
          }

        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ポイント設定情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updatePointSettings()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updatePointSettings(){
        var condition = {
          point_settings: {
            'expire_type': this.expireType,
            'purchase_rate': this.purchaseRate,
            'visit_point': this.visitPoint,
            'admission_point': this.admissionPoint,
            'is_purchase_available': this.isPurchaseAvailable,
            'is_visit_available': this.isVisitAvailable,
            'is_admission_available': this.isAdmissionAvailable
          }
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/point_settings/' + this.$Cookies.get('companyId'),
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`ポイント設定を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ポイント設定情報を正しく登録できませんでした')
          }
        })
      }
    },
    mounted () {
      if (this.shopFlag || !this.opt_point) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getPointSettings()
    },
    watch: {
      '$route.path'(value){
        this.getPointSettings()
      },
      expireType(){
        this.touched.expireType = true
      },
      admissionPoint(){
        this.touched.admissionPoint = true
      },
      purchaseRate(){
        this.touched.purchaseRate = true
      },
      visitPoint(){
        this.touched.visitPoint = true
      }
    }
  }
</script>

<style lang="css" scoped>
.ptb{
  padding-top: 15px !important;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}
.md-card .md-card-actions {
  border-style: none;
}
#md-input-ywauk325d {
  width: 40px!important;
}
@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }
}
</style>

