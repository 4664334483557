<template>
  <product-detail :productData="productData"></product-detail>
</template>
<script>
import ProductDetail from '../components/additional/ProductDetail.vue'

export default {
  components: {
    ProductDetail,
  },
  data () {
    return { 
      productData: {
        title: "事前予約",
        returnBack: '/admin/reserve_product_list/',
        apiUrl: '/reserve_order_products/',
        stockManagement: false,
        timeManagement: true
      }}
  }
}
</script>
