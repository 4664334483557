<template>
  <product-detail :productData="productData"></product-detail>
</template>

<script>
import ProductDetail from '../components/additional/ProductDetail.vue'

export default {
  components: {
    ProductDetail,
  },
  data () {
    return { 
      productData: {
        title: "事前注文",
        returnBack: '/admin/pre_product_list/',
        apiUrl: '/pre_order_products/',
        stockManagement: true,
        timeManagement: true
      }}
  }
}
</script>