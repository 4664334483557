<template>
  <products :productData="productData"></products>
</template>

<script>
import Products from '../components/additional/ProductList.vue'

export default {
  components: {
    Products,
  },
  data () {
    return { 
      productData: {
        title: "事前予約",
        returnBack: '/admin/reserve_product_list/',
        editUrl: '/admin/reserve_product/edit/',
        apiUrl: '/reserve_order_products/'
      }}
  }
}
</script>