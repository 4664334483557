<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">Push通知予約設定</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-40  md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              入会日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              購入日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model=" purchaseStart"
                :language="ja"
                data-vv-name="purchaseStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="purchaseStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="purchaseEnd"
                :language="ja"
                data-vv-name="purchaseEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="purchaseEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="searchTarget">上記に当てはまる対象者を検索</md-button>
        </md-card-actions>
      </md-card>

      <transition name="fade">
        <div class="md-layout-item md-size-100" v-if="resultFlag">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>create</md-icon>
              </div>
              <h4 class="title">Push通知予約詳細設定</h4>
            </md-card-header>

            <md-card-content class="md-layout formLayout">
              <div class="md-layout md-layout-item md-size-100">
                <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                  件名
                </label>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field :class="[
                    {'md-error': errors.has('subject')},
                    {'md-valid': !errors.has('subject') && touched.subject}]">
                    <md-input
                      v-model="subject"
                      data-vv-name="subject"
                      type="text"
                      v-validate= "modelValidations.subject"
                      required>
                    </md-input>
                    <slide-y-up-transition>
                      <div class="vErrorText" v-show="errors.has('subject')">【必須】255文字以内で入力してください</div>
                    </slide-y-up-transition>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="errors.has('subject')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="!errors.has('subject') && touched.subject">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div> 
              <div class="md-layout md-layout-item md-size-100">
                <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                  配信内容
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-textarea id="txtArea" class="txtArea" v-model="noticeText" md-counter="140" maxlength="140"></md-textarea>
                  </md-field>
                  <div class="iconWrapper">
                    <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                    <transition name="fade">
                      <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                        <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>

              <div class="md-layout md-layout-item md-size-100">
                <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                  配信時間
                </label>
                <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
                  <vuejs-datepicker
                    :format="DatePickerFormat"
                    v-model="deliveryStart"
                    :language="ja"
                    data-vv-name="deliveryStart"
                    v-validate= "modelValidations.deliveryStart"
                    ></vuejs-datepicker>
                  <md-button
                    class="md-just-icon md-default md-simple dateDelete"
                    @click.native="deliveryStart = ''">
                    <md-icon>close</md-icon>
                  </md-button>
                  <slide-y-up-transition>
                    <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('deliveryStart')">【必須】日付を選択してください</div>
                  </slide-y-up-transition>
                  <slide-y-down-transition>
                    <md-icon class="error dateError" v-show="errors.has('deliveryStart')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success dateSuccess" v-show="!errors.has('deliveryStart') && touched.deliveryStart">done</md-icon>
                  </slide-y-down-transition>
                </div>
                <time-select ref="deliveryTime" v-model="deliveryTime"></time-select>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="left" class="mt60 mx-auto">
              <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
            </md-card-actions>
          </md-card>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import swal from 'sweetalert2'
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      TimeSelect,
      SubmitButton,
      ShopSelect
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        buttonWord: '登録',
        shopId: '選択しない',
        status: '',
        selectedStart: '',
        selectedEnd: '',
        purchaseStart: '',
        purchaseEnd: '',
        noticeText: '',
        subject: '',
        deliveryStart: '',
        deliveryTime: '00:00',
        cursorPos: 0,
        listData:[],
        resultFlag: false,
        touched: {
          purchaseStart: false,
          purchaseEnd: false,
          noticeText: false,
          deliveryStart: false
        },
        modelValidations: {
          purchaseStart: {
            required: true
          },
          purchaseEnd: {
            required: true
          },
          deliveryStart: {
            required: true
          },
          subject: {
            required: true,
            max: 255
          },
          noticeText: {
            required: true,
            max: 255
          }
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.createNotice()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, 2000);
        });
      },
      searchTarget () {
        var condition = {
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          created_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          created_to: this.selectedEnd === '' ? '' : this.initDate(this.selectedEnd),
          buy_from: this.purchaseStart === '' ? '' : this.initDate(this.purchaseStart),
          buy_to: this.purchaseEnd === '' ? '' : this.initDate(this.purchaseEnd)
        }

        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/notifications/recipients',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.meta.count === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            swal({
              title: '該当する会員が見つかりました',
              text: response.data.meta.count + '件 のユーザーがいます。Push通知を作成しますか？',
              type: 'warning',
              showCancelButton: true,
              confirmButtonClass: 'md-button md-success btn-fill',
              cancelButtonClass: 'md-button md-danger btn-fill',
              confirmButtonText: 'OK',
              buttonsStyling: false
            }).then((result) => {
              if(result.value){
                this.resultFlag = true
              }
            });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('検索情報を取得できませんでした')
          }
        })
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        if (this.noticeText.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.noticeText
        this.noticeText = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      },
      createNotice () {
        var condition = {
          body: this.noticeText,
          subject: this.subject,
          delivery_at: this.initDate(this.deliveryStart) + ' ' + this.deliveryTime,
          shop_id: this.shopId === '選択しない' ? null : this.shopId,
          created_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          created_to: this.selectedEnd === '' ? '' : this.initDate(this.selectedEnd),
          buy_from: this.purchaseStart === '' ? '' : this.initDate(this.purchaseStart),
          buy_to: this.purchaseEnd === '' ? '' : this.initDate(this.purchaseEnd),
          status: null
        }

        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/notifications/',
          timeout : 10000,
          data: {
            notification : condition
          },
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`Push通知予約を登録しました`)
          router.push({ path: '/admin/notification_list' })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('Push通知予約を登録できませんでした')
          }
        })
      }
    },
    mounted () {
      if (this.shopFlag || !this.opt_pushNotice) {
        router.push({ path: '/admin/login'})
        return false
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
      },
      selectedStart(){
        this.touched.selectedStart = true
      },
      selectedEnd(){
        this.touched.selectedEnd = true
      },
      purchaseStart(){
        this.touched.purchaseStart = true
      },
      purchaseEnd(){
        this.touched.purchaseEnd = true
      },
      deliveryStart(){
        this.touched.deliveryStart = true
        this.$refs.deliveryTime.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.txtArea{
  padding: 10px 5px 0 !important;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>
