<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">会員詳細</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ニックネーム
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('nickname')},
                {'md-valid': !errors.has('nickname') && touched.nickname}]">
                <md-input
                  v-model="nickname"
                  data-vv-name="nickname"
                  type="text"
                  v-validate= "modelValidations.nickname"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('nickname')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('nickname')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('nickname') && touched.nickname">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              性別
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('sex')},
                {'md-valid': !errors.has('sex') && touched.sex}]">
                <md-select
                  v-model="sex"
                  data-vv-name="sex"
                  v-validate= "modelValidations.sex"
                  required>
                  <md-option value="male">男性</md-option>
                  <md-option value="female">女性</md-option>
                  <md-option value="other">その他</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('sex')">【必須】一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('sex')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('sex') && touched.sex" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              生年月日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="birthday"
                :language="ja"
                data-vv-name="birthday"
                v-validate= "modelValidations.birthday"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="birthday = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('birthday')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('birthday')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('birthday') && touched.birthday">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              携帯電話番号
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('tel')},
                {'md-valid': !errors.has('tel') && touched.tel}]">
                <md-input
                  v-model="tel"
                  data-vv-name="tel"
                  v-validate= "modelValidations.tel"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel')">【必須】10桁〜11桁の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel') && touched.tel" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ログインパスワード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('password')},
                {'md-valid': !errors.has('password') && touched.password}]">
                <md-input
                  v-model="password"
                  data-vv-name="password"
                  type="password"
                  v-validate= "modelValidations.password">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('password')">6文字以上12文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('password')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              住まいの地域
            </label>
            <div class="md-layout-item md-aSize-100">
              <md-field :class="[
                {'md-error': errors.has('zipCode1')},
                {'md-valid': !errors.has('zipCode1') && touched.zipCode1}]">
                <md-input
                  maxLength="3"
                  v-model="zipCode1"
                  data-vv-name="zipCode1"
                  type="number"
                  v-validate= "modelValidations.zipCode1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('zipCode1') || errors.has('zipCode2')">【必須】前3桁・後4桁の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode1') && touched.zipCode1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-aSize-120">
              <md-field :class="[
                {'md-error': errors.has('zipCode2')},
                {'md-valid': !errors.has('zipCode2') && touched.zipCode2}]">
                <md-input
                  v-model="zipCode2"
                  maxLength="4"
                  data-vv-name="zipCode2"
                  type="number"
                  v-validate= "modelValidations.zipCode2"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode2') && touched.zipCode2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              PIN（暗証番号）
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('pin')},
                {'md-valid': !errors.has('pin') && touched.pin}]">
                <md-input
                  v-model="pin"
                  length="6"
                  data-vv-name="pin"
                  type="password"
                  v-validate= "modelValidations.pin">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('pin')">6桁の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('pin')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('pin') && touched.pin">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              入会日時
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{created_at | timeFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              OS種類
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('device_kind')},
                {'md-valid': !errors.has('device_kind') && touched.device_kind}]">
                <md-select
                  v-model="device_kind"
                  data-vv-name="device_kind"
                  v-validate= "modelValidations.device_kind"
                  required>
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="ios">iOS</md-option>
                  <md-option value="android">Android</md-option>
                  <md-option value="device_other">その他</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              OSバージョン
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('device_os')},
                {'md-valid': !errors.has('device_os') && touched.device_os}]">
                <md-input
                  v-model="device_os"
                  data-vv-name="device_os"
                  type="text"
                  v-validate= "modelValidations.device_os"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('device_os')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('device_os')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('device_os') && touched.device_os">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              モデル名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('device')},
                {'md-valid': !errors.has('device') && touched.device}]">
                <md-input
                  v-model="device"
                  data-vv-name="device"
                  type="text"
                  v-validate= "modelValidations.device"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('device')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('device')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('device') && touched.device">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <md-field class="userStatus">
                <md-select v-model="status" name="status">
                  <md-option value="not_approve">SMS未認証</md-option>
                  <md-option value="sms_approved">仮会員</md-option>
                  <md-option value="approved">本会員</md-option>
                  <md-option value="unsubscribed">退会会員</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    data () {
      return {
        buttonWord: '更新',
        nickname: '',
        sex: '',
        birthday: '',
        tel: '',
        password: '',
        zipCode1: '',
        zipCode2: '',
        pin: '',
        created_at: '',
        device_kind: '',
        device_os: '',
        device: '',
        status: '',
        touched: {
          nickname: false,
          sex: false,
          birthday: false,
          tel: false,
          password: false,
          zipCode1: false,
          zipCode2: false,
          pin: false,
          device_kind: false,
          device_os: false,
          device: false,
          status: false
        },
        modelValidations: {
          nickname:{
            max: 255
          },
          sex:{
            required: true
          },
          birthday:{
            required: true
          },
          password: {
            min: 6,
            max: 12,
            alpha_num: true
          },
          tel:{
            min: 10,
            max: 11,
            required: true
          },
          zipCode1:{
            max: 3,
            numeric: true
          },
          zipCode2:{
            max: 4,
            numeric: true
          },
          pin:{
            length: 6,
            numeric: true
          },
          device_os:{
            max: 255
          },
          device:{
            max: 255
          },
          status:{
            required: true,
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/user_list/' })
      },
      getUsers () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.nickname = response.data.nickname
          this.sex = response.data.sex
          this.birthday = response.data.birth_day
          this.tel = response.data.tel
          this.zipCode1 = response.data.zip1
          this.zipCode2 = response.data.zip2
          this.created_at = response.data.created_at
          response.data.device_kind === null ? this.device_kind='選択しない' :  this.device_kind = response.data.device_kind
          this.device_os = response.data.device_os
          this.device = response.data.device
          this.status = response.data.status
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateStatus()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, 2000);
        });
      },
      updateStatus () {
        var condition = {
          user: {
            nickname: this.nickname,
            sex: this.sex,
            birth_day: this.birthday,
            tel: this.tel,
            zip1: this.zipCode1,
            zip2: this.zipCode2,
            device_kind: this.device_kind === '選択しない' ? '' : this.device_kind,
            device_os: this.device_os,
            device: this.device,
            status: this.status
          }
        }
        if (this.password !== '') {
          condition.user.password = this.password
        }
        if (this.pin !== '') {
          condition.user.pin = this.pin
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/users/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`会員情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の更新に失敗しました')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getUsers()
    },
    watch: {
      nickname(){
        this.touched.nickname = true
      },
      birthday(){
        this.touched.birthday = true
      },
      tel(){
        this.touched.tel = true
      },
      password(){
        this.touched.password = true
      },
      zipCode1(){
        this.touched.zipCode1 = true
      },
      zipCode2(){
        this.touched.zipCode2 = true
      },
      pin(){
        this.touched.pin = true
      },
      device_kind(){
        this.touched.device_kind = true
      },
      device_os(){
        this.touched.device_os = true
      },
      device(){
        this.touched.device = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.userStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
