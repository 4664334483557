<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">端末情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メニウくんID
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末名称
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              テーブル番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('tableName')},
                {'md-valid': !errors.has('tableName') && touched.tableName}]">
                <md-input
                  v-model="tableName"
                  data-vv-name="tableName"
                  type="text"
                  v-validate= "modelValidations.tableName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tableName')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tableName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tableName') && touched.tableName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              レジ番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('registerNo')},
                {'md-valid': !errors.has('registerNo') && touched.registerNo}]">
                <md-input
                  v-model="registerNo"
                  data-vv-name="registerNo"
                  type="number"
                  v-validate= "modelValidations.registerNo"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('registerNo')">【必須】11桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('registerNo')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('registerNo') && touched.registerNo">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              レジ担当者名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('registerStaff')},
                {'md-valid': !errors.has('registerStaff') && touched.registerStaff}]">
                <md-input
                  v-model="registerStaff"
                  data-vv-name="registerStaff"
                  type="text"
                  v-validate= "modelValidations.registerStaff"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('registerStaff')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('registerStaff')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('registerStaff') && touched.registerStaff">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              パスワード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('password')},
                {'md-valid': !errors.has('password') && touched.password}]">
                <md-input
                  v-model="password"
                  data-vv-name="password"
                  type="password"
                  v-validate= "modelValidations.password">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('password')">6文字以上12文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('password')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末種別
            </label>
            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('kinds')},
                {'md-valid': !errors.has('kinds') && touched.kinds}]">
                <md-select
                  v-model="kinds"
                  data-vv-name="kinds"
                  v-validate= "modelValidations.kinds"
                  required>
                  <md-option value="table_seat">テーブル端末</md-option>
                  <md-option value="register">レジ横端末</md-option>
                  <md-option value="bullpen">ブルペン</md-option>
                  <md-option value="kitchen">厨房</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kinds')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kinds')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kinds') && touched.kinds" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末設定ブランド
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <brand-select v-model="brandId" :touchedFlag="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末設定店舗
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :touchedFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              証明書ファイル名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('certificateFileName')},
                {'md-valid': !errors.has('certificateFileName') && touched.certificateFileName}]">
                <md-input
                  v-model="certificateFileName"
                  data-vv-name="certificateFileName"
                  type="text"
                  v-validate= "modelValidations.certificateFileName">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('certificateFileName')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('certificateFileName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('certificateFileName') && touched.certificateFileName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末初期認証状態
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static" :readonly="true">{{initAuthTerm}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              端末初期認証更新時刻
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static" :readonly="true" v-if="this.initAuthTermAt!=null">{{initAuthTermAt | timeFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="stop">停止中</md-option>
                  <md-option value="active">稼動中</md-option>
                  <md-option value="ready">設置準備中</md-option>
                  <md-option value="repair">修理中</md-option>
                  <md-option value="discard">廃棄</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import router from '../packs/router.js'
  import qs from 'qs'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js';

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      BrandSelect,
      ShopSelect
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        buttonWord: '登録',
        code: '',
        tableName: '',
        registerNo: '',
        registerStaff: '',
        name: '',
        password: '',
        kinds: '',
        brandId: '',
        shopId: '',
        certificateFileName: '',
        initAuthTerm: '',
        initAuthTermAt: null,
        status: '',
        shopInitFlag: false,
        touched: {
          code: false,
          tableName: false,
          registerNo: false,
          registerStaff: false,
          name: false,
          password: false,
          kinds: false,
          certificateFileName: false,
          status: false
        },
        modelValidations: {
          code: {
            required: true,
            max: 255
          },
          registerNo: {
            required: true,
            max: 11
          },
          registerStaff: {
            required: true,
            max: 255
          },
          name: {
            required: true,
            max: 255
          },
          tableName: {
            max: 255
          },
          password: {
            min: 6,
            max: 12,
            alpha_num: true
          },
          kinds: {
            required: true
          },
          brandId: {
            required: true
          },
          shopId: {
            required: true
          },
          certificateFileName: {
            max: 255
          },
          status:{
            required: true,
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/shop_terminal_list/' })
      },
      getShopTerminal () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_terminals/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.code = response.data.code
          this.tableName = response.data.table_name
          this.registerNo = response.data.register_no
          this.registerStaff = response.data.register_staff
          this.name = response.data.name
          this.kinds = response.data.kinds
          this.brandId = response.data.brand_id
          this.shopId = response.data.shop_id
          this.certificateFileName = response.data.certificate_file_name
          this.initAuthTerm = this.initAuthTermChecker(response.data.init_auth_term)
          this.initAuthTermAt = response.data.init_auth_term_at
          this.status = response.data.status
          this.shopChangeFlag = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('端末情報を正しく取得できませんでした')
          }
        })
      },
      initAuthTermChecker (init_auth_term) {
        var pushInitAuthStatus
        switch (init_auth_term) {
          case 'success':
            pushInitAuthStatus = '初期認証完了'
            break;
          case 'reauthorize':
            pushInitAuthStatus = '再認証完了'
            break;
          default:
            pushInitAuthStatus = '認証失敗（初期状態）'
        }
        return pushInitAuthStatus
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateShopTerminal()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateShopTerminal(){
        var condition = {
          shop_terminal: {
            'code': this.code,
            'table_name': this.tableName,
            'register_no': this.registerNo,
            'register_staff': this.registerStaff,
            'name': this.name,
            'kinds': this.kinds,
            'brand_id': this.brandId,
            'shop_id': this.shopId,
            'certificate_file_name': this.certificateFileName,
            'status': this.status
          }
        }
        if (this.password !== '') {
          condition.shop_terminal.password = this.password
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/shop_terminals/' : this.AdminApiUrl + '/shop_terminals/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`端末情報を`+this.buttonWord+`しました`,'/admin/shop_terminal_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`端末情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.code = ''
        this.tableName = ''
        this.registerNo = ''
        this.registerStaff = ''
        this.name = ''
        this.kinds = ''
        this.brandId = ''
        this.shopId = ''
        this.certificateFileName = ''
        this.initAuthTerm = ''
        this.status = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
        this.modelValidations.password.required = true
      } else {
        this.buttonWord = '更新'
        this.getShopTerminal()
      }
    },
    destroyed() {
      sessionStorage.removeItem('shopSelect')
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getShopTerminal()
        }
      },
      code(){
        this.touched.code = true
      },
      tableName(){
        this.touched.tableName = true
      },
      registerNo(){
        this.touched.registerNo = true
      },
      registerStaff(){
        this.touched.registerStaff = true
      },
      name(){
        this.touched.name = true
      },
      password(){
        this.touched.password = true
      },
      kinds(){
        this.touched.kinds = true
      },
      brandId(){
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
        if (this.newFlag) {
          this.shopId = ''
        } else {
          this.shopInitFlag ? this.shopId = '' : this.shopInitFlag = true
        }
      },
      certificateFileName(){
        this.touched.certificateFileName = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.form-control-static{
  margin-top: 9px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.ml-5{
  margin-left: 5px;
}

.mb-20{
  margin-bottom: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
