<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">事前注文検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="number"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="accept">注文済み</md-option>
                  <md-option value="ready">準備中</md-option>
                  <md-option value="done">準備完了</md-option>
                  <md-option value="recieved">受渡済み</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-xsmall-size-100 md-form-label">
              注文日
            </label>
            <div class="md-layout-item" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="reserveDay"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="reserveDay = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-xsmall-size-100 md-form-label">
              受取予定日
            </label>
            <div class="md-layout-item" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="readyDay"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="readyDay = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getOrders(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">注文一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">注文番号</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">商品名</div>
                  <div class="tHead_th">電話番号</div>
                  <div class="tHead_th">注文日</div>
                  <div class="tHead_th">受取予定日</div>
                  <div class="tHead_th taR">金額</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.orderNumber }}</div>
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="accept">注文済み</md-option>
                        <md-option value="ready">準備中</md-option>
                        <md-option value="done">準備完了</md-option>
                        <md-option value="recieved">受渡済み</md-option>
                        <md-option value="cancel">キャンセル</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.courseName }}</md-label></div>
                  <div class="tItem_td">{{item.tel}}</div>
                  <div class="tItem_td">{{item.createdAt | timeFormat2}}</div>
                  <div class="tItem_td">{{item.readyTime | timeFormat2}}</div>
                  <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-success mg2"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg2"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from}}件から {{to}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getOrders">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      ShopSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        number: '',
        tel: '',
        shopId: '',
        status: '選択しない',
        treatmentDay: '',
        reserveDay: '',
        readyDay: '',
        tableData: [],
        resultFlag: false,
        currentSort: '',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      updateList () {
        console.log('sort')
      },
      getOrders (page) {
        var condition = {
          page: page,
          order_number: this.number,
          order_status: this.status === '選択しない' ? '' : this.status,
          tel: this.tel,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          created_at: this.reserveDay === '' ? '' : this.initDate(this.reserveDay),
          ready_time: this.readyDay === '' ? '' : this.initDate(this.readyDay)
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/pre_orders/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            orderNumber: res[i].attributes['order-number'],
            status: res[i].attributes['order-status'],
            courseName: res[i].attributes['product-name'],
            tel: res[i].attributes['tel'],
            createdAt: res[i].attributes['created-at'],
            readyTime: res[i].attributes['ready-time'],
            price: res[i].attributes['total-price']
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      updateStatus (id, status) {
        var param = {
          order_status: status
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/pre_orders/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            pre_order : param
          }
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      handleEdit (id) {
        router.push({ path: '/admin/pre_order/edit/' + id })
      },
      getToday () {
        this.selectedEnd = this.initDate(new Date() , 5);
        this.treatmentDay = this.initDate(new Date());
      }
    },
    mounted () {
      if (!this.opt_preOrder) {
        router.push({ path: '/admin/login'})
        return false
      }
      
      this.getToday()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.wth100{
  width: 100px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-card{
  z-index: inherit;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
