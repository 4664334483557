<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">店舗基本情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" v-if="superFlag">
              <md-field :class="[
                {'md-error': errors.has('companyId')},
                {'md-valid': !errors.has('companyId') && touched.companyId}]">
                <md-select
                  v-model="companyId"
                  data-vv-name="companyId"
                  v-validate= "superFlag === true ? modelValidations.companyId : ''"
                  required>
                  <md-option v-for="item in enterpriseList" :value="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyId')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyId')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyId') && touched.companyId" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" v-if="!superFlag">
              <md-field>
                <md-input v-model="companyName" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" v-if="superFlag">
              <brand-select v-model="brandId" :touchedFlag="true"></brand-select>
            </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" v-if="!superFlag">
              <md-field>
                <md-input v-model="brandName" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗コード
            </label>
            <div class="md-layout-item" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('shopCode')},
                {'md-valid': !errors.has('shopCode') && touched.shopCode}]">
                <md-input
                  v-model="shopCode"
                  data-vv-name="shopCode"
                  type="text"
                  v-validate= "modelValidations.shopCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('shopCode')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('shopCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('shopCode') && touched.shopCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="shopCode" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('shopName')},
                {'md-valid': !errors.has('shopName') && touched.shopName}]">
                <md-input
                  v-model="shopName"
                  data-vv-name="shopName"
                  type="text"
                  v-validate= "modelValidations.shopName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('shopName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('shopName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('shopName') && touched.shopName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              郵便番号
            </label>
            <div class="md-layout-item md-aSize-100">
              <md-field :md-counter="false" :class="[
                {'md-error': errors.has('zipCode1')},
                {'md-valid': !errors.has('zipCode1') && touched.zipCode1}]">
                <md-input
                  v-model="zipCode1"
                  data-vv-name="zipCode1"
                  type="number"
                  v-validate= "modelValidations.zipCode1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('zipCode1') || errors.has('zipCode2')">【必須】前3桁・後4桁の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode1') && touched.zipCode1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-aSize-120">
              <md-field :md-counter="false" :class="[
                {'md-error': errors.has('zipCode2')},
                {'md-valid': !errors.has('zipCode2') && touched.zipCode2}]">
                <md-input
                  v-model="zipCode2"
                  data-vv-name="zipCode2"
                  type="number"
                  v-validate= "modelValidations.zipCode2"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode2') && touched.zipCode2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              都道府県
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('prefecture')},
                {'md-valid': !errors.has('prefecture') && touched.prefecture}]">
                <md-select
                  v-model="prefecture"
                  data-vv-name="prefecture"
                  v-validate= "modelValidations.prefecture"
                  required>
                  <md-option value="北海道">北海道</md-option>
                  <md-option value="青森県">青森県</md-option>
                  <md-option value="岩手県">岩手県</md-option>
                  <md-option value="宮城県">宮城県</md-option>
                  <md-option value="秋田県">秋田県</md-option>
                  <md-option value="山形県">山形県</md-option>
                  <md-option value="福島県">福島県</md-option>
                  <md-option value="茨城県">茨城県</md-option>
                  <md-option value="栃木県">栃木県</md-option>
                  <md-option value="群馬県">群馬県</md-option>
                  <md-option value="埼玉県">埼玉県</md-option>
                  <md-option value="千葉県">千葉県</md-option>
                  <md-option value="東京都">東京都</md-option>
                  <md-option value="神奈川県">神奈川県</md-option>
                  <md-option value="新潟県">新潟県</md-option>
                  <md-option value="富山県">富山県</md-option>
                  <md-option value="石川県">石川県</md-option>
                  <md-option value="福井県">福井県</md-option>
                  <md-option value="山梨県">山梨県</md-option>
                  <md-option value="長野県">長野県</md-option>
                  <md-option value="岐阜県">岐阜県</md-option>
                  <md-option value="静岡県">静岡県</md-option>
                  <md-option value="愛知県">愛知県</md-option>
                  <md-option value="三重県">三重県</md-option>
                  <md-option value="滋賀県">滋賀県</md-option>
                  <md-option value="京都府">京都府</md-option>
                  <md-option value="大阪府">大阪府</md-option>
                  <md-option value="兵庫県">兵庫県</md-option>
                  <md-option value="奈良県">奈良県</md-option>
                  <md-option value="和歌山県">和歌山県</md-option>
                  <md-option value="鳥取県">鳥取県</md-option>
                  <md-option value="島根県">島根県</md-option>
                  <md-option value="岡山県">岡山県</md-option>
                  <md-option value="広島県">広島県</md-option>
                  <md-option value="山口県">山口県</md-option>
                  <md-option value="徳島県">徳島県</md-option>
                  <md-option value="香川県">香川県</md-option>
                  <md-option value="愛媛県">愛媛県</md-option>
                  <md-option value="高知県">高知県</md-option>
                  <md-option value="福岡県">福岡県</md-option>
                  <md-option value="佐賀県">佐賀県</md-option>
                  <md-option value="長崎県">長崎県</md-option>
                  <md-option value="熊本県">熊本県</md-option>
                  <md-option value="大分県">大分県</md-option>
                  <md-option value="宮崎県">宮崎県</md-option>
                  <md-option value="鹿児島県">鹿児島県</md-option>
                  <md-option value="沖縄県">沖縄県</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('prefecture')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('prefecture')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('prefecture') && touched.prefecture" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              市区町村
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('city')},
                {'md-valid': !errors.has('city') && touched.city}]">
                <md-input
                  v-model="city"
                  data-vv-name="city"
                  type="text"
                  v-validate= "modelValidations.city"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('city')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('city')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              番地
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('street1')},
                {'md-valid': !errors.has('street1') && touched.street1}]">
                <md-input
                  v-model="street1"
                  data-vv-name="street1"
                  type="text"
                  v-validate= "modelValidations.street1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('street1')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('street1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('street1') && touched.street1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              建物名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('street2')},
                {'md-valid': !errors.has('street2') && touched.street2}]">
                <md-input
                  v-model="street2"
                  data-vv-name="street2"
                  type="text"
                  v-validate= "modelValidations.street2">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('street2')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('street2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('street2') && touched.street2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel1')},
                {'md-valid': !errors.has('tel1') && touched.tel1}]">
                <md-input
                  v-model="tel1"
                  data-vv-name="tel1"
                  type="number"
                  v-validate= "modelValidations.tel1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel1') || errors.has('tel2') || errors.has('tel3')">【必須】それぞれ４桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel1') && touched.tel1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel2')},
                {'md-valid': !errors.has('tel2') && touched.tel2}]">
                <md-input
                  v-model="tel2"
                  data-vv-name="tel2"
                  type="number"
                  v-validate= "modelValidations.tel2"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel2') && touched.tel2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel3')},
                {'md-valid': !errors.has('tel3') && touched.tel3}]">
                <md-input
                  v-model="tel3"
                  data-vv-name="tel3"
                  type="number"
                  v-validate= "modelValidations.tel3"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel3')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel3') && touched.tel3" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              緯度
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-30">
              <md-field>
                <md-input
                  v-model="latitude"
                  type="text"
                  class="readonlyBg"
                  :readonly="true">
                </md-input>
              </md-field>
            </div>
            
            <label class="md-layout-item md-size-5 md-form-label md-xsmall-size-100">
              緯度
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-30">
              <md-field>
                <md-input
                  v-model="longitude"
                  type="text"
                  class="readonlyBg"
                  :readonly="true">
                </md-input>
              </md-field>
            </div>
          </div>

          <category-detail
            ref="category"
            :categoryData="categoryData"
            v-on:get-categories="getCategories">
          </category-detail>

          <category-detail
            ref="areas" 
            :categoryData="areaData"
            v-on:get-categories="getAreas">
          </category-detail>

          <category-detail
            ref="payments"
            :categoryData="paymentData"
            v-on:get-categories="getPayments">
          </category-detail>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              QR×Drive SID
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('qr_drive_sid')},
                {'md-valid': !errors.has('qr_drive_sid') && touched.qr_drive_sid}]">
                <md-input
                  v-model="qr_drive_sid"
                  data-vv-name="qr_drive_sid"
                  type="text"
                  v-validate= "modelValidations.qr_drive_sid">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('qr_drive_sid')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('qr_drive_sid')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('qr_drive_sid') && touched.qr_drive_sid">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="qr_drive_sid" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              QR×Drive SignKey
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('qr_drive_sign_key')},
                {'md-valid': !errors.has('qr_drive_sign_key') && touched.qr_drive_sign_key}]">
                <md-input
                  v-model="qr_drive_sign_key"
                  data-vv-name="qr_drive_sign_key"
                  type="text"
                  v-validate= "modelValidations.qr_drive_sign_key">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('qr_drive_sign_key')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('qr_drive_sign_key')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('qr_drive_sign_key') && touched.qr_drive_sign_key">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="qr_drive_sign_key" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スマートオーダー<br>店舗コード
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('smart_order_code')},
                {'md-valid': !errors.has('smart_order_code') && touched.smart_order_code}]">
                <md-input
                  v-model="smartOrderCode"
                  data-vv-name="smart_order_code"
                  type="text"
                  v-validate= "modelValidations.smart_order_code">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('smart_order_code')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('smart_order_code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('smart_order_code') && touched.smart_order_code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="smart_order_code" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              MIS IP アドレス
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('mis_address')},
                {'md-valid': !errors.has('mis_address') && touched.mis_address}]">
                <md-input
                  v-model="misAddress"
                  data-vv-name="mis_address"
                  type="text"
                  v-validate= "modelValidations.mis_address">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('mis_address')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('mis_address')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('mis_address') && touched.mis_address">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="mis_address" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>

        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">店舗掲載情報</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャッチコピー
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="catch_phrase"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗紹介文
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="description" md-counter="1024" maxlength="1024"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗紹介URL
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('webUrl')},
                {'md-valid': !errors.has('webUrl') && touched.webUrl}]">
                <md-input
                  v-model="webUrl"
                  data-vv-name="webUrl"
                  v-validate= "modelValidations.webUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('webUrl')">半角英数記号のURL形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('webUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('webUrl') && touched.webUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              営業時間
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="business_hours"
                  data-vv-name="business_hours"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              定休日
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="holiday"
                  data-vv-name="holiday"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アクセス
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="access"
                  data-vv-name="access"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗掲載画像
            </label>

            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!image1">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="image1" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="image1" data-id="image1"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!image1">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="image1">
                  </md-button>
                </div>
              </div>
            </div>

            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!image2">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="image2" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="image2" data-id="image2"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!image2">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="image2">
                  </md-button>
                </div>
              </div>
            </div>
                        
            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!image3">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="image3" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="image3" data-id="image3"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!image3">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="image3">
                  </md-button>
                </div>
              </div>
            </div>

          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              バージョン管理
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100 pt7">
              <p class="form-control-static">{{ version }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              おすすめバージョン管理
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100 pt7">
              <p class="form-control-static">{{ recommendVersion }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              無料Wi-Fi接続方法
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('wifi_url')},
                {'md-valid': !errors.has('wifi_url') && touched.wifi_url}]">
                <md-input
                  v-model="wifiUrl"
                  data-vv-name="wifi_url"
                  type="text"
                  v-validate= "modelValidations.wifi_url">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('wifi_url')">半角英数記号のURL形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('wifi_url')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('wifi_url') && touched.wifi_url">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="wifi_url" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              人数入力画面ラベル1
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="ninzu1Label"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              人数入力画面ラベル2
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="ninzu2Label"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              人数入力画面ラベル3
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="ninzu3Label"
                  type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗WiFiパスワード
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-show="superFlag">
              <md-field :class="[
                {'md-error': errors.has('wifi_password')},
                {'md-valid': !errors.has('wifi_password') && touched.wifi_password}]">
                <md-input
                  v-model="wifiPassword"
                  data-vv-name="wifi_password"
                  type="text"
                  v-validate= "modelValidations.wifi_password">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('wifi_password')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('wifi_password')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('wifi_password') && touched.wifi_password">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item" v-show="!superFlag">
              <md-field>
                <md-input v-model="wifi_password" class="readonlyBg" :readonly="true"></md-input>
              </md-field>
            </div>
          </div> -->

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
    
    <div class="md-layout-item md-size-100" v-if="this.$route.path == '/admin/shop/edit/' + this.$route.params.id">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">rsync連携情報</h4>
        </md-card-header>

        <md-card-content class="formLayout">

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の注文数制限
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderItemMaxNum" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の1商品の注文数制限
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="itemLimitNum" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の注文商品種類数制限
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="itemKindLimitNum" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              食べ放題商品の注文数制限機能
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="freeFoodOrderLimit" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の食べ放題商品制限数
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="freeFoodLimitExtNum" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              飲み放題商品の注文数制限機能
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="freeDrinkOrderLimit" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の飲み放題商品制限数
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="freeDrinkLimitExtNum" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              数量限定注文機能
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="useLimitedItem" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              数量限定注文機能対象分類
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="limitedItemMenu" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オーダー単位の限定注文数制限
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderLimitOption" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="statusShow" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス1
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status1Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス2
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status2Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス3
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status3Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス4
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status4Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス5
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status5Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス6
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status6Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              調理配膳ステータス7
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="status7Text" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              食べ放題終了警告表示時間
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="free1InfoScrTime" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              飲み放題終了警告表示時間
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="free2InfoScrTime" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              アルコール対象商品
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="alcoholFlag" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店員呼び出し機能
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="useExCall" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              バーコード決済利用
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="registerBarcodeType" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オンライン決済利用
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="onlinePayment" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文履歴ボタン非表示
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="hideOrderHistory" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              おかわりボタン非表示
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="hideRepeatOrder" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              0円金額非表示
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="hideZeroPrice" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名非表示
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="hideItemName" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品金額非表示
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="hideMenuPrice" class="readonlyBg" :readonly="true" style="padding:5;"></md-input></md-field>
            </div>
          </div>

        </md-card-content>
      </md-card>
      <div class="md-layout">
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-rose md-lg" @click.prevent="sendResetTelegram">初期化</md-button>
          <md-button class="md-danger md-lg mL40" @click.prevent="sendDataReset">クリア</md-button>
        </md-card-actions>
      </div>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import CategoryDetail from '../components/additional/CategoryDetail.vue'
  import router from '../packs/router.js'
  import qs from 'qs'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js'
  import 'babel-polyfill'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      BrandSelect,
      CategoryDetail
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        newFlag: false,
        id: '',
        buttonWord: '登録',
        shopName: '',
        companyId: '',
        companyName: '',
        enterpriseList: [],        
        shopCode: '',
        brandId: '',
        brandName: '',
        zipCode1: '',
        zipCode2: '',
        prefecture: '',
        city: '',
        street1: '',
        street2: '',
        tel1: '',
        tel2: '',
        tel3: '',
        latitude: '',
        longitude: '',
        qr_drive_sid: '',
        qr_drive_sign_key: '',
        status: '',
        smartOrderCode: '',
        misAddress: '',
        catch_phrase: '',
        description: '',
        webUrl: '',
        business_hours: '',
        holiday: '',
        access: '',
        image1: '',
        image2: '',
        image3: '',
        categories: '',
        categoryData: {
          title: "店舗カテゴリ",
          apiUrl: '/job_categories/'
        },
        areas: '',
        areaData: {
          title: "店舗エリア",
          apiUrl: '/areas/'
        },
        payments: '',
        paymentData: {
          title: "対応支払い方法",
          apiUrl: '/shop_payments/'
        },
        version: '',
        recommendVersion: '',
        wifiUrl: '',
        wifiPassword: '',
        orderItemMaxNum: '',
        itemLimitNum: '',
        itemKindLimitNum: '',
        freeFoodOrderLimit: '',
        freeFoodLimitExtNum: '',
        freeDrinkOrderLimit: '',
        freeDrinkLimitExtNum: '',
        useLimitedItem: '',
        orderLimitOption: '',
        statusShow: '',
        status1Text: '',
        status2Text: '',
        status3Text: '',
        status4Text: '',
        status5Text: '',
        status6Text: '',
        status7Text: '',
        free1InfoScrTime: '',
        free2InfoScrTime: '',
        useExCall: '',
        registerBarcodeType: '',
        onlinePayment: '',
        hideOrderHistory: '',
        hideRepeatOrder: '',
        hideZeroPrice: '',
        hideItemName: '',
        hideMenuPrice: '',
        touched: {
          shopName: false,
          shopCode: false,
          brandId: false,
          companyId: false,
          zipCode1: false,
          zipCode2: false,
          prefecture: false,
          city: false,
          street1: false,
          street2: false,
          tel1: false,
          tel2: false,
          tel3: false,
          qr_drive_sid: false,
          qr_drive_sign_key: false,
          status: false,
          smartOrderCode: false,
          misAddress: false,
          wifiUrl: false,
          wifiPassword: false,
          webUrl: false
        },
        modelValidations: {
          shopCode: {
            required: true,
            max: 255
          },
          shopName: {
            required: true,
            max: 255
          },
          companyId: {
            required: true
          },
          zipCode1: {
            required: true,
            max: 3,
            min: 3,
            numeric: true
          },
          zipCode2:{
            required: true,
            max: 4,
            min: 4,
            numeric: true
          },
          prefecture: {
            required: true
          },
          brandId: {
            required: true
          },
          city: {
            required: true,
            max: 255
          },
          street1:{
            required: true,
            max: 255
          },
          street2:{
            max: 255
          },
          tel1:{
            required: true,
            max: 4,
            numeric: true
          },
          tel2:{
            required: true,
            max: 4,
            numeric: true
          },
          tel3:{
            required: true,
            max: 4,
            numeric: true
          },
          qr_drive_sid: {
            max: 255
          },
          qr_drive_sign_key: {
            max: 255
          },
          status:{
            required: true,
          },
          smartOrderCode:{
            required: true,
          },
          misAddress:{
            required: true,
          },
          wifiUrl:{
            url: true,
          },
          // wifiPassword:{
          //   required: true,
          // },
          webUrl:{
            url: true,
            max: 255
          }
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      getCategories(categories){
        this.categories = categories  // $emitの第二引数のデータが入っている
      },
      getAreas(areas){
        this.areas = areas
      },
      getPayments(payments){
        this.payments = payments
      },
      getShop () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.companyId = response.data.company_id
          this.companyName = response.data.company_name
          this.shopName = response.data.name
          this.shopCode = response.data.code
          this.brandId = response.data.brand_id
          this.brandName = response.data.brand_name
          this.zipCode1 = response.data.zip1
          this.zipCode2 = response.data.zip2
          this.prefecture = response.data.pref
          this.city = response.data.city
          this.street1 = response.data.street
          this.street2 = response.data.street2
          this.tel1 = response.data.tel1
          this.tel2 = response.data.tel2
          this.tel3 = response.data.tel3
          this.latitude = response.data.latitude
          this.longitude = response.data.longitude
          this.qr_drive_sid = response.data.qr_drive_sid
          this.qr_drive_sign_key = response.data.qr_drive_sign_key
          this.status = response.data.status
          this.smartOrderCode = response.data.smart_order_code
          this.misAddress = response.data.mis_address
          this.catch_phrase = response.data.catch_phrase
          this.description = response.data.description
          this.webUrl = response.data.website
          this.business_hours = response.data.business_hours
          this.holiday = response.data.holiday
          this.access = response.data.access
          this.image1 = response.data.image1_url
          this.image2 = response.data.image2_url
          this.image3 = response.data.image3_url
          this.version = response.data.version
          this.recommendVersion = response.data.recommend_version
          this.wifiUrl = response.data.wifi_url
          this.wifiPassword = response.data.wifi_password
          this.orderItemMaxNum = response.data.order_item_max_num
          this.itemLimitNum = response.data.item_limit_num
          this.itemKindLimitNum = response.data.item_kind_limit_num
          this.freeFoodLimitExtNum = response.data.free_food_limit_ext_num
          this.orderLimitOption = response.data.order_limit_option
          this.freeDrinkLimitExtNum = response.data.free_drink_limit_ext_num
          this.status1Text = response.data.status1_text
          this.status2Text = response.data.status2_text
          this.status3Text = response.data.status3_text
          this.status4Text = response.data.status4_text
          this.status5Text = response.data.status5_text
          this.status6Text = response.data.status6_text
          this.status7Text = response.data.status7_text
          this.free1InfoScrTime = response.data.free1_info_scr_time
          this.free2InfoScrTime = response.data.free2_info_scr_time
          this.limitedItemMenu = response.data.limited_item_menu
          this.alcoholFlag = response.data.alcohol_flag
          this.ninzu1Label = response.data.ninzu_1_label
          this.ninzu2Label = response.data.ninzu_2_label
          this.ninzu3Label = response.data.ninzu_3_label
          // レンダリングの完了（CategoryDetailコンポーネント内のmounted処理終了）を待つため、5秒間、0.5秒間隔で確認する。
          this.createCategoryListLoop(response)
          switch (response.data.free_food_order_limit) {
            case 'not_active':
              this.freeFoodOrderLimit = '無効'
              break;
            case 'active':
              this.freeFoodOrderLimit = '有効'
              break;
              default:
              this.freeFoodOrderLimit = '識別不明'
          }
          switch (response.data.free_drink_order_limit) {
            case 'not_active':
              this.freeDrinkOrderLimit = '無効'
              break;
            case 'active':
              this.freeDrinkOrderLimit = '有効'
              break;
              default:
              this.freeDrinkOrderLimit = '識別不明'
          }
          switch (response.data.use_limited_item) {
            case 'not_active':
              this.useLimitedItem = '無効'
              break;
            case 'total_amount':
              this.useLimitedItem = '小分類画面内の商品合計で制限'
              break;
            case 'separate':
              this.useLimitedItem = '小分類画面内の商品個別で制限'
              break;
              default:
              this.useLimitedItem = '識別不明'
          }
          switch (response.data.status_show) {
            case 'not_active':
              this.statusShow = '無効'
              break;
            case 'active':
              this.statusShow = '有効'
              break;
              default:
              this.statusShow = '識別不明'
          }
          switch (response.data.use_ex_call) {
            case 'not_active':
              this.useExCall = '無効'
              break;
            case 'active':
              this.useExCall = '有効'
              break;
            case 'active_2':
              this.useExCall = '有効'
              break;
              default:
              this.useExCall = '識別不明'
          }
          switch (response.data.register_barcode_type) {
            case 'not_active':
              this.registerBarcodeType = '無効'
              break;
            case 'active':
              this.registerBarcodeType = '有効'
              break;
              default:
              this.registerBarcodeType = '識別不明'
          }
          switch (response.data.online_payment) {
            case 'not_active':
              this.onlinePayment = '無効'
              break;
            case 'active':
              this.onlinePayment = '有効'
              break;
              default:
              this.onlinePayment = '識別不明'
          }
          switch (response.data.hide_order_history) {
            case 'not_active':
              this.hideOrderHistory = '非表示'
              break;
            case 'active':
              this.hideOrderHistory = '表示'
              break;
              default:
              this.hideOrderHistory = '識別不明'
          }
          switch (response.data.hide_repeat_order) {
            case 'not_active':
              this.hideRepeatOrder = '非表示'
              break;
            case 'active':
              this.hideRepeatOrder = '表示'
              break;
              default:
              this.hideRepeatOrder = '識別不明'
          }
          switch (response.data.hide_zero_price) {
            case 'not_active':
              this.hideZeroPrice = '非表示'
              break;
            case 'active':
              this.hideZeroPrice = '表示'
              break;
            default:
              this.hideZeroPrice = '識別不明'
          }
          switch (response.data.hide_item_name) {
            case 'not_active':
              this.hideItemName = '非表示'
              break;
            case 'active':
              this.hideItemName = '表示'
              break;
            default:
              this.hideItemName = '識別不明'
          }
          switch (response.data.hide_menu_price) {
            case 'not_active':
              this.hideMenuPrice = '非表示'
              break;
            case 'active':
              this.hideMenuPrice = '表示'
              break;
            default:
              this.hideMenuPrice = '識別不明'
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗情報を正しく取得できませんでした')
          }
        })
      },
      createCategoryList (array, obj) {
        array.splice(0)
        var parent = obj
        for (var i in parent) {
          var item = {
            id: parent[i].id,
            name: parent[i].name,
            children: [],
            openFlag: false,
            check: parent[i].checked
          }
          var child = parent[i].children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: child[j].checked
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: child[j].children[k].checked
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          array.push(item)
        }
      },
      sleep (time) {
        return new Promise(resolve => {
          setTimeout(resolve, time)
        })
      },
      async createCategoryListLoop (response) {
        try {
          for (let i = 0; i < 10; i++) {
            if (this.categories && this.areas && this.payments) {
              this.createCategoryList(this.categories, response.data.categories)
              this.createCategoryList(this.areas, response.data.areas)
              this.createCategoryList(this.payments, response.data.payments)
              break;
            } else if (i === 9) {
              throw "店舗情報を正しく取得できませんでした";
            } else {
              await this.sleep(500)
            }
          }
        } catch (error) {
          this.dangerNotify(error)
        }
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateShop()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateShop(){
        var condition = {
          shop: {
            'company_id': this.superFlag === true ? this.companyId : this.$Cookies.get('companyId'),
            'brand_id': this.brandId,
            'code': this.shopCode,
            'name': this.shopName,
            'pref': this.prefecture,
            'city': this.city,
            'street': this.street1,
            'street2': this.street2,
            'tel1': this.tel1,
            'tel2': this.tel2,
            'tel3': this.tel3,
            'zip1': this.zipCode1,
            'zip2': this.zipCode2,
            'latitude': this.latitude,
            'longitude': this.longitude,
            'qr_drive_sid': this.qr_drive_sid,
            'qr_drive_sign_key': this.qr_drive_sign_key,
            'status': this.status,
            'smart_order_code': this.smartOrderCode,
            'mis_address': this.misAddress,
            'catch_phrase': this.catch_phrase,
            'description': this.description,
            'website': this.webUrl,
            'wifi_url': this.wifiUrl,
            'wifi_password': this.wifiPassword,
            'business_hours': this.business_hours,
            'holiday': this.holiday,
            'access': this.access,
            'categories': this.checkCategories(this.categories,"店舗カテゴリ"),
            'areas': this.checkCategories(this.areas,"店舗エリア"),
            'payments': this.checkCategories(this.payments,"対応支払い方法"),
            'ninzu_1_label': this.ninzu1Label,
            'ninzu_2_label': this.ninzu2Label,
            'ninzu_3_label': this.ninzu3Label
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.image1 === '' || this.image1 === null){
          condition.shop.image1 = ''
        } else if (this.image1.indexOf('/uploads/') == -1) {
          condition.shop.image1 = this.image1
        }
        if(this.image2 === '' || this.image2 === null){
          condition.shop.image2 = ''
        } else if (this.image2.indexOf('/uploads/') == -1) {
          condition.shop.image2 = this.image2
        }
        if(this.image3 === '' || this.image3 === null){
          condition.shop.image3 = ''
        } else if (this.image3.indexOf('/uploads/') == -1) {
          condition.shop.image3 = this.image3
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/shops/' : this.AdminApiUrl + '/shops/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          sessionStorage.removeItem('shopSelect')
          this.successAndBackPage(`店舗情報を`+this.buttonWord+`しました`,'/admin/shop_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`店舗情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      checkCategories (categories, text) {
        var checkedCategories = []
        for (var i in categories) {
          if (categories[i].check) {
            checkedCategories.push(categories[i].id)
          }
          var child = categories[i].children
          for (var j in child) {
            if (child[j].check) {
              checkedCategories.push(child[j].id)
            }
            for (var k in child[j].children) {
              if (child[j].children[k].check) {
              checkedCategories.push(child[j].children[k].id)
              }
            }
          }
        }
        if(checkedCategories.length === 0){
          this.validateError(text + "を一つ以上チェックして下さい。");
          exit;
        }
        return checkedCategories.join()
      },
      sendResetTelegram(){
        this.successAndBackPage('初期化通知の送信を開始しました')
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shops/' + this.$route.params.id + '/reset_telegram/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.result === 'OK') {
            this.successAndBackPage('初期化通知の送信に成功しました')
          } else {
            this.dangerNotify('初期化通知の送信に失敗しました')
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('初期化通知の送信に失敗しました')
          }
        })
      },
      sendDataReset(){
        this.successAndBackPage('データクリアを開始しました')
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shops/' + this.$route.params.id + '/data_reset/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.result === 'OK') {
            this.successAndBackPage('データクリアに成功しました')
          } else {
            this.dangerNotify('データクリアに失敗しました')
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('データクリアに失敗しました')
          }
        })
      },
      getCompany () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.length === 0) {
            this.successAndBackPage(`選択できる事業者がありませんでした`, '')
          } else {
            this.createList(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('事業者情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        this.enterpriseList.splice(0)
        for (var i = 0;i < obj.length; i++) {
          var item = {
            id: obj[i].id,
            name: obj[i].name,
          }
          this.enterpriseList.push(item)
        }
      },
      resetData () {
        this.id = ''
        this.shopName = ''
        this.brandId = ''
        this.brandName = ''
        this.companyId = ''
        this.companyName = ''
        this.shopCode = ''
        this.zipCode1 = ''
        this.zipCode2 = ''
        this.prefecture = ''
        this.city = ''
        this.street1 = ''
        this.street2 = ''
        this.tel1 = ''
        this.tel2 = ''
        this.tel3 = ''
        this.latitude = ''
        this.longitude = ''
        this.qr_drive_sid = ''
        this.qr_drive_sign_key = ''
        this.status = ''
        this.smartOrderCode = ''
        this.misAddress = ''
        this.catch_phrase = ''
        this.description = ''
        this.webUrl = ''
        this.business_hours = ''
        this.holiday = ''
        this.access = ''
        this.image1 = ''
        this.image2 = ''
        this.image3 = ''
        this.version = ''
        this.recommendVersion = ''
        this.categories = ''
        this.areas = ''
        this.payments = ''
        this.wifiUrl = ''
        this.wifiPassword = ''
        this.$refs.category.getCategory()
        this.$refs.areas.getCategory()
        this.$refs.payments.getCategory()
        this.$validator.reset()
        EventBus.$emit('reset', this.errors)
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.description
        this.description = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      }
    },
    mounted () {
      // role check
      if (this.superFlag) {
        this.getCompany()
      } else if (!this.superFlag) {
        this.getCompany(this.companyId)
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getShop()
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
        this.getCompany()
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getShop()
        }
      },
      shopName(){
        this.touched.shopName = true
      },
      shopCode(){
        this.touched.shopCode = true
      },
      companyId(){
        this.touched.companyId = true
      },
      prefecture(){
        this.touched.prefecture = true
      },
      brandId(){
        this.touched.brandId = true
      },
      city(){
        this.touched.city = true
      },
      street1(){
        this.touched.street1 = true
      },
      street2(){
        this.touched.street2 = true
      },
      zipCode1(){
        this.touched.zipCode1 = true
      },
      zipCode2(){
        this.touched.zipCode2 = true
      },
      tel1(){
        this.touched.tel1 = true
      },
      tel2(){
        this.touched.tel2 = true
      },
      tel3(){
        this.touched.tel3 = true
      },
      qr_drive_sid(){
        this.touched.qr_drive_sid = true
      },
      qr_drive_sign_key(){
        this.touched.qr_drive_sign_key = true
      },
      status(){
        this.touched.status = true
      },
      smartOrderCode(){
        this.touched.smartOrderCode = true
      },
      misAddress(){
        this.touched.smartOrderCode = true
      },
      webUrl(){
        this.touched.webUrl = true
      },
      wifiUrl(){
        this.touched.wifiUrl = true
      },
      wifiPassword(){
        this.touched.wifiPassword = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.ml-5{
  margin-left: 5px;
}

.mb-20{
  margin-bottom: 20px;
}

.pt7{
  padding-top: 7px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.readonlyBg{
  background-color: rgb(211, 211, 211);
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
