<template>
  <div class="wrapper" :class="[{'nav-open': $sidebar.showSidebar}, {'rtl': $route.meta.rtlActive}]">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <template slot="links">
        <sidebar-item :link="{name: '事前注文管理', icon: 'local_grocery_store'}" v-if="opt_preOrder">
          <sidebar-item :link="{name: '注文一覧', path: '/admin/pre_order_list'}"></sidebar-item>
          <sidebar-item :link="{name: '商品一覧', path: '/admin/pre_product_list'}" v-if="(superFlag || companyFlag)"></sidebar-item>
          <sidebar-item :link="{name: '商品登録', path: '/admin/pre_product/new'}" v-if="(superFlag || companyFlag)"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '事前予約管理', icon: 'event'}" v-if="opt_reserveOrder">
          <sidebar-item :link="{name: '予約一覧', path: '/admin/reserve_order_list'}"></sidebar-item>
          <sidebar-item :link="{name: '商品一覧', path: '/admin/reserve_product_list'}" v-if="(superFlag || companyFlag)"></sidebar-item>
          <sidebar-item :link="{name: '商品登録', path: '/admin/reserve_product/new'}" v-if="(superFlag || companyFlag)"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '商品カテゴリ管理', icon: 'dns', path: '/admin/category_list'}" v-if="(superFlag || companyFlag) && (opt_preOrder || opt_reserveOrder)">
        </sidebar-item>
        <sidebar-item  :link="{name: 'スマートオーダー注文管理', icon: 'phone_android', path: '/admin/smart_order_list'}"></sidebar-item>
        <sidebar-item  :link="{name: '決済履歴管理', icon: 'dashboard', path: '/admin/shop_order_list'}"></sidebar-item>
        <sidebar-item  :link="{name: 'ハウスマネー管理', icon: 'attach_money', path: '/admin/house_money_list'}" v-if="opt_houseMoney">
        </sidebar-item>
        <sidebar-item :link="{name: '会員管理', icon: 'group'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: '会員一覧', path: '/admin/user_list'}"></sidebar-item>
          <sidebar-item :link="{name: '会員ランク設定', path: '/admin/user_rank_list'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Push通知管理', icon: 'phonelink_ring'}" v-if="((superFlag || companyFlag) && opt_pushNotice)">
          <sidebar-item :link="{name: 'Push通知履歴', path: '/admin/notification_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'Push通知予約設定', path: '/admin/reserve_notification/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'メルマガ配信管理', icon: 'mail_outline'}" v-if="((superFlag || companyFlag) && opt_mailMagazine)">
          <sidebar-item :link="{name: 'メルマガ配信履歴', path: '/admin/mail_magazine_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'メルマガ配信予約設定', path: '/admin/reserve_mail_magazine/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '販促管理', icon: 'message'}">
          <sidebar-item :link="{name: 'お知らせ一覧', path: '/admin/information_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'お知らせ新規登録', path: '/admin/information/new'}"></sidebar-item>
          <sidebar-item :link="{name: 'クーポン一覧', path: '/admin/coupon_list'}" v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
          <sidebar-item :link="{name: 'クーポン新規登録', path: '/admin/coupon/new'}"  v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
          <sidebar-item :link="{name: 'ポイントカード一覧', path: '/admin/point_card_list'}" v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
          <sidebar-item :link="{name: 'ポイントカード新規登録', path: '/admin/point_card/new'}"  v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
          <sidebar-item :link="{name: 'スタンプクーポン一覧', path: '/admin/stamp_coupon_list'}" v-if="((superFlag || companyFlag) && opt_stamp)"></sidebar-item>
          <sidebar-item :link="{name: 'スタンプクーポン新規登録', path: '/admin/stamp_coupon/new'}"  v-if="((superFlag || companyFlag) && opt_stamp)"></sidebar-item>
          <sidebar-item :link="{name: 'ポイント設定', path: '/admin/point_setting/edit/' + this.$Cookies.get('companyId')}" v-if="((superFlag || companyFlag) && opt_point)"></sidebar-item>
          <sidebar-item :link="{name: 'スタンプ設定', path: '/admin/stamp_setting/edit/' + this.$Cookies.get('companyId')}" v-if="((superFlag || companyFlag) && opt_stamp)"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '店舗管理', icon: 'store_mall_directory'}">
          <sidebar-item :link="{name: '店舗一覧', path: '/admin/shop_list'}"></sidebar-item>
          <sidebar-item :link="{name: '店舗登録', path: '/admin/shop/new'}" v-if="superFlag"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '店舗カード管理', icon: 'credit_card'}" v-if="superFlag">
          <sidebar-item :link="{name: '店舗カード一覧', path: '/admin/shop_card_list'}"></sidebar-item>
          <sidebar-item :link="{name: '店舗カード登録', path: '/admin/shop_card/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '端末管理', icon: 'computer'}" v-if="superFlag">
          <sidebar-item :link="{name: '端末一覧', path: '/admin/shop_terminal_list'}"></sidebar-item>
          <sidebar-item :link="{name: '端末登録', path: '/admin/shop_terminal/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '決済端末管理', icon: 'computer'}" v-if="superFlag">
          <sidebar-item :link="{name: '決済端末一覧', path: '/admin/payment_terminal_list'}"></sidebar-item>
          <sidebar-item :link="{name: '決済端末登録', path: '/admin/payment_terminal/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'QA管理', icon: 'question_answer'}" v-if="superFlag">
          <sidebar-item :link="{name: 'QAカテゴリ一覧', path: '/admin/qa_category_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'QA情報一覧', path: '/admin/qa_information_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'QA情報登録', path: '/admin/qa_information/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'お問合せ管理', icon: 'contact_mail'}" v-if="superFlag">
          <sidebar-item :link="{name: 'お問合せカテゴリ一覧', path: '/admin/contact_category_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'お問合せ一覧', path: '/admin/contact_list'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'スタッフ管理', icon: 'group'}" v-if="opt_reserveOrder">
          <sidebar-item :link="{name: 'スタッフ一覧', path: '/admin/staff_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'スタッフ登録', path: '/admin/staff/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '分析・集計管理', icon: 'insert_chart'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: '期間別売上レポート', path: '/admin/analysis_period'}"></sidebar-item>
          <sidebar-item :link="{name: '会員別売上レポート', path: '/admin/analysis_user'}"></sidebar-item>
          <sidebar-item :link="{name: '商品別売上レポート', path: '/admin/analysis_product'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item  :link="{name: 'ブランド管理', icon: 'style', path: '/admin/brand_list'}" v-if="superFlag">
        </sidebar-item>
        <sidebar-item  :link="{name: '業種カテゴリ管理', icon: 'dns', path: '/admin/job_category_list'}" v-if="superFlag">
        </sidebar-item>        
        <sidebar-item  :link="{name: 'エリア管理', icon: 'dns', path: '/admin/area_list'}" v-if="superFlag">
        </sidebar-item>
        <sidebar-item  :link="{name: '支払いマスタ管理', icon: 'attach_money', path: '/admin/payment_list'}" v-if="superFlag">
        </sidebar-item>
        <sidebar-item
           v-if="superFlag || companyFlag"
          :link="{name: 'システム管理', icon: 'widgets', path: '/widgets'}">
          <sidebar-item :link="{name: '管理ユーザー一覧', path: '/admin/admin_user_list'}"></sidebar-item>
          <sidebar-item :link="{name: '管理ユーザー新規登録', path: '/admin/admin_user/new'}"></sidebar-item>
          <sidebar-item :link="{name: 'CSVインポート', path: '/admin/csv_import'}" v-if="superFlag"></sidebar-item>
          <sidebar-item :link="{name: 'CSVダウンロード', path: '/admin/csv_export'}" v-if="superFlag"></sidebar-item>
          <sidebar-item :link="{name: 'アプリバージョン登録', path: '/admin/require_version'}" v-if="superFlag"></sidebar-item>
          <sidebar-item :link="{name: '連携履歴一覧', path: '/admin/telegram_history_list'}" v-if="superFlag"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '事業者管理', icon: 'business', path: '/widgets'}" v-if="superFlag">
          <sidebar-item :link="{name: '事業者一覧', path: '/admin/company_list'}"></sidebar-item>
          <sidebar-item :link="{name: '事業者新規登録', path: '/admin/company/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'ログアウト', icon: 'exit_to_app', path: '/admin/login' }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div :class="{content: !$route.meta.hideContent}" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar'
  import 'perfect-scrollbar/css/perfect-scrollbar.css'

  function hasElement (className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar (className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from './extra/UserMenu.vue'
  import {SlideYDownTransition, ZoomCenterTransition} from 'vue2-transitions'
  import role from "../../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      TopNavbar,
      ContentFooter,
      UserMenu,
      DashboardContent,
      SlideYDownTransition,
      ZoomCenterTransition
    },
    data () {
      return {}
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    mounted () {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');
        initScrollbar('main-panel');

        docClasses.add('perfect-scrollbar-on')
      } else {
        docClasses.add('perfect-scrollbar-off')
      }
    }
  }

</script>
<style lang="scss">
  $scaleSize: .95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
</style>
