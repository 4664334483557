<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">端末検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              事業者
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <company-select v-model="companyId" :noSelectFlag="true"></company-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <brand-select ref="brand" v-model="brandId" :noSelectFlag="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メニウくんID
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="code"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="stop">停止中</md-option>
                  <md-option value="active">稼動中</md-option>
                  <md-option value="ready">設置準備中</md-option>
                  <md-option value="repair">修理中</md-option>
                  <md-option value="discard">廃棄</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getShopTerminals">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="handleCsvDownload()" v-show="this.superFlag">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">端末一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">メニウくんID</div>
                  <div class="tHead_th">端末名称</div>
                  <div class="tHead_th">店舗名</div>
                  <div class="tHead_th">テーブル番号</div>
                  <div class="tHead_th">ブランド</div>
                  <div class="tHead_th">更新日</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.code}}</div>
                  <div class="tItem_td">{{ item.name }}</div>
                  <div class="tItem_td">{{ item.shopName }}</div>
                  <div class="tItem_td">{{ item.tableName }}</div>
                  <div class="tItem_td">{{ item.brandName }}</div>
                  <div class="tItem_td">{{ item.updateDay | dateFormat }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="handleEdit(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getShopTerminals">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      CompanySelect,
      BrandSelect,
      ShopSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        code: '',
        name: '',
        companyId: '',
        brandId: '',
        shopId: '',
        status: '選択しない',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        statusRadio: 'all',
        opHousemoney: null,
        opSharing: null,
        opAPI: null
      }
    },
    methods: {
      getShopTerminals (page) {
        var condition = {
          page: page,
          code: this.code,
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          status: this.status === '選択しない' ? '' : this.status
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_terminals/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('端末情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            code: res[i].attributes.code,
            name: res[i].attributes.name,
            shopName: res[i].attributes['shop-name'],
            tableName: res[i].attributes['table-name'],
            brandName: res[i].attributes['brand-name'],
            contractDay: res[i].attributes['created-at'],
            updateDay: res[i].attributes['updated-at']
          }
          switch (res[i].attributes.status) {
            case 'stop':
              item.status = '停止中'
              break;
            case 'active':
              item.status = '稼動中'
              break;
            case 'ready':
              item.status = '設置準備中'
              break;
            case 'repair':
              item.status = '修理中'
              break;
            case 'discard':
              item.status = '廃棄'
              break;
            default:
              item.status = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleCsvDownload () {
        swal({
          title: 'CSVダウンロード処理を開始します。',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          code: this.code,
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          status: this.status === '選択しない' ? '' : this.status
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shop_terminals/export_csv',
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "ShopTerminal not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      },   
      handleEdit (item) {
        router.push({ path: '/admin/shop_terminal/edit/' + item.id })
      },
      handleDelete (item) {
        swal({
          title: `この端末情報を削除してもよろしいですか?`,
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/shop_terminals/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`端末情報を削除しました`)
          this.getShopTerminals(1)

        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('端末情報を削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      }
    },
    mounted () {
      // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect') 
      sessionStorage.removeItem('shopSelect') 
    },
    watch: {
      companyId () {
        this.$refs.brand.companyId = this.companyId
        this.$refs.brand.resetBrand()
        this.$refs.shop.companyId = this.companyId
        this.$refs.shop.resetShop()
      },
      brandId () {
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.btnSpace{
  margin: 0 2px;
}

.commentBody{
  max-width: 100px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
