<template>
  <footer class="footer">
    <div class="container">
        <div class="copyright text-center">
          &copy; {{ new Date().getFullYear() }} <a href="https://www.samurai-net.co.jp" target="_blank">Samurai, inc</a>&nbsp;All Rights Reserved.
        </div>
    </div>
  </footer>

</template>
<script>
export default {}

</script>
<style>

</style>
