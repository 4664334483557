<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">スタンプ基本設定</h4>
        </md-card-header>
        <md-card-content>
          <form data-vv-scope="baseSetting">
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              満了スタンプ数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('baseSetting.limitStamp')},
                {'md-valid': !errors.has('baseSetting.limitStamp') && touched.limitStamp}]">
                <md-input
                  v-model="limitStamp"
                  data-vv-name="limitStamp"
                  v-validate= "modelValidations.limitStamp"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('baseSetting.limitStamp')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('baseSetting.limitStamp')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('baseSetting.limitStamp') && touched.limitStamp" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              有効期限
            </label>
            <div class="md-layout-item">
              <md-radio v-model="expireType" value="one_year">1年間</md-radio>
              <md-radio v-model="expireType" value="two_year">2年間</md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              スタンプ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!image">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="image" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="image" data-id="image"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!image">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="image">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">スタンプ特典クーポン</h4>
        </md-card-header>
        <md-card-content>
          <form data-vv-scope="couponSetting">
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              クーポン名
            </label>
            <div class="md-layout-item md-size-40  md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('couponSetting.stampCouponName')},
                {'md-valid': !errors.has('couponSetting.stampCouponName') && touched.stampCouponName}]">
                <md-select
                  v-model="stampCouponName"
                  data-vv-name="stampCouponName"
                  v-validate= "modelValidations.stampCouponName">
                  <md-option v-for="item in listData" :value="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('couponSetting.stampCouponName')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('couponSetting.stampCouponName')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('couponSetting.stampCouponName') && touched.stampCouponName" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特典提供スタンプ数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('couponSetting.number')},
                {'md-valid': !errors.has('couponSetting.number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  v-validate= "modelValidations.number"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('couponSetting.number')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('couponSetting.number')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('couponSetting.number') && touched.number" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          </form>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg" :onclick="stampCouponAcquisition" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">スタンプ特典クーポン一覧</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="tableData" :md-sort.sync="currentSortOrder" class="paginated-table table-shopping table-hover">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="クーポン名">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="スタンプ数">{{ item.number }}</md-table-cell>
              <md-table-cell md-label="アクション">
                <md-button
                  class="md-just-icon md-danger mg0"
                  @click.native="handleDelete(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import router from '../packs/router.js'
  import qs from 'qs'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        expireType: '',
        limitStamp: '',
        image: '',
        stampCouponName:'',
        number: '',
        listData: [],
        tableData: [],
        currentSortOrder: 'asc',
        touched: {
          expireType: false,
          limitStamp: false,
          image: false,
          stampCouponName: false,
          number: false
        },
        modelValidations: {
          limitStamp: {
            required: true,
            numeric: true,
            max: 2
          },
          stampCouponName: {
            required: true
          },
          number: {
            required: true,
            numeric: true,
            max: 2
          }
        }
      }
    },
    methods: {
      getStampSettings () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/stamp_settings/' +  this.$Cookies.get('companyId') ,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.limit_stamp !== 0 && response.data.limit_stamp !== null) {
            this.limitStamp = response.data.limit_stamp
          }
          this.expireType = response.data.expire_type
          this.image = response.data.image_url
          this.setList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプ特典クーポン情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll('baseSetting').then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateStampSettings()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateStampSettings(){
        var content = {
          stamp_settings: {
            'expire_type': this.expireType,
            'limit_stamp': this.limitStamp
          }
        }
        // 画像がURLかどうか判別して追加
        if(this.image === '' || this.image === null){
          content.stamp_settings.image = ''
        } else if (this.image.indexOf('/uploads/') == -1) {
          content.stamp_settings.image = this.image
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/stamp_settings/' + this.$Cookies.get('companyId'),
          timeout : 30000,
          data: qs.stringify(content),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スタンプ設定を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプ設定情報を正しく登録できませんでした')
          }
        })
      },
      // クーポン選択リスト
      getStampCoupons() {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/stamp_coupons/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプクーポンリストを取得できませんでした')
          }
        })
      },
      createList (obj) {
        this.listData.splice(0)
        this.listData.push({
          id: '選択しない',
          name: '選択しない'
        })
        for (var i in obj) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.listData.push(item)
        }
      },
      stampCouponAcquisition() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('stampCouponAcquisitionf', this.errors)
            this.$validator.validateAll('couponSetting').then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateStampCouponAcquisition()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateStampCouponAcquisition(){
        var content = {
          stamp_coupon_acquisitions: {
          'number': this.number,
          'coupon_id': this.stampCouponName
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/stamp_coupon_acquisitions/' + this.$Cookies.get('companyId') ,
          timeout : 30000,
          data: content,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          var item = {
            id: response.data.id,
            name: response.data.name,
            number: response.data.number
          }
          this.tableData.push(item)
          this.successAndBackPage(`スタンプ特典クーポンを登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプ特典クーポン情報を正しく登録できませんでした')
          }
        })
      },
      resetData () {
        this.stampCouponName = ''
        this.number =  ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
      // 一覧
      setList (obj) {
        var res = obj.stamp_coupon_acquisitions
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].name,
            number: res[i].number
          }
          this.tableData.push(item)
        }
      },
      handleDelete (item) {
        swal({
          title: 'このスタンプ特典クーポンを<br>削除してもよろしいですか?',
          text: `削除してしまうと、元に戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/stamp_coupon_acquisitions/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item);
          this.successAndBackPage(`スタンプ特典クーポンを削除しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプ特典クーポンを削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      }
    },
    mounted () {
      if (this.shopFlag || !this.opt_stamp) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getStampSettings()
      this.getStampCoupons()
    },
    watch: {
      '$route.path'(value){
        this.resetData()
        this.getStampCoupons()
        this.getStampSettings()
      },
      expireType(){
        this.touched.expireType = true
      },
      limitStamp(){
        this.touched.limitStamp = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border-style: none;
}
.pt20{
  padding-top: 20px;
}
.md-input {
  width: 40px!important;
}
.md-table-cell-container {
  padding-right: 50px!important;
}
.warning{
  margin-right: 50px!important;
}
.paginated-table .md-btn-fab, .paginated-table .md-just-icon {
  margin: 5px 10px!important;
}
.image-container{
  max-width: none !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }
  .is-xs-none{
    display: none;
  }
  .is-xs-block{
    display: block;
  }
}
</style>

