<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">アプリメイン画像設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">iOS用</div>
              <div class="file-input imageWidth">
                <div v-if="!imageMainIos">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageMainIos" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageMainIos" data-id="imageMainIos"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageMainIos">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageMainIos">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは1500px × 900pxです</li>
                <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
                <li>対応ファイル：jpg,png</li>
              </ul>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">Android用</div>
              <div class="file-input imageWidth">
                <div v-if="!imageMainAnd">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageMainAnd" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageMainAnd" data-id="imageMainAnd"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageMainAnd">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageMainAnd">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは1500px × 900pxです</li>
                <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
                <li>対応ファイル：jpg,png</li>
              </ul>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="uploadImage('main')">設定</md-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">スプラッシュ画像設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">iOS用</div>
              <div class="file-input imageWidth">
                <div v-if="!imageSplashIos">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageSplashIos" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageSplashIos" data-id="imageSplashIos"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageSplashIos">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageSplashIos">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは2732px × 2732pxです</li>
                <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
                <li>対応ファイル：jpg,png</li>
              </ul>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">Android用</div>
              <div class="file-input imageWidth">
                <div v-if="!imageSplashAnd">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageSplashAnd" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageSplashAnd" data-id="imageSplashAnd"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageSplashAnd">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageSplashAnd">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは950px × 950pxです</li>
                <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
                <li>Android用のスプラッシュ画像は、自動的に画面サイズの最適化が行われる9-patch形式のPNG画像ファイルが推奨されます。</li>
              </ul>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="uploadImage('splash')">設定</md-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">アプリアイコン画像設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">iOS用</div>
              <div class="file-input imageWidth w200">
                <div v-if="!imageIconIos">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageIconIos" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageIconIos" data-id="imageIconIos"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageIconIos">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageIconIos">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは180px × 180pxです</li>
                <li>対応ファイル：png</li>
              </ul>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div class="labels">Android用</div>
              <div class="file-input imageWidth w200">
                <div v-if="!imageIconAnd">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageIconAnd" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageIconAnd" data-id="imageIconAnd"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageIconAnd">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileSingleChange" data-id="imageIconAnd">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>推奨サイズは192px × 192pxです</li>
                <li>対応ファイル：png</li>
              </ul>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="uploadImage('logo')">設定</md-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">ウォークスルー画像設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout-item md-size-100 pl30">
            <div v-show="buttonFlag" class="BtnAdd" @click="addImage"><md-icon>add</md-icon><span>ウォークスルー枠を追加する</span></div>
            <div v-show="imgList.length" class="BtnRemove" @click="removeImage"><md-icon>remove</md-icon><span>ウォークスルー枠を削除する</span></div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <div class="md-layout-item md-xsmall-size-100">
              <div class="md-layout-item md-size-walk mx-auto file-input imageWalk mb30" v-for="(item,index) in imgList" :key="index">
                <div class="walkLabel">{{index + 1}}枚目</div>
                <div v-if="!item.url">
                  <div class="image-container">
                    <img :src="dummyWalk" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="item.url" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeMultiImage" v-if="item.url" :data-id="index"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!item.url">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @input="onFileMultiChange" :data-id="index">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <ul class="imgAttention md-layout-item md-size-100 pl30">
            <li>推奨サイズは1125px × 2000pxです</li>
            <li>ウォークスルー画像は最大６枚まで登録できます</li>
            <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
            <li>サンプル画像の点線部分にはボタン等が生成されるため、該当箇所の情報が読めなくなる場合があります</li>
            <li>対応ファイル：jpg,png</li>
          </ul>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="uploadImage('walk')">登録</md-button>
        </md-card-actions>
      </md-card>

      <md-card v-show="!basicLock">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">画像一括ダウンロード</h4>
        </md-card-header>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="downloadImage">ダウンロード</md-button>
        </md-card-actions>
      </md-card>

      <div class="linkCont">
        <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
        <div class="bottomLink" @click="goDelivery">配信情報を編集する</div>
        <div class="bottomLink" @click="goService">サービス情報を編集する</div>
      </div>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      },
      dummyWalk: {
        type: String,
        default: '/images/image_placeholder_walk.jpg'
      }
    },
    data () {
      return {
        imageMainIos: '',
        imageMainAnd: '',
        imageSplashIos: '',
        imageSplashAnd: '',
        imageIconIos: '',
        imageIconAnd: '',
        imgList: [],
        name: '',
        imageWalk1: '',
        imageWalk2: '',
        imageWalk3: '',
        imageWalk4: '',
        imageWalk5: '',
        imageWalk6: '',
        buttonFlag: true,
        basicLock: false
      }
    },
    methods: {
      returnBack () {
        if (this.superFlag) {
          router.push({ path: '/admin/company_list/' })
        } else {
          router.push({ path: '/admin/company/service/edit/' + this.$route.params.id })
        }
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateService()
          } else {
            this.validateError()
          }
        })
      },
      goSetting () {
        router.push({ path: '/admin/company/edit/' + this.$route.params.id })
      },
      goDelivery () {
        router.push({ path: '/admin/company/delivery/edit/' + this.$route.params.id })
      },
      goService () {
        router.push({ path: '/admin/company/service/edit/' + this.$route.params.id })
      },
      onFileSingleChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createSingleImage(files[0],ids);
      },
      createSingleImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeSingleImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      onFileMultiChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createMultiImage(files[0],ids);
      },
      createMultiImage(file,fileId) {
        let reader = new FileReader()
        let $this = this

        reader.onload = (e) => {
          $this.imgList[fileId].url = e.target.result
        };
        reader.readAsDataURL(file)
      },
      removeMultiImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id')
        this.imgList[ids].url = ''
      },
      addImage () {
        var image = {
          url: ''
        }
        this.imgList.push(image)
        this.checkImgLength()

      },
      removeImage () {
        this.imgList.pop()
        this.checkImgLength()
      },
      checkImgLength () {
        if (this.imgList.length >= 6) {
          this.buttonFlag = false
        } else {
          this.buttonFlag = true
        }
      },
      getService () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.name = response.data.name
          this.imageMainIos = response.data.app_main_ios_url
          this.imageMainAnd = response.data.app_main_android_url
          this.imageSplashIos = response.data.splash_ios_url
          this.imageSplashAnd = response.data.splash_android_url
          this.imageIconIos = response.data.app_logo_ios_url
          this.imageIconAnd = response.data.app_logo_android_url
          if (response.data.walk_through1_url !== '' && response.data.walk_through1_url !== null) {
            this.imgList.push({url:response.data.walk_through1_url})
          }
          if (response.data.walk_through2_url !== '' && response.data.walk_through2_url !== null) {
            this.imgList.push({url:response.data.walk_through2_url})
          }
          if (response.data.walk_through3_url !== '' && response.data.walk_through3_url !== null) {
            this.imgList.push({url:response.data.walk_through3_url})
          }
          if (response.data.walk_through4_url !== '' && response.data.walk_through4_url !== null) {
            this.imgList.push({url:response.data.walk_through4_url})
          }
          if (response.data.walk_through5_url !== '' && response.data.walk_through5_url !== null) {
            this.imgList.push({url:response.data.walk_through5_url})
          }
          if (response.data.walk_through6_url !== '' && response.data.walk_through6_url !== null) {
            this.imgList.push({url:response.data.walk_through6_url})
          }

          this.checkImgLength()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('サービス情報を正しく取得できませんでした')
          }
        })
      },
      uploadImage(type){
        var condition = {
          service_setting: {}
        }
        var typeText = ''

        switch (type) {
          case 'main':
            typeText = 'アプリメイン'
            if(this.imageMainIos === '' || this.imageMainIos === null){
              condition.service_setting.app_main_ios = ''
            } else if (this.imageMainIos.indexOf('/uploads/') == -1) {
              condition.service_setting.app_main_ios = this.imageMainIos
            }

            if(this.imageMainAnd === '' || this.imageMainAnd === null){
              condition.service_setting.app_main_android = ''
            } else if (this.imageMainAnd.indexOf('/uploads/') == -1) {
              condition.service_setting.app_main_android = this.imageMainAnd
            }

            break;

          case 'splash':
            typeText = 'スプラッシュ'
            if(this.imageSplashIos === '' || this.imageSplashIos === null){
              condition.service_setting.splash_ios = ''
            } else if (this.imageSplashIos.indexOf('/uploads/') == -1) {                
              condition.service_setting.splash_ios = this.imageSplashIos
            }

            if(this.imageSplashAnd === '' || this.imageSplashAnd === null){
              condition.service_setting.splash_android = ''
            } else if (this.imageSplashAnd.indexOf('/uploads/') == -1) {
              condition.service_setting.splash_android = this.imageSplashAnd
            }

            break;

          case 'logo':
            typeText = 'アプリアイコン'
            if(this.imageIconIos === '' || this.imageIconIos === null){
              condition.service_setting.app_logo_ios = ''
            } else if (this.imageIconIos.indexOf('/uploads/') == -1) {
              condition.service_setting.app_logo_ios = this.imageIconIos
            }

            if(this.imageIconAnd === '' || this.imageIconAnd === null){
              condition.service_setting.app_logo_android = ''
            } else if (this.imageIconAnd.indexOf('/uploads/') == -1) {
              condition.service_setting.app_logo_android = this.imageIconAnd
            }

            break;

          case 'walk':
            typeText = 'ウォークスルー'

            for (var i = 0; i < this.imgList.length; i++) {
              if(this.imgList[i].url === '' || this.imgList[i].url === null){
                condition.service_setting['walk_through' + (i + 1)] = ''
              } else if (this.imgList[i].url.indexOf('/uploads/') == -1) {
                condition.service_setting['walk_through' + (i + 1)] = this.imgList[i].url
              }
            }
            break;
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(typeText+`画像を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(typeText+'画像を正しく登録できませんでした')
          }
        })
      },
      downloadImage(){
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id + '/download',
          timeout : 30000,
          responseType: 'arraybuffer',
          headers : {'Authorization': this.$Cookies.get('accessToken'), 'Accept': 'application/zip'}
        }).then(response => {
          var blob = new Blob([response.data], { "type" : "application/x-zip-compressed" })
          var uri = URL.createObjectURL(blob)
          var link = document.createElement('a')
          link.download = this.name + '.zip'
          link.href = uri
          link.click()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('画像をダウンロードできませんでした')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.companyFlag) {
        this.basicLock = true
        var pt = this.$route.path
        pt = pt.split('/edit/')
        if (pt[1] !== this.$Cookies.get('companyId')) {
          router.push({ path: '/admin/login'})
          return false
        }
      } else if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getService()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.labels{
  color: #666666;
  font-weight: 400;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  padding-bottom: 10px;
}

.imageWidth{
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto
}

.imageWalk{
  width: 100%;
  margin: 0 auto
}

.image-container{
  max-width: none;
}

.pt15{
  padding-top: 15px;
}

.pt20{
  padding-top: 20px;
}

.mb30{
  margin-bottom: 20px;
}

.pl30{
  padding-left: 30px;
}

.is-l-none{
  display: none;
}

.BtnAdd,
.BtnRemove{
  color: #666666;
  padding: 3px 0;
  line-height: 24px;
  cursor: pointer;
}

.BtnAdd{
  margin-top: 15px;
}

.BtnRemove{
  margin-bottom: 30px;
}

.BtnAdd span,
.BtnRemove span{
  display: inline-block;
}

.BtnAdd i,
.BtnRemove i{
  margin-bottom: 3px;
}

.md-layout-item.md-size-walk{
  min-width: 33%;
  max-width: 33%;
  flex: 0 1 33%;
}

.w200{
  width: 200px;
}

.imgAttention{
  font-size: 12px;
  color: #333333;
  padding-left: 10px;
  list-style: none;
}

.imgAttention li{
  padding-left: 10px;
  position: relative;
  line-height: 18px;
}

.imgAttention li:before{
  content: '*';
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .md-layout-item.md-size-walk{
    min-width: 100%;
    max-width: 100%;
    flex: 0 1 100%;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .imageWidth{
    width: auto;
  }

  .imageWalk{
    width: auto
  }

  .image-container{
    margin: 0 auto 10px;
  }
}

</style>
