<template>
  <md-field class="userRank">
    <md-select
      v-model="rank"
      @input="updateValue">
      <md-option v-for="item in listData" :value="item.id">
        {{item.name}}
      </md-option>
    </md-select>
  </md-field>
</template>

<script>
  export default {
    data() {
      return {
        listData: []
      }
    },
    props: ['rank'],
    methods: {
      getUserRanks () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/user_ranks/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          this.dangerNotify('会員ランク情報を正しく取得できませんでした')
        })
      },
      createList (obj) {
        this.listData.splice(0)
        this.listData.push({
          id: '選択しない',
          name: '選択しない'
        })
        for (var i in obj) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.listData.push(item)
        }
      },
      updateValue(){
        this.$emit('input', this.rank)
      }
    },
    mounted () {
      this.getUserRanks()
    }
  }
</script>