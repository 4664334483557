import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const searchParams = {
  namespaced: true,
  state: {
    condition: null,
  },
  getters: {
    condition(state){
      return state.condition
    }
  },
  mutations: {
    searchParams(state, data){
      state.condition = data
      console.log(state.condition)
    }
  },
  actions: {
    searchParamsAsync ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          commit('searchParams', payload)
          resolve()
        }, 1000)
      })
    }
  }
}

const store = new Vuex.Store({
  modules: {
    shopOrderList: searchParams
  }
})

export default store