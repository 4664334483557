

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">クーポンコード基本情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              クーポンコード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              クーポン名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('title')},
                {'md-valid': !errors.has('title') && touched.title}]">
                <md-input
                  v-model="title"
                  data-vv-name="title"
                  type="text"
                  v-validate= "modelValidations.title"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('title')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('title')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('title') && touched.title">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              クーポン内容
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('couponDetail')},
                {'md-valid': !errors.has('couponDetail') && touched.couponDetail}]">
                <md-textarea
                  class="txtArea"
                  id="txtArea"
                  v-model="couponDetail"
                  data-vv-name="couponDetail"
                  type="text"
                  v-validate= "modelValidations.couponDetail"
                  md-counter="140"
                  maxlength="140"
                  required>
                </md-textarea>
                <slide-y-up-transition>
                  <div class="vErrorText errorMsg" v-show="errors.has('couponDetail')">【必須】140文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('couponDetail')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('couponDetail') && touched.couponDetail">done</md-icon>
                </slide-y-down-transition>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                  <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              特典種別
            </label>
            <div class="md-layout-item">
              <md-radio v-model="kind" value="fixed_amount">値引</md-radio>
              <md-radio v-model="kind" value="fixed_rate">割引</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_amount'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              値引
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('kindFixedAmount')},
                {'md-valid': !errors.has('kindFixedAmount') && touched.kindFixedAmount}]">
                <md-input
                  v-model="kindFixedAmount"
                  data-vv-name="kindFixedAmount"
                  v-validate= "kind === 'fixed_amount' ? modelValidations.kindFixedAmount : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kindFixedAmount')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kindFixedAmount')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kindFixedAmount') && touched.kindFixedAmount" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_rate'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              割引率
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('kindFixedRate')},
                {'md-valid': !errors.has('kindFixedRate') && touched.kindFixedRate}]">
                <md-input
                  v-model="kindFixedRate"
                  data-vv-name="kindFixedRate"
                  v-validate= "kind === 'fixed_rate' ? modelValidations.kindFixedRate : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kindFixedRate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kindFixedRate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kindFixedRate') && touched.kindFixedRate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">％</div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_rate'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              税種別
            </label>
            <div class="md-layout-item">
              <md-radio v-model="taxKind" value="not_tax_include">税抜</md-radio>
              <md-radio v-model="taxKind" value="tax_include">税込</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              併用利用
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('isJointlyUse')},
                {'md-valid': !errors.has('isJointlyUse') && touched.isJointlyUse}]">
                <md-select
                  v-model="isJointlyUse"
                  data-vv-name="isJointlyUse"
                  v-validate= "modelValidations.isJointlyUse"
                  required>
                  <md-option value="jointly_use">有効</md-option>
                  <md-option value="not_jointly_use">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('isJointlyUse')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('isJointlyUse')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('isJointlyUse') && touched.isJointlyUse" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              再発行
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('reAcquire')},
                {'md-valid': !errors.has('reAcquire') && touched.reAcquire}]">
                <md-select
                  v-model="reAcquire"
                  data-vv-name="reAcquire"
                  v-validate= "modelValidations.reAcquire"
                  required>
                  <md-option value="re_acquire">可能</md-option>
                  <md-option value="not_re_acquire">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('reAcquire')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('reAcquire')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('reAcquire') && touched.reAcquire" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              利用可能金額制限
            </label>
            <div class="md-layout-item">
              <md-radio v-model="availableAmountLimit" value="amount_limit">有</md-radio>
              <md-radio v-model="availableAmountLimit" value="not_amount_limit">無</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="availableAmountLimit==='amount_limit'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              制限金額
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('availableAmount')},
                {'md-valid': !errors.has('availableAmount') && touched.availableAmount}]">
                <md-input
                  v-model="availableAmount"
                  data-vv-name="availableAmount"
                  v-validate= "availableAmountLimit === 'amount_limit' ? modelValidations.availableAmount : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('availableAmount')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('availableAmount')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('availableAmount') && touched.availableAmount" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円以上</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              配布制限
            </label>
            <div class="md-layout-item">
              <md-radio v-model="distributionLimit" value="distribution_limit">枚数指定</md-radio>
              <md-radio v-model="distributionLimit" value="not_distribution_limit">無制限</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="distributionLimit==='distribution_limit'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              配布枚数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('distributionQuantity')},
                {'md-valid': !errors.has('distributionQuantity') && touched.distributionQuantity}]">
                <md-input
                  v-model="distributionQuantity"
                  data-vv-name="distributionQuantity"
                  v-validate= "distributionLimit === 'distribution_limit' ? modelValidations.distributionQuantity : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('distributionQuantity')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('distributionQuantity')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('distributionQuantity') && touched.distributionQuantity" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">枚</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
              利用可能範囲
            </label>
            <div class="md-layout-item">
              <md-radio v-model="availableType" value="available_all">全体</md-radio>
              <md-radio v-model="availableType" value="available_brand_id">ブランド</md-radio>
              <md-radio v-model="availableType" value="available_shop_id">店舗</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-show="availableType!='available_all'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              対象ブランド
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <brand-select
                v-model="brandId"
                :avoidValidFlag = "availableType === 'available_all'"
                :touchedFlag="true">
              </brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-show="availableType==='available_shop_id'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" v-show="!this.shopFlag">
              対象店舗
            </label>
            <div class="md-layout-item">
              <shop-select 
                ref= "shop"
                v-model="shopId"
                :avoidValidFlag = "availableType != 'available_shop_id'"
                :touchedFlag="true"
                :mutlipleFlag="true">
              </shop-select>
            </div>
          </div>
        </md-card-content>
        
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">クーポン期間設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開開始日
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開終了日
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              有効期限
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="useLimitStart"
                @input="checkEmpty"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="startDateDelete">
                <md-icon>close</md-icon>
              </md-button>
              <div class="vErrorText" style="margin-left: 15px" v-show="validateUseLimitStart">設定する場合は必ず両方の日付を入力してください</div>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none ptb"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="useLimitEnd"
                @input="checkEmpty"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="endDateDelete">
                <md-icon>close</md-icon>
              </md-button>
              <div class="vErrorText" style="margin-left: 15px" v-show="validateUseLimitEnd">設定する場合は必ず両方の日付を入力してください</div>
            </div>
          </div>

        </md-card-content>
      </md-card>
    </div>


    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">クーポン画像設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageCoupon">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageCoupon" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageCoupon" data-id="imageCoupon"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageCoupon">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageCoupon">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import qs from 'qs'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import BrandSelect from '../components/additional/BrandSelect.vue'

  export default {
    mixins: [ role ],
    components: {
      ShopSelect,
      SubmitButton,
      BrandSelect,
      TimeSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        number: null,
        title: '',
        code: '',
        shopId: [],
        brandId: '',
        imageCoupon: '',
        couponDetail: '',
        selectedStart: '',
        selectedEnd: '',
        useLimitStart: '',
        useLimitEnd: '',
        startTime: '',
        endTime: '',
        status: '',
        kind: 'fixed_amount',
        kindFixedAmount: '',
        kindFixedRate: '',
        taxKind: 'not_tax_include',
        isJointlyUse: '',
        reAcquire: '',
        availableAmountLimit: 'not_amount_limit',
        availableAmount: '',
        distributionLimit: 'not_distribution_limit',
        distributionQuantity: '',
        availableType: 'available_all',
        newFlag: false,
        shopInitFlag: false,
        listData: [],
        touched: {
          number: false,
          title: false,
          code: false,
          status: false,
          selectedStart: false,
          selectedEnd: false,
          useLimitStart: false,
          useLimitEnd: false,
          kindFixedAmount: '',
          kindFixedRate: '',
          availableAmount: '',
          isJointlyUse: false,
          reAcquire: false,
          distributionQuantity: '',
          couponDetail: false
        },
        validateUseLimitStart: false,
        validateUseLimitEnd: false,
        modelValidations: {
          number: {
            required: true,
            numeric: true,
            max: 3
          },
          title: {
            required: true,
            max: 255
          },
          status: {
            required: true
          },
          kindFixedAmount: {
            required: true,
            max: 6
          },
          kindFixedRate: {
            required: true,
            max: 2
          },
          isJointlyUse: {
            required: true
          },
          reAcquire: {
            required: true
          },
          availableAmount: {
            required: true,
            max: 6
          },
          distributionQuantity: {
            required: true,
            max: 6
          },
          code: {
            required: true,
            max: 255
          },
          couponDetail: {
            required: true,
            max: 140
          },
          shopId: {
            required: true
          },
          brandId: {
            required: true
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          },
          useLimitStart: {
            required: true
          },
          useLimitEnd: {
            required: true
          }
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/coupon_list/' })
      },
      getCoupons () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/coupons/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.number = response.data.position
          this.code = response.data.code
          this.title = response.data.name
          this.couponDetail = response.data.description
          this.imageCoupon = response.data.image_url
          response.data.shop_ids === null ? this.shopId= []  :  this.shopId = response.data.shop_ids
          response.data.brand_id === null ? this.brandId='' :  this.brandId = response.data.brand_id
          this.status = response.data.status
          this.kind = response.data.kind
          this.kindFixedAmount = response.data.kind_fixed_amount
          this.kindFixedRate = response.data.kind_fixed_rate
          this.taxKind = response.data.tax_kind
          this.isJointlyUse = response.data.is_jointly_use
          this.reAcquire = response.data.re_acquire
          this.availableAmountLimit = response.data.available_amount_limit
          this.availableAmount = response.data.available_amount
          this.distributionLimit = response.data.distribution_limit
          this.distributionQuantity = response.data.distribution_quantity
          this.availableType = response.data.available_type
          if (response.data.public_from !== null) {
            this.selectedStart = this.dateDivider(response.data.public_from)[0]
            this.startTime = this.dateDivider(response.data.public_from)[1]
            this.$refs.startTimer.unlock()
          }
          if (response.data.public_to !== null) {
            this.selectedEnd = this.dateDivider(response.data.public_to)[0]
            this.endTime = this.dateDivider(response.data.public_to)[1]
            this.$refs.endTimer.unlock()
          }
          if (response.data.use_limit_start !== null) {
            this.useLimitStart = this.dateDivider(response.data.use_limit_start)[0]
          }
          if (response.data.use_limit_end !== null) {
            this.useLimitEnd = this.dateDivider(response.data.use_limit_end)[0]
          }
          
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('クーポン情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateInformation()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateInformation(){
        var condition = {
          coupon: {
            position: this.number,
            code: this.code,
            name: this.title,
            description: this.couponDetail,
            status:this.status,
            kind: this.kind,
            kind_fixed_amount: this.kind === "fixed_amount" ? this.kindFixedAmount : null,
            kind_fixed_rate: this.kind === "fixed_rate" ? this.kindFixedRate : null,
            tax_kind: this.taxKind,
            is_jointly_use:this.isJointlyUse,
            re_acquire:this.reAcquire,
            available_amount_limit: this.availableAmountLimit,
            available_amount: this.availableAmount,
            distribution_limit: this.distributionLimit,
            distribution_quantity: this.distributionQuantity,
            available_type: this.availableType,
            public_from: this.initDate(this.selectedStart) + ' ' + this.startTime,
            public_to: this.initDate(this.selectedEnd) + ' ' + this.endTime,
            use_limit_start: this.useLimitStart,
            use_limit_end: this.useLimitEnd,
            shop_ids: this.shopId === '' ? '' : this.shopId.join(','),
            brand_id: this.brandId === '' ? '' : this.brandId
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageCoupon === '' || this.imageCoupon === null){
          condition.coupon.image = ''
        } else if (this.imageCoupon.indexOf('/uploads/') == -1) {
          condition.coupon.image = this.imageCoupon
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/coupons/' : this.AdminApiUrl + '/coupons/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`クーポンを`+this.buttonWord+`しました`,'/admin/coupon_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`クーポンを正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      resetData () {
        this.number = ''
        this.status = 'not_active'
        this.kind = ''
        this.kindFixedAmount = ''
        this.kindFixedRate = ''
        this.kindFixedRateTax = ''
        this.isJointlyUse = 'not_active'
        this.reAcquire = 'not_active'
        this.availableAmountLimit = ''
        this.availableAmount = ''
        this.distributionLimit = ''
        this.distributionQuantity = ''
        this.code = ''
        this.title = ''
        this.imageCoupon = ''
        this.availableType = ''
        this.shopId = []
        this.brandId = ''
        this.couponDetail = ''
        this.selectedStart = null
        this.startTime = '00:00'
        this.$refs.startTimer.lock()
        this.selectedEnd = null
        this.endTime = '00:00'
        this.$refs.endTimer.lock()
        this.useLimitStart = null
        this.useLimitEnd = null
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.couponDetail
        this.couponDetail = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      },
      checkEmpty () {
        if ((this.useLimitStart == '' && this.useLimitEnd == '') || (this.useLimitStart != '' && this.useLimitEnd != '')) {
          this.validateUseLimitStart = false
          this.validateUseLimitEnd = false
        } else if (this.useLimitStart != '' && this.useLimitEnd == ''){
          this.validateUseLimitStart = false
          this.validateUseLimitEnd = true
        } else if (this.useLimitStart == '' && this.useLimitEnd != ''){
          this.validateUseLimitStart = true
          this.validateUseLimitEnd = false
        } else {
          this.validateUseLimitStart = true
          this.validateUseLimitEnd = true
        }
      },
      startDateDelete(){
        this.useLimitStart = ''
        this.checkEmpty()
      },
      endDateDelete(){
        this.useLimitEnd = ''
        this.checkEmpty()
      },
    },
    mounted () {
      // role check
      if (this.shopFlag || !this.opt_coupon) {
        router.push({ path: '/admin/login'})
        return false
      }
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getCoupons()
      }
      if (this.companyFlag) {
        this.companyId = this.$Cookies.get('companyId')
      } else if (this.shopFlag) {
        this.shopId = this.$Cookies.get('shopId')
      }
      //Icon
      this.icons = this.TextIcons
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect') 
      sessionStorage.removeItem('shopSelect') 
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getCoupons()
        }
      },
      brandId () {
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
        if (this.newFlag) {
          this.shopId = []
        } else {
          this.shopInitFlag ? this.shopId = [] : this.shopInitFlag = true
        }
      },
      number () {
        this.touched.number = true
      },
      title(){
        this.touched.title = true
      },
      code(){
        this.touched.code = true
      },
      status(){
        this.touched.status = true
      },
      kindFixedAmount(){
        this.touched.kindFixedAmount = true
      },
      kindFixedRate(){
        this.touched.kindFixedRate = true
      },
      availableAmount(){
        this.touched.availableAmount = true
      },
      isJointlyUse(){
        this.touched.isJointlyUse = true
      },
      reAcquire(){
        this.touched.reAcquire = true
      },
      distributionQuantity(){
        this.touched.distributionQuantity = true
      },
      couponDetail(){
        this.touched.couponDetail = true
      },
      selectedStart () {
        this.$refs.startTimer.unlock()
      },
      selectedEnd () {
        this.$refs.endTimer.unlock()
      },
      useLimitStart () {
        this.$refs.startTimer.unlock()
      },
      useLimitEnd () {
        this.$refs.endTimer.unlock()
      }

    }
  }
</script>

<style lang="css" scoped>
.ptb{
  padding-top: 15px !important;
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

.iconWrapper{
  position: relative;
  bottom: -8px !important;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

.errorMsg{
  bottom: -15px !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>

