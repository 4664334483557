import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LoginRemind from '../views/LoginRemind.vue'
import LoginRemindPassword from '../views/LoginRemindPassword.vue'
import DashboardLayout from '../views/layouts/DashboardLayout.vue'
import Top from '../views/Top.vue'
import PreProductList from '../views/PreProductList.vue'
import PreProduct from '../views/PreProduct.vue'
import ReserveProductList from '../views/ReserveProductList.vue'
import ReserveProduct from '../views/ReserveProduct.vue'
import ProductCategoryList from '../views/ProductCategoryList.vue'
import HouseMoneyList from '../views/HouseMoneyList.vue'
import HouseMoney from '../views/HouseMoney.vue'
import HouseMoneyPayment from '../views/HouseMoneyPayment.vue'
import HouseMoneyCharge from '../views/HouseMoneyCharge.vue'
import UserList from '../views/UserList.vue'
import User from '../views/User.vue'
import PreOrderList from '../views/PreOrderList.vue'
import PreOrder from '../views/PreOrder.vue'
import ShopOrderList from '../views/ShopOrderList.vue'
import ShopOrder from '../views/ShopOrder.vue'
import SmartOrderList from '../views/SmartOrderList.vue'
import SmartOrder from '../views/SmartOrder.vue'
import QrIssue from '../views/QrIssue.vue'
import ReserveOrderList from '../views/ReserveOrderList.vue'
import ReserveOrder from '../views/ReserveOrder.vue'
import BrandList from '../views/BrandList.vue'
import Brand from '../views/Brand.vue'
import JobCategoryList from '../views/JobCategoryList.vue'
import AreaList from '../views/AreaList.vue'
import PaymentTerminalList from '../views/PaymentTerminalList.vue'
import PaymentTerminal from '../views/PaymentTerminal.vue'
import PaymentList from '../views/PaymentList.vue'
import Payment from '../views/Payment.vue'
import CompanyList from '../views/CompanyList.vue'
import Company from '../views/Company.vue'
import CompanyService from '../views/CompanyService.vue'
import CompanyDelivery from '../views/CompanyDelivery.vue'
import CompanyApplication from '../views/CompanyApplication.vue'
import CouponList from '../views/CouponList.vue'
import Coupon from '../views/Coupon.vue'
import PointCardList from '../views/PointCardList.vue'
import PointCard from '../views/PointCard.vue'
import CsvImport from '../views/CsvImport.vue'
import CsvExport from '../views/CsvExport.vue'
import RequireVersion from '../views/RequireVersion.vue'
import TelegramHistoryList from '../views/TelegramHistoryList.vue'
import TelegramHistory from '../views/TelegramHistory.vue'
import StampCouponList from '../views/StampCouponList.vue'
import StampCoupon from '../views/StampCoupon.vue'
import PointSetting from '../views/PointSetting.vue'
import StampSetting from '../views/StampSetting.vue'
import Notification from '../views/Notification.vue'
import NotificationList from '../views/NotificationList.vue'
import ReserveNotification from '../views/ReserveNotification.vue'
import MailMagazine from '../views/MailMagazine.vue'
import MailMagazineList from '../views/MailMagazineList.vue'
import ReserveMailMagazine from '../views/ReserveMailMagazine.vue'
import InformationList from '../views/InformationList.vue'
import Information from '../views/Information.vue'
import ShopTerminalList from '../views/ShopTerminalList.vue'
import ShopTerminal from '../views/ShopTerminal.vue'
import ShopList from '../views/ShopList.vue'
import Shop from '../views/Shop.vue'
import ShopCardList from '../views/ShopCardList.vue'
import ShopCard from '../views/ShopCard.vue'
import StaffList from '../views/StaffList.vue'
import Staff from '../views/Staff.vue'
import AnalysisProduct from '../views/AnalysisProduct.vue'
import AnalysisPeriod from '../views/AnalysisPeriod.vue'
import AnalysisUser from '../views/AnalysisUser.vue'
import Calendar from '../views/Calendar.vue'
import AdminUserList from '../views/AdminUserList.vue'
import AdminUser from '../views/AdminUser.vue'
import AccessHistory from '../views/AccessHistory.vue'
import UserRankList from '../views/UserRankList.vue'
import UserRank from '../views/UserRank.vue'
import QaCategoryList from '../views/QaCategoryList.vue'
import QaCategory from '../views/QaCategory.vue'
import QaInformationList from '../views/QaInformationList.vue'
import QaInformation from '../views/QaInformation.vue'
import ContactCategoryList from '../views/ContactCategoryList.vue'
import ContactCategory from '../views/ContactCategory.vue'
import ContactList from '../views/ContactList.vue'
import Contact from '../views/Contact.vue'
import Cookies from 'js-cookie';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/admin/login' , component: Login },
    { path: '/admin/login_remind' , component: LoginRemind },
    { path: '/admin/login_remind_password' , component: LoginRemindPassword },
    { path: '/admin/sessions/new' , component: Login },
    {
      path: '/admin/',
      component: DashboardLayout,
      redirect: '/admin/top',
      name: 'dashbord',
      children: [
        {
          path: 'top',
          name: 'top',
          components: {default: Top}
        },
        {
          path: 'pre_product_list',
          name: 'pre_product_list',
          components: {default: PreProductList},
        },
        {
          path: 'pre_product/edit/:id',
          name: 'pre_product',
          components: {default: PreProduct}
        },
        {
          path: 'pre_product/new',
          name: 'pre_product',
          components: {default: PreProduct}
        },
        {
          path: 'reserve_product_list',
          name: 'reserve_product_list',
          components: {default: ReserveProductList},
        },
        {
          path: 'reserve_product/edit/:id',
          name: 'reserve_product',
          components: {default: ReserveProduct}
        },
        {
          path: 'reserve_product/new',
          name: 'reserve_product',
          components: {default: ReserveProduct}
        },
        {
          path: 'product_category_list',
          name: 'product_category_list',
          components: {default: ProductCategoryList}
        },
        {
          path: 'house_money_list',
          name: 'house_money_list',
          components: {default: HouseMoneyList}
        },
        {
          path: 'house_money/:type/:id',
          name: 'house_money',
          components: {default: HouseMoney}
        },
        {
          path: 'house_money_detail/charge/:id',
          name: 'house_money_detail_charge',
          components: {default: HouseMoneyCharge}
        },
        {
          path: 'house_money_detail/payment/:id',
          name: 'house_money_detail_payment',
          components: {default: HouseMoneyPayment}
        },
        {
          path: 'user_list',
          name: 'user_list',
          components: {default: UserList}
        },
        {
          path: 'user/edit/:id',
          name: 'user',
          components: {default: User}
        },
        {
          path: 'user_rank_list',
          name: 'user_rank_list',
          components: {default: UserRankList}
        },
        {
          path: 'user_rank/edit/:id',
          name: 'user_rank_edit',
          components: {default: UserRank}
        },
        {
          path: 'pre_order_list',
          name: 'pre_order_list',
          components: {default: PreOrderList}
        },
        {
          path: 'pre_order/edit/:id',
          name: 'pre_order_edit',
          components: {default: PreOrder}
        },
        {
          path: 'reserve_order_list',
          name: 'reserve_order_list',
          components: {default: ReserveOrderList}
        },
        {
          path: 'reserve_order/edit/:id',
          name: 'reserve_order',
          components: {default: ReserveOrder}
        },
        {
          path: 'shop_order_list',
          name: 'shop_order_list',
          components: {default: ShopOrderList}
        },
        {
          path: 'shop_order/edit/:id',
          name: 'shop_order',
          components: {default: ShopOrder}
        },
        {
          path: 'smart_order_list',
          name: 'smart_order_list',
          components: {default: SmartOrderList}
        },
        {
          path: 'smart_order/edit/:id',
          name: 'smart_order',
          components: {default: SmartOrder}
        },
        {
          path: 'company_list',
          name: 'company_list',
          components: {default: CompanyList}
        },
        {
          path: 'brand_list',
          name: 'brand_list',
          components: {default: BrandList}
        },
        {
          path: 'brand/edit/:id',
          name: 'brands_edit',
          components: {default: Brand}
        },
        {
          path: 'job_category_list',
          name: 'job_category_list',
          components: {default: JobCategoryList}
        },
        {
          path: 'area_list',
          name: 'area_list',
          components: {default: AreaList}
        },
        {
          path: 'payment_list',
          name: 'payment_list',
          components: {default: PaymentList}
        },
        {
          path: 'payment/edit/:id',
          name: 'payment_edit',
          components: {default: Payment}
        },
        {
          path: 'company_list',
          name: 'company_list',
          components: {default: CompanyList}
        },
        {
          path: 'company/edit/:id',
          name: 'company',
          components: {default: Company}
        },
        {
          path: 'company/new',
          name: 'company',
          components: {default: Company}
        },
        {
          path: 'company/service/edit/:id',
          name: 'company_service_edit',
          components: {default: CompanyService}
        },
        {
          path: 'company/delivery/edit/:id',
          name: 'company_delivery_edit',
          components: {default: CompanyDelivery}
        },
        {
          path: 'company/application/edit/:id',
          name: 'company_application_edit',
          components: {default: CompanyApplication}
        },
        {
          path: 'notification_list',
          name: 'notification_list',
          components: {default: NotificationList}
        },
        {
          path: 'notification/edit/:id',
          name: 'notification',
          components: {default: Notification}
        },
        {
          path: 'mail_magazine_list',
          name: 'mail_magazine_list',
          components: {default: MailMagazineList}
        },
        {
          path: 'mail_magazine/edit/:id',
          name: 'mail_magazine',
          components: {default: MailMagazine}
        },
        {
          path: 'reserve_notification/new',
          name: 'reserve_notification',
          components: {default: ReserveNotification}
        },
        {
          path: 'reserve_mail_magazine/new',
          name: 'reserve_mail_magazine',
          components: {default: ReserveMailMagazine}
        },
        {
          path: 'information_list',
          name: 'information_List',
          components: {default: InformationList}
        },
        {
          path: 'information/edit/:id',
          name: 'information',
          components: {default: Information}
        },
        {
          path: 'information/new',
          name: 'information',
          components: {default: Information}
        },
        {
          path: 'coupon_list',
          name: 'coupon_list',
          components: {default: CouponList}
        },
        {
          path: 'coupon/new',
          name: 'coupon',
          components: {default: Coupon}
        },
        {
          path: 'coupon/edit/:id',
          name: 'coupon',
          components: {default: Coupon}
        },
        {
          path: 'point_card_list',
          name: 'point_card_list',
          components: {default: PointCardList}
        },
        {
          path: 'point_card/new',
          name: 'point_card',
          components: {default: PointCard}
        },
        {
          path: 'point_card/edit/:id',
          name: 'point_card',
          components: {default: PointCard}
        },
        {
          path: 'stamp_coupon_list',
          name: 'stamp_coupon_list',
          components: {default: StampCouponList}
        },
        {
          path: 'stamp_coupon/new',
          name: 'stamp_coupon',
          components: {default: StampCoupon}
        },
        {
          path: 'stamp_coupon/edit/:id',
          name: 'stamp_coupon',
          components: {default: StampCoupon}
        },
        {
          path: 'point_setting/edit/:company_id',
          name: 'point_setting',
          components: {default: PointSetting}
        },
        {
          path: 'stamp_setting/edit/:company_id',
          name: 'stamp_setting',
          components: {default: StampSetting}
        },
        {
          path: 'shop_terminal_list',
          name: 'shop_terminal_list',
          components: {default: ShopTerminalList}
        },
        {
          path: 'shop_terminal/new',
          name: 'shop_terminal',
          components: {default: ShopTerminal}
        },
        {
          path: 'shop_terminal/edit/:id',
          name: 'shop_terminal',
          components: {default: ShopTerminal}
        },
        {
          path: 'payment_terminal_list',
          name: 'payment_terminal_list',
          components: {default: PaymentTerminalList}
        },
        {
          path: 'payment_terminal/new',
          name: 'payment_terminal',
          components: {default: PaymentTerminal}
        },
        {
          path: 'payment_terminal/edit/:id',
          name: 'payment_terminal',
          components: {default: PaymentTerminal}
        },
        {
          path: 'shop_list',
          name: 'shop_list',
          components: {default: ShopList}
        },
        {
          path: 'shop/new',
          name: 'shop',
          components: {default: Shop}
        },
        {
          path: 'shop/edit/:id',
          name: 'shop',
          components: {default: Shop}
        },
        {
          path: 'shop_card_list',
          name: 'shop_card_list',
          components: {default: ShopCardList}
        },
        {
          path: 'shop_card/new',
          name: 'shop_card',
          components: {default: ShopCard}
        },
        {
          path: 'shop_card/edit/:id',
          name: 'shop_card',
          components: {default: ShopCard}
        },
        {
          path: 'analysis_product',
          name: 'analysis_product',
          components: {default: AnalysisProduct}
        },
        {
          path: 'analysis_user',
          name: 'analysis_user',
          components: {default: AnalysisUser}
        },
        {
          path: 'analysis_period',
          name: 'analysis_period',
          components: {default: AnalysisPeriod}
        },
        {
          path: 'shop/calendar/edit/:id',
          name: 'calendar',
          components: {default: Calendar}
        },
        {
          path: 'staff_list',
          name: 'staff_list',
          components: {default: StaffList}
        },
        {
          path: 'staff/new',
          name: 'staff',
          components: {default: Staff}
        },
        {
          path: 'staff/edit/:id',
          name: 'staff',
          components: {default: Staff}
        },
        {
          path: 'staff/calendar/edit/:id',
          name: 'calendar',
          components: {default: Calendar}
        },
        {
          path: 'admin_user_list',
          name: 'admin_user_list',
          components: {default: AdminUserList}
        },
        {
          path: 'admin_user/history/:id',
          name: 'admin_user_history',
          components: {default: AccessHistory}
        },
        {
          path: 'admin_user/edit/:id',
          name: 'admin_user_edit',
          components: {default: AdminUser}
        },
        {
          path: 'admin_user/new',
          name: 'admin_user_new',
          components: {default: AdminUser}
        },
        {
          path: 'qr_issue',
          name: 'qr_issue',
          components: {default: QrIssue}
        },
        {
          path: 'qa_category_list',
          name: 'qa_category_list',
          components: {default: QaCategoryList}
        },
        {
          path: 'qa_category/new',
          name: 'qa_category',
          components: {default: QaCategory}
        },
        {
          path: 'qa_category/edit/:id',
          name: 'qa_category',
          components: {default: QaCategory}
        },
        {
          path: 'qa_information_list',
          name: 'qa_information_list',
          components: {default: QaInformationList}
        },
        {
          path: 'qa_information/edit/:id',
          name: 'qa_information',
          components: {default: QaInformation}
        },
        {
          path: 'qa_information/new',
          name: 'qa_information',
          components: {default: QaInformation}
        },
        {
          path: 'contact_category_list',
          name: 'contact_category_list',
          components: {default: ContactCategoryList}
        },
        {
          path: 'contact_category/new',
          name: 'contact_category',
          components: {default: ContactCategory}
        },
        {
          path: 'contact_category/edit/:id',
          name: 'contact_category',
          components: {default: ContactCategory}
        },
        {
          path: 'contact_list',
          name: 'contact_list',
          components: {default: ContactList}
        },
        {
          path: 'contact/edit/:id',
          name: 'contact',
          components: {default: Contact}
        }
        ,
        {
          path: 'csv_import',
          name: 'csv_import',
          components: {default: CsvImport}
        }
        ,
        {
          path: 'csv_export',
          name: 'csv_export',
          components: {default: CsvExport}
        }
        ,
        {
          path: '/admin/require_version',
          name: 'require_version',
          components: {default: RequireVersion}
        }
        ,
        {
          path: 'telegram_history_list',
          name: 'telegram_history_list',
          components: {default: TelegramHistoryList}
        }
        ,
        {
          path: 'telegram_history/edit/:id',
          name: 'telegram_history',
          components: {default: TelegramHistory}
        }

      ],beforeEnter( to, from, next){
        if (Cookies.get('accessToken') != null && new Date() <= new Date(Cookies.get('accessTokenExpireDate'))){
          next()
        } else{
          router.push({ path: '/admin/login' })
        }
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
export default router