<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">スタンプクーポン検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開期間
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getStampCoupons(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">スタンプクーポン一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
                <div class="tHead">
                  <div class="tHead_th">並び替え</div>
                  <div class="tHead_th">スタンプクーポンコード</div>
                  <div class="tHead_th">スタンプクーポン名</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">公開期間</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td cursorGrab"><md-icon class="dragHandle">reorder</md-icon></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.code }}</md-label></div>                 
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>                 
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="active">有効</md-option>
                        <md-option value="not_active">無効</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td">{{ item.period }}</div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-success mg0"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </draggable>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getStampCoupons">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>
</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import Draggable from 'vuedraggable'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      Draggable
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        selectedStart: '',
        selectedEnd: '',
        status: '',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getStampCoupons (page) {
        var condition = {
          page: page,
          public_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          public_to: this.selectedEnd === '' ? '' :  this.initDate(this.selectedEnd),
          status: this.status　=== '選択しない' ? '' :　this.status
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/stamp_coupons/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプクーポン情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            number: res[i].attributes.position,
            code: res[i].attributes.code,
            name: res[i].attributes.name,
            status: res[i].attributes.status,
            period: this.initDate(res[i].attributes['public-from']) + '〜' + this.initDate(res[i].attributes['public-to'])
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleDelete (item) {
        swal({
          title: 'このスタンプクーポンを削除してもよろしいですか?',
          text: `削除してしまうと、元に戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/stamp_coupons/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`スタンプクーポンを削除しました`)
          this.getStampCoupons(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプクーポンを削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      updateList (event) {
        var id = event.item.getAttribute('data-id')
        this.updatePosition(id, event.newIndex + 1)
      },
      updatePosition (id, number) {
        var condition = {
          stamp_coupon: {
            position: (this.pageData.currentPage - 1) * 25 + number - 1
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/stamp_coupons/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタンプクーポン表示順の更新に失敗しました')
          }
        })
      },
      updateStatus (id, status) {
        var condition = {
          stamp_coupon: {
            'status': status,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/stamp_coupons/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/stamp_coupon/edit/' + item.id })
      },
    },
    mounted () {
      // role check
      if (this.shopFlag || !this.opt_coupon) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>