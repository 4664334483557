<template>
  <md-field :class="[
                {'md-error': errors.has('companyId')},
                {'md-valid': !errors.has('companyId') && touched.companyId}]" >
    <md-select
      v-model="companyId"
      data-vv-name="companyId"
      v-validate= "modelValidations.companyId">
      <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
    </md-select>
    <slide-y-up-transition>
      <div class="vErrorText" v-show="errors.has('companyId')">【必須】必ず一つ選択してください</div>
    </slide-y-up-transition>
    <slide-y-down-transition>
      <md-icon class="error" v-show="errors.has('companyId')" style="margin-right:20px">close</md-icon>
    </slide-y-down-transition>
    <slide-y-down-transition>
      <md-icon class="success" v-show="!errors.has('companyId') && touched.companyId" style="margin-right:20px">done</md-icon>
    </slide-y-down-transition>
  </md-field>
</template>
<script>
  import NestedValidator from '../../mixins/nested-validator'
  export default {
    inject: ['$validator'],
    mixins: [NestedValidator],
    data() {
      return {
        listData: [],
        modelValidations: {
          companyId: {
            required: true
          }
        },
        touched: {
          companyId: false,
        },
      }
    },
    model: {
    },
    props:  ['value','noSelectFlag','saveCondition','touchedFlag'],    // undefinedはfalse扱い
    methods: {
      getCompanies () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          this.dangerNotify('事業者リストを取得できませんでした')
        })
      },
      createList (obj) {
        if (this.noSelectFlag) {
          var item = {
            id: '選択しない',
            name: '選択しない',
          }
          this.listData.push(item)
          if (!this.saveCondition) {
            this.companyId = '選択しない'
          }
        }
        for (var i in obj) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.listData.push(item)
        }
      },
      setCompany () {
        var companySelect = sessionStorage.getItem('companySelect')
        if (companySelect === null) {
          this.getCompanies()
        } else {
          companySelect = JSON.parse(companySelect)
          this.createList(companySelect)
        }
      }
    },
    mounted () {
      this.setCompany()
      this.touched.companyId = false
    },watch: {
      companyId(){
        this.touched.companyId = this.touchedFlag
      },
      '$route.path'(value){
        if (value.match('new')) {
          this.$validator.reset()
          this.touched.companyId = false 
        }
      }
    },computed: {
      companyId: {
        get: function() {
          return this.value;
        },
        set: function(newValue) {
          this.$emit('input', newValue)
        }
      }
    }

  }
</script>
<style scoped>
.companySelect{
  /* max-width: 120px; */
  width: 100%;
}
</style>