import EventBus from '../mixins/event-bus';

export default {
  mounted() {
    EventBus.$on('validate', (errorBag) => {
      this.$validator.validateAll();
      this.errors.items.forEach((error) => {
        errorBag.add(error);
      });
    });
    EventBus.$on('reset', (errorBag) => {
      this.$validator.reset();
      setTimeout (times =>{
      for (var key in this.touched) {
          this.touched[key] = false
        }
      },100)
    });
  },
};