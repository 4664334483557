<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">お問合せ詳細</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会員番号
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input
                  v-model="userNo"
                  type="text"
                  class="readonlyBg"
                  :readonly="true">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ニックネーム
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input
                  v-model="nickname"
                  type="text"
                  class="readonlyBg"
                  :readonly="true">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item">
              <md-field  class="readonlyBg" >
                <md-input v-model="email"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              問合せ内容
            </label>
            <div class="md-layout-item">
              <md-field class="readonlyBg" style="padding-top:10px">
                <md-textarea v-model="message" style="padding:0"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              備考・メモ
            </label>
            <div class="md-layout-item">
              <md-field style="padding-top:10px">
                <md-textarea id="txtAreaProfile" class="txtArea" v-model="memo" style="padding:0"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              お問合せカテゴリ名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <contact-category-select v-model="categoryId" :touchedFlag="true"></contact-category-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              問合せ日時
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <p class="readonlyBg">{{createdAt | timeFormat2}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              対応ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="not_reply">未回答</md-option>
                  <md-option value="reply">回答済み</md-option>
                  <md-option value="no_need">回答不要</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import ContactCategorySelect from '../components/additional/ContactCategorySelect.vue'
  import router from '../packs/router.js'
  import qs from 'qs'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      ContactCategorySelect
    },
    computed: {
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        userNo: '',
        nickname: '',
        email: '',
        message: '',
        memo: '',
        categoryId: '',
        createdAt: '',
        status: '',
        newFlag: false,
        touched: {
          memo: false,
          categoryId: false,
          status: false
        },
        modelValidations: {
          status: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/contact_list/' })
      },
      getInformation () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/contacts/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.userNo = response.data.user_no
          this.nickname = response.data.nickname
          this.email = response.data.email
          this.message = response.data.message
          this.memo = response.data.memo
          this.createdAt = response.data.created_at
          this.status = response.data.status
          this.categoryId = response.data.contact_category_id
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せ情報を正しく取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateinformation()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateinformation(){
        var condition = {
          contact: {
            contact_category_id: this.categoryId,
            memo: this.memo,
            status:this.status
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/contacts/' : this.AdminApiUrl + '/contacts/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`お問合せ情報を`+this.buttonWord+`しました`,'/admin/contact_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`お問合せ情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.userNo = ''
        this.nickname = ''
        this.email = ''
        this.message = ''
        this.memo = ''
        this.categoryId = ''
        this.createdAt = ''
        this.status = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getInformation()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getInformation()
        }
      },
      memo () {
        this.touched.memo = true
      },
      categoryId(){
        this.touched.categoryId = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.readonlyBg{
  background-color: rgb(211, 211, 211);
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
