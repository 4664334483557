<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">{{ productData.title }}商品情報</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productNumber')},
                {'md-valid': !errors.has('productNumber') && touched.productNumber}]">
                <md-input
                  v-model="productNumber"
                  data-vv-name="productNumber"
                  type="text"
                  v-validate= "modelValidations.productNumber"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productNumber')">【必須】255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productNumber')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productNumber') && touched.productNumber">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productName')},
                {'md-valid': !errors.has('productName') && touched.productName}]">
                <md-input
                  v-model="productName"
                  data-vv-name="productName"
                  type="text"
                  v-validate= "modelValidations.productName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productName') && touched.productName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>

            <div class="md-layout-item md-size-40  md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true" :touchedFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">販売中</md-option>
                  <md-option value="not_active">販売停止中</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              販売期間
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <div>
                <vuejs-datepicker
                  :format="DatePickerFormat"
                  v-model="selectedStart"
                  :language="ja"
                  data-vv-name="selectedStart"
                  v-validate= "modelValidations.selectedStart"
                  ></vuejs-datepicker>
                <md-button
                  class="md-just-icon md-default md-simple dateDelete"
                  @click.native="selectedStart = ''">
                  <md-icon>close</md-icon>
                </md-button>
                <slide-y-up-transition>
                  <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
                </slide-y-down-transition>
              </div>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品説明
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="productDetail" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="productData.stockManagement">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              在庫
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="stockUnlimited" :value="true">無制限</md-radio>
              <md-radio v-model="stockUnlimited" :value="false">制限あり</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-show="!stockUnlimited">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              在庫数
            </label>
            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('stockNumber')},
                {'md-valid': !errors.has('stockNumber') && touched.stockNumber}]">
                <md-input
                  v-model="stockNumber"
                  data-vv-name="stockNumber"
                  v-validate= "stockUnlimited === false ? modelValidations.stockNumber : ''"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('stockNumber')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('stockNumber')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('stockNumber') && touched.stockNumber" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              定価
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('listPrice')},
                {'md-valid': !errors.has('listPrice') && touched.listPrice}]">
                <md-input
                  v-model="listPrice"
                  data-vv-name="listPrice"
                  v-validate= "modelValidations.listPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('listPrice')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('listPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('listPrice') && touched.listPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              販売価格
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('sellingPrice')},
                {'md-valid': !errors.has('sellingPrice') && touched.sellingPrice}]">
                <md-input
                  v-model="sellingPrice"
                  data-vv-name="sellingPrice"
                  v-validate= "modelValidations.sellingPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('sellingPrice')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('sellingPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('sellingPrice') && touched.sellingPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-show="productData.timeManagement">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              所要時間
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('requiredTime')},
                {'md-valid': !errors.has('requiredTime') && touched.requiredTime}]">
                <md-select
                  v-model="requiredTime"
                  data-vv-name="requiredTime"
                  v-validate= "modelValidations.requiredTime"
                  required>
                  <md-option value="15">15分</md-option>
                  <md-option value="30">30分</md-option>
                  <md-option value="45">45分</md-option>
                  <md-option value="60">60分</md-option>
                  <md-option value="90">90分</md-option>
                  <md-option value="120">120分</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('requiredTime')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('requiredTime')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('requiredTime') && touched.requiredTime" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              消費税
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="taxRate" value="normal">標準税率(10%)</md-radio>
              <md-radio v-model="taxRate" value="reduced">軽減税率(8%)</md-radio>
              <md-radio v-model="taxRate" value="free">非課税(0%)</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品カテゴリ
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
              <div class="categoryContainer">
                <div class="bigCategory" v-for="item, index in categories" :key="item.id">
                  <div class="categoryDetail">
                    <div class="checkArea">
                      <md-checkbox v-model="item.check" class="categoryCheck">{{item.name}}</md-checkbox>
                      <div class="toggleBox">
                        <md-button
                          class="md-just-icon md-simple"
                          @click.native="item.openFlag = !item.openFlag">
                          <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                          <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                        </md-button>
                      </div>
                    </div>
                  </div>
                  <slide-y-up-transition>
                    <div class="progenyCategory" v-show="item.openFlag">
                      <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                        <div class="categoryDetail">
                          <div class="checkArea">
                            <md-checkbox v-model="itemC.check" class="categoryCheck">{{itemC.name}}</md-checkbox>
                            <div class="toggleBox">
                              <md-button
                                class="md-just-icon md-simple"
                                @click.native="itemC.openFlag = !itemC.openFlag">
                                <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                                <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                              </md-button>
                            </div>
                          </div>
                        </div>
                        <slide-y-up-transition>
                          <div class="progenyCategory" v-show="itemC.openFlag">
                            <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                              <div class="categoryDetail">
                                <div class="checkArea">
                                  <md-checkbox v-model="itemCC.check" class="categoryCheck">{{itemCC.name}}</md-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </slide-y-up-transition>
                      </div>
                    </div>
                  </slide-y-up-transition>
                </div>

              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メイン画像
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageMain">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageMain" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeMainImage" v-if="imageMain" data-id="imageMain"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageMain">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileMainChange" data-id="imageMain">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              サブ画像
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input imageSub" v-for="(item,index) in imgList" :key="index">
                <div v-if="!item.url">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="item.url" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSubImage" v-if="item.url" :data-id="index"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!item.url">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @input="onFileSubChange" :data-id="index">
                  </md-button>
                </div>
              </div>

              <div v-show="buttonFlag" class="BtnAdd" @click="addImage"><md-icon>add</md-icon><span>サブ画像を追加する</span></div>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">{{buttonWord}}</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import ShopSelect from './ShopSelect.vue'
  import router from '../../packs/router.js'
  import EventBus from '../../mixins/event-bus.js';
  import role from "../../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      ShopSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      },
      productData: Object
    },
    data () {
      return {
        buttonWord: '登録',
        productNumber: '',
        productName: '',
        shopId: '',
        status: '',
        selectedStart: null,
        selectedEnd: null,
        productDetail: '',
        stockUnlimited: this.productData.stockManagement ? false : true,
        stockNumber: '',
        listPrice: '',
        sellingPrice: '',
        requiredTime: null,
        imageMain: '',
        imgList: [],
        newFlag: false,
        buttonFlag: true,
        taxRate: '',
        categories: [],
        touched: {
          productNumber: false,
          productName: false,
          status: false,
          selectedStart: false,
          selectedEnd: false,
          stockNumber: false,
          listPrice: false,
          sellingPrice: false,
          requiredTime: false,
          taxRate: false
        },
        modelValidations: {
          productNumber: {
            max: 255,
            required: true,
            alpha_num: true
          },
          productName: {
            max: 255,
            required: true
          },
          shopId: {
            required: true
          },
          status:{
            required: true
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          },
          stockNumber:{
            max: 6,
            required: true,
            numeric: true
          },
          listPrice:{
            max: 6,
            required: true,
            numeric: true
          },
          sellingPrice:{
            max: 6,
            required: true,
            numeric: true
          },
          requiredTime:{
            required: this.productData.timeManagement,
          }
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: this.productData.returnBack })
      },
      validate() {
        EventBus.$emit('validate', this.errors)
        this.$validator.validateAll().then(isValid => {
          if (this.errors.items.length == 0) {
            this.putItem();
          } else {
            this.validateError();
          }
        })
      },
      onFileMainChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createMainImage(files[0],ids);
      },
      createMainImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeMainImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      onFileSubChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createSubImage(files[0],ids);
      },
      createSubImage(file,fileId) {
        let reader = new FileReader()
        let $this = this

        reader.onload = (e) => {
          $this.imgList[fileId].url = e.target.result
        };
        reader.readAsDataURL(file)
      },
      removeSubImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id')
        this.imgList[ids].url = ''
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        if (this.productDetail.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.productDetail
        this.productDetail = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      },
      getItem () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + this.productData.apiUrl + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.productNumber = response.data.code
          this.productName = response.data.name
          this.productDetail = response.data.description
          this.stockUnlimited = response.data.stock_unlimited
          this.stockNumber = response.data.stock_count
          this.listPrice = Math.floor(response.data.regular_price)
          this.sellingPrice = Math.floor(response.data.sales_price)
          this.status = response.data.status
          this.requiredTime = response.data.required_time
          response.data.shop_id === null ? this.shopId='選択しない' :  this.shopId = response.data.shop_id
          this.taxRate = response.data.tax_rate
          if (response.data.public_from !== null) this.selectedStart = response.data.public_from.split('T')[0]
          if (response.data.public_to !== null) this.selectedEnd = response.data.public_to.split('T')[0]

          this.imageMain = response.data.main_image_url
          if (response.data.sub_image1_url !== '' && response.data.sub_image1_url !== null) {
            this.imgList.push({url:response.data.sub_image1_url})
          }
          if (response.data.sub_image2_url !== '' && response.data.sub_image2_url !== null) {
            this.imgList.push({url:response.data.sub_image2_url})
          }
          if (response.data.sub_image3_url !== '' && response.data.sub_image3_url !== null) {
            this.imgList.push({url:response.data.sub_image3_url})
          }
          if (response.data.sub_image4_url !== '' && response.data.sub_image4_url !== null) {
            this.imgList.push({url:response.data.sub_image4_url})
          }
          if (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null) {
            this.imgList.push({url:response.data.sub_image5_url})
          }
          this.checkImgLength()
          this.createCategoryList(response.data.categories)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      putItem () {
        var condition = {
          product: {
            code: this.productNumber,
            name: this.productName,
            shop_id: this.shopId === '選択しない' ? '' : this.shopId,
            status: this.status,
            stock_count: this.stockNumber,
            stock_unlimited: this.stockUnlimited,
            description: this.productDetail,
            sales_price: this.sellingPrice,
            regular_price: this.listPrice,
            public_from: this.selectedStart,
            public_to: this.selectedEnd,
            required_time: this.requiredTime,
            categories: this.checkCategories(),
            tax_rate: this.taxRate
          }
        }

        if(this.imageMain === '' || this.imageMain === null){
          condition.product.main_image = ''
        } else if (this.imageMain.indexOf('/uploads/') == -1) {
          condition.product.main_image = this.imageMain
        }

        for (var i = 0; i < this.imgList.length; i++) {
          if(this.imgList[i].url === '' || this.imgList[i].url === null){
            condition.product['sub_image' + (i + 1)] = ''
          } else if (this.imgList[i].url.indexOf('/uploads/') == -1) {
            condition.product['sub_image' + (i + 1)] = this.imgList[i].url
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl+this.productData.apiUrl : this.AdminApiUrl+this.productData.apiUrl + this.$route.params.id,
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`商品情報を`+this.buttonWord+`しました`, this.productData.returnBack)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`商品情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      removeImage () {
        this.imgList.pop()
        this.checkImgLength()
      },
      addImage () {
        var image = {
          url: ''
        }
        this.imgList.push(image)
        this.checkImgLength()
      },
      checkImgLength () {
        if (this.imgList.length >= 5) {
          this.buttonFlag = false
        } else {
          this.buttonFlag = true
        }
      },
      resetData () {
        this.productNumber = ''
        this.productName = ''
        this.shopId = '選択しない'
        this.status = ''
        this.selectedStart = null
        this.selectedEnd = null
        this.productDetail = ''
        this.stockUnlimited = this.productData.stockManagement ? false : true,
        this.stockNumber = ''
        this.listPrice = ''
        this.requiredTime = null
        this.taxRate = 'normal'
        this.sellingPrice = ''
        this.imageMain = '',
        this.imgList = [],
        this.buttonFlag = true,
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        this.selectedStart = this.initDate(new Date());
        this.selectedEnd = this.initDate(new Date() , 5);
        },100)
      },
      getCategory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
          // params: condition
        }).then(response => {
          this.createNewCategoryList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createNewCategoryList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false,
            check: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
        this.sliderBgFlag = false
      },
      createCategoryList (obj) {
        var res = obj
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].name,
            children: [],
            openFlag: false,
            check: res[i].checked
          }
          var child = res[i].children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: child[j].checked
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: child[j].children[k].checked
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
      },
      checkCategories () {
        var checkedCategories = []
        for (var i in this.categories) {
          if (this.categories[i].check) {
            checkedCategories.push(this.categories[i].id)
          }
          var child = this.categories[i].children
          for (var j in child) {
            if (child[j].check) {
              checkedCategories.push(child[j].id)
            }
            for (var k in child[j].children) {
              if (child[j].children[k].check) {
              checkedCategories.push(child[j].children[k].id)
              }
            }
          }
        }
        if(checkedCategories.length === 0){
          this.validateError("商品カテゴリを一つ以上チェックして下さい。");
          exit;
        }
        return checkedCategories.join()
      }
    },
    mounted () {
      if (this.shopFlag || 
         (this.productData.title=="事前注文" && !this.opt_preOrder) || 
         (this.productData.title=="事前予約" && !this.opt_reserveOrder)) {
        router.push({ path: '/admin/login'})
        return false
      }
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
        this.resetData()
        this.getCategory()
      } else {
        this.buttonWord = '更新'
        this.getItem()
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
          this.getCategory()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getItem()
        }
      },
      productNumber(){
        this.touched.productNumber = true
      },
      productName(){
        this.touched.productName = true
      },
      status(){
        this.touched.status = true
      },
      selectedStart(){
        this.touched.selectedStart = true
      },
      selectedEnd(){
        this.touched.selectedEnd = true
      },
      stockNumber(){
        this.touched.stockNumber = true
      },
      listPrice(){
        this.touched.listPrice = true
      },
      sellingPrice(){
        this.touched.sellingPrice = true
      },
      requiredTime(){
        this.touched.requiredTime = true
      },
      taxRate(){
        this.touched.taxRate = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}
.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>