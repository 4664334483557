import { render, staticRenderFns } from "./ContactCategorySelect.vue?vue&type=template&id=40a79ade&scoped=true&"
import script from "./ContactCategorySelect.vue?vue&type=script&lang=js&"
export * from "./ContactCategorySelect.vue?vue&type=script&lang=js&"
import style0 from "./ContactCategorySelect.vue?vue&type=style&index=0&id=40a79ade&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a79ade",
  null
  
)

export default component.exports