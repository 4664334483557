<template>
  <md-card-content>
    <div style="position: relative;height: 20px;">
      <slide-y-up-transition>
        <div class="emptyError" v-show="workingPlanEmpty">【必須】必ず一日は営業時間を設定してください</div>
      </slide-y-up-transition>
    </div>
    <div class="md-layout md-layout-item md-size-100">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel is-red">
        日
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.sunday.start" @input="checkTime('sunday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.sunday.reverse">閉店時間が開店時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.sunday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.sunday.reverse || validatePlan.sunday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.sunday.reverse && !validatePlan.sunday.empty && touched.sunday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.sunday.end" @input="checkTime('sunday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.sunday.reverse || validatePlan.sunday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.sunday.reverse && !validatePlan.sunday.empty && touched.sunday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.sunday.start || workingPlans.sunday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.sunday.breaks[0].start" @input="checkBreakTime('sunday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.sunday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.sunday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.sunday.breaks.reverse || validatePlan.sunday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.sunday.breaks.reverse && !validatePlan.sunday.breaks.empty && touched.sundayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.sunday.breaks[0].end" @input="checkBreakTime('sunday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.sunday.breaks.reverse || validatePlan.sunday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.sunday.breaks.reverse && !validatePlan.sunday.breaks.empty && touched.sundayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel">
        月
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.monday.start" @input="checkTime('monday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.monday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.monday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.monday.reverse || validatePlan.monday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.monday.reverse && !validatePlan.monday.empty && touched.monday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.monday.end" @input="checkTime('monday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.monday.reverse || validatePlan.monday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.monday.reverse && !validatePlan.monday.empty && touched.monday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.monday.start || workingPlans.monday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.monday.breaks[0].start" @input="checkBreakTime('monday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.monday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.monday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.monday.breaks.reverse || validatePlan.monday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.monday.breaks.reverse && !validatePlan.monday.breaks.empty && touched.mondayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.monday.breaks[0].end" @input="checkBreakTime('monday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.monday.breaks.reverse || validatePlan.monday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.monday.breaks.reverse && !validatePlan.monday.breaks.empty && touched.mondayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel">
        火
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.tuesday.start" @input="checkTime('tuesday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.tuesday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.tuesday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.tuesday.reverse || validatePlan.tuesday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.tuesday.reverse && !validatePlan.tuesday.empty && touched.tuesday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.tuesday.end" @input="checkTime('tuesday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.tuesday.reverse || validatePlan.tuesday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.tuesday.reverse && !validatePlan.tuesday.empty && touched.tuesday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.tuesday.start || workingPlans.tuesday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.tuesday.breaks[0].start" @input="checkBreakTime('tuesday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.tuesday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.tuesday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.tuesday.breaks.reverse || validatePlan.tuesday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.tuesday.breaks.reverse && !validatePlan.tuesday.breaks.empty && touched.tuesdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.tuesday.breaks[0].end" @input="checkBreakTime('tuesday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.tuesday.breaks.reverse || validatePlan.tuesday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.tuesday.breaks.reverse && !validatePlan.tuesday.breaks.empty && touched.tuesdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel">
        水
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.wednesday.start" @input="checkTime('wednesday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.wednesday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.wednesday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.wednesday.reverse || validatePlan.wednesday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.wednesday.reverse && !validatePlan.wednesday.empty && touched.wednesday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.wednesday.end" @input="checkTime('wednesday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.wednesday.reverse || validatePlan.wednesday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.wednesday.reverse && !validatePlan.wednesday.empty && touched.wednesday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.wednesday.start || workingPlans.wednesday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.wednesday.breaks[0].start" @input="checkBreakTime('wednesday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.wednesday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.wednesday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.wednesday.breaks.reverse || validatePlan.wednesday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.wednesday.breaks.reverse && !validatePlan.wednesday.breaks.empty && touched.wednesdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.wednesday.breaks[0].end" @input="checkBreakTime('wednesday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.wednesday.breaks.reverse || validatePlan.wednesday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.wednesday.breaks.reverse && !validatePlan.wednesday.breaks.empty && touched.wednesdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel">
        木
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.thursday.start" @input="checkTime('thursday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.thursday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.thursday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.thursday.reverse || validatePlan.thursday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.thursday.reverse && !validatePlan.thursday.empty && touched.thursday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.thursday.end" @input="checkTime('thursday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.thursday.reverse || validatePlan.thursday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.thursday.reverse && !validatePlan.thursday.empty && touched.thursday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.thursday.start || workingPlans.thursday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.thursday.breaks[0].start" @input="checkBreakTime('thursday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.thursday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.thursday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.thursday.breaks.reverse || validatePlan.thursday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.thursday.breaks.reverse && !validatePlan.thursday.breaks.empty && touched.thursdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.thursday.breaks[0].end" @input="checkBreakTime('thursday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.thursday.breaks.reverse || validatePlan.thursday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.thursday.breaks.reverse && !validatePlan.thursday.breaks.empty && touched.thursdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel">
        金
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.friday.start" @input="checkTime('friday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.friday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.friday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.friday.reverse || validatePlan.friday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.friday.reverse && !validatePlan.friday.empty && touched.friday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.friday.end" @input="checkTime('friday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.friday.reverse || validatePlan.friday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.friday.reverse && !validatePlan.friday.empty && touched.friday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.friday.start || workingPlans.friday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.friday.breaks[0].start" @input="checkBreakTime('friday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.friday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.friday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.friday.breaks.reverse || validatePlan.friday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.friday.breaks.reverse && !validatePlan.friday.breaks.empty && touched.fridayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.friday.breaks[0].end" @input="checkBreakTime('friday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.friday.breaks.reverse || validatePlan.friday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.friday.breaks.reverse && !validatePlan.friday.breaks.empty && touched.fridayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>

    <div class="md-layout md-layout-item md-size-100 mt-20">
      <label class="md-layout-item md-size-10 md-xsmall-size-100 md-form-label weekLabel is-blue">
        土
      </label>
      <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel">営業<br class="is-xs-block">時間</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.saturday.start" @input="checkTime('saturday')"></time-setter>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.saturday.reverse">閉店時間が開店時間より前になっています、正しい時刻を設定してください</div>
          </slide-y-up-transition>
          <slide-y-up-transition>
            <div class="vErrorText" v-show="validatePlan.saturday.empty">設定する場合は必ず両方の時刻を入力してください</div>
          </slide-y-up-transition>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.saturday.reverse || validatePlan.saturday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.saturday.reverse && !validatePlan.saturday.empty && touched.saturday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item md-size-5 zipBetween">〜</div>
      <div class="md-layout-item md-size-20 md-xsmall-size-40">
        <md-field>
          <time-setter v-model="workingPlans.saturday.end" @input="checkTime('saturday')"></time-setter>
          <slide-y-down-transition>
            <md-icon class="error" v-show="validatePlan.saturday.reverse || validatePlan.saturday.empty">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!validatePlan.saturday.reverse && !validatePlan.saturday.empty && touched.saturday">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <slide-y-up-transition>
      <div v-show="workingPlans.saturday.start || workingPlans.saturday.end" class="md-layout md-layout-item md-size-100">
        <label class="md-layout-item md-size-10 md-xsmall-size-15 is-xs-none"></label>
        <div class="md-layout-item md-size-10 md-xsmall-size-15 timeLabel is-restRed">休憩<br class="is-xs-block">時間</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.saturday.breaks[0].start" @input="checkBreakTime('saturday')"></time-setter>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.saturday.breaks.reverse">休憩開始時間が休憩終了時間より前になっています、<br class="is-xs-block">正しい時刻を設定してください</div>
            </slide-y-up-transition>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="validatePlan.saturday.breaks.empty">設定する場合は必ず両方の時刻を入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.saturday.breaks.reverse || validatePlan.saturday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.saturday.breaks.reverse && !validatePlan.saturday.breaks.empty && touched.saturdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-5 zipBetween">〜</div>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <time-setter v-model="workingPlans.saturday.breaks[0].end" @input="checkBreakTime('saturday')"></time-setter>
            <slide-y-down-transition>
              <md-icon class="error" v-show="validatePlan.saturday.breaks.reverse || validatePlan.saturday.breaks.empty">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!validatePlan.saturday.breaks.reverse && !validatePlan.saturday.breaks.empty && touched.saturdayBreak">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </slide-y-up-transition>
  </md-card-content>
</template>

<script>
  import TimeSetter from './TimeSetter.vue'
  import TimeSelect from './TimeSelect.vue'

  export default {
    components: {
      TimeSelect,
      TimeSetter
    },
    model: {
    },
    computed: {
    },
    data () {
      return {
        workingPlans: {
          sunday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          monday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          tuesday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          wednesday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          thursday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          friday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          },
          saturday: {
            breaks: [{
              start: '',
              end: ''
            }],
            start: '',
            end: ''
          }
        },
        workingPlanEmpty: false,
        touched: {
          sunday: false,
          sundayBreak: false,
          monday: false,
          mondayBreak: false,
          tuesday: false,
          tuesdayBreak: false,
          wednesday: false,
          wednesdayBreak: false,
          thursday: false,
          thursdayBreak: false,
          friday: false,
          fridayBreak: false,
          saturday: false,
          saturdayBreak: false
        },
        validatePlan: {
          sunday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          monday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          tuesday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          wednesday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          thursday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          friday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          },
          saturday: {
            reverse: false,
            empty: false,
            breaks: {
              reverse: false,
              empty: false
            }
          }
        }
      }
    },
    props: {
    },
    methods: {
      dates () {
        return this.workingPlans
      },
      workingPlan (dates) {        
        for (var key in dates) {
          if (dates[key] !== null) {
            this.workingPlans[key].start = dates[key].start
            this.workingPlans[key].end = dates[key].end
            if (dates[key].breaks[0] !== undefined) {
              this.workingPlans[key].breaks[0].start = dates[key].breaks[0].start
              this.workingPlans[key].breaks[0].end = dates[key].breaks[0].end
            }
          }
        }
      },
      checkTime (week) {
        if (this.workingPlans[week].start === '' & this.workingPlans[week].end === '') {
          this.validatePlan[week].reverse = false
          this.validatePlan[week].empty = false
        } else if (this.workingPlans[week].start !== '' & this.workingPlans[week].end !== '') {
          var startArray = String(this.workingPlans[week].start).split(':')
          var startMinute = Number(startArray[0]) * 60 + Number(startArray[1])
          var EndArray = String(this.workingPlans[week].end).split(':')
          var EndMinute = Number(EndArray[0]) * 60 + Number(EndArray[1])
          if (startMinute >= EndMinute) {
            this.validatePlan[week].reverse = true
            this.validatePlan[week].empty = false
          } else {
            this.validatePlan[week].reverse = false
            this.validatePlan[week].empty = false
            this.workingPlanEmpty = false
          }
        } else {
          this.validatePlan[week].reverse = false
          this.validatePlan[week].empty = true
        }
      },
      checkBreakTime (week) {
        this.touched[week + 'Break'] = true
        if (this.workingPlans[week].breaks[0].start === '' & this.workingPlans[week].breaks[0].end === '') {
          this.validatePlan[week].breaks.reverse = false
          this.validatePlan[week].breaks.empty = false
        } else if (this.workingPlans[week].breaks[0].start !== '' & this.workingPlans[week].breaks[0].end !== '') {
          var startArray = String(this.workingPlans[week].breaks[0].start).split(':')
          var startMinute = Number(startArray[0]) * 60 + Number(startArray[1])
          var EndArray = String(this.workingPlans[week].breaks[0].end).split(':')
          var EndMinute = Number(EndArray[0]) * 60 + Number(EndArray[1])
          if (startMinute >= EndMinute) {
            this.validatePlan[week].breaks.reverse = true
            this.validatePlan[week].breaks.empty = false
          } else {
            this.validatePlan[week].breaks.reverse = false
            this.validatePlan[week].breaks.empty = false
          }
        } else {
          this.validatePlan[week].breaks.reverse = false
          this.validatePlan[week].breaks.empty = true
        }
      },
      emptyCheckPlan () {
        var planEmpty = true
        for (var key in this.workingPlans) {
          if (this.workingPlans[key].start !== '') {
            planEmpty = false
          }

          if (this.workingPlans[key].end !== '') {
            planEmpty = false
          }
        }

        if (planEmpty) {
          this.workingPlanEmpty = true
          return false
        } else {
          this.workingPlanEmpty = false
          return true
        }
      },
      errorCheckPlan () {
        var planErorr = false
        for (var key in this.validatePlan) {
          if (this.validatePlan[key].reverse === true) {
            planErorr = true
          }

          if (this.validatePlan[key].breaks.reverse === true) {
            planErorr = true
          }

          if (this.validatePlan[key].empty === true) {
            planErorr = true
          }

          if (this.validatePlan[key].breaks.empty === true) {
            planErorr = true
          }
        }

        if (planErorr) {
          return false
        } else {
          return true
        }
      },
    },
    mounted () {
    },
    watch: {
      'workingPlans.sunday.start': function () {
        this.touched.sunday = true
      },
      'workingPlans.monday.start': function () {
        this.touched.monday = true
      },
      'workingPlans.tuesday.start': function () {
        this.touched.tuesday = true
      },
      'workingPlans.wednesday.start': function () {
        this.touched.wednesday = true
      },
      'workingPlans.thursday.start': function () {
        this.touched.thursday = true
      },
      'workingPlans.friday.start': function () {
        this.touched.friday = true
      },
      'workingPlans.saturday.start': function () {
        this.touched.saturday = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
