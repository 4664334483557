<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">スタッフ検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="code"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="not_active">無効</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="選択しない">選択しない</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getStaff(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">スタッフ一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">店舗名</div>
                  <div class="tHead_th">コード</div>
                  <div class="tHead_th">氏名</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.shopName }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.code }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>                 
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="active">有効</md-option>
                        <md-option value="not_active">無効</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-success mg0"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                    <drop-down direction="up" class="dropDown">
                      <md-button slot="title" class="md-just-icon md-warning mg0 mgR2 dropdown-toggle" data-toggle="dropdown">
                        <md-icon>dvr</md-icon>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li><a @click="handleEdit(item.id)">基本情報設定</a></li>
                        <li class="is-xs-none"><a @click="calendarEdit(item.id)">休日カレンダー設定</a></li>
                      </ul>
                    </drop-down>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getStaff">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        code: '',
        lastName: '',
        firstName: '',
        shopId: '',
        status: '選択しない',
        selectedStart: null,
        selectedEnd: null,
        currentSort: 'tel',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        shopSelect: [],
        statusRadio: 'all',
        opHousemoney: null,
        opSharing: null,
        opAPI: null
      }
    },
    methods: {
      getStaff (page) {
        var condition = {
          page: page,
          company_id: this.superFlag === true ? '' : this.$Cookies.get('companyId'),
          code: this.code,
          last_name: this.lastName,
          first_name: this.firstName,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          status: this.status === '選択しない' ? '' : this.status
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/staff/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタッフ情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            shopId: res[i].attributes['shop-id'],
            shopName: this.shopChecker(res[i].attributes['shop-id']),
            code: res[i].attributes.code,
            name: res[i].attributes.name,
            status: res[i].attributes.status
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      updateStatus (id, status) {
        var condition = {
          staff: {
            'status': status,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/staff/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
          })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      handleEdit (id) {
        router.push({ path: '/admin/staff/edit/' + id })
      },
      calendarEdit (id) {
        router.push({ path: '/admin/staff/calendar/edit/' + id })
      },
      handleDelete (item) {
        swal({
          title: `このスタッフ情報を削除してもよろしいですか?`,
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/staff/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`スタッフ情報を削除しました`)
          this.getStaff(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スタッフ情報を削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      getShop (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.shopSelect = response.data
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗リストを取得できませんでした')
          }
        })
      },
      shopChecker (id) {
        var name = '店舗名未設定'
        for (var i = 0 ; i < this.shopSelect.length ; i++) {
          if (this.shopSelect[i].id === id) {
            name = this.shopSelect[i].name
            break
          }
        }
        return name
      },
      setShop () {
        var shopSelect = sessionStorage.getItem('shopSelect')
        if (shopSelect === null) {
          this.getShop()
        } else {
          this.shopSelect = JSON.parse(shopSelect)
        }
      }
    },
    mounted () {
      if (!this.opt_reserveOrder) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.setShop()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.btnSpace{
  margin: 0 2px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
