<template>
  <div class="timeWrapper">
    <div class="sliderBg" v-show='sliderBgFlag' @click="sliderBgFlag = false,sliderFlag = false"></div>
    <input
      :value="value"
      @focus="setTime"
    >
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer" v-show="sliderFlag">
        <div class="timeTitle"><i class="material-icons icons">query_builder</i>時刻設定</div>
        <div class="timeSet">{{sHour}}:{{sMinute}}</div>
        <div class="hourLabel"><slider v-model="sliderHour"></slider></div>
        <div class="MinuteLabel"><slider v-model="sliderMinute"></slider></div>
        <p class="timeExplain">バーをスライドして任意の時間を選択してください</p>
        <div class="btnArea">
          <md-button class="md-success md-sm" @click="setOutTime">設定する</md-button>
          <md-button class="md-warning md-sm" @click="deleteTime">取り消す</md-button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import Slider from '../Slider.vue'
  export default {
    components: {
      Slider
    },
    computed: {
      sHour () {
        var time = ''
        time = String(Math.round(this.sliderHour * 23 / 100))
        if (time.length === 1) {
          time = '0' + time
        }
        this.sliderSetHour = time
        return time
      },
      sMinute () {
        var time = ''
        time = String(Math.round(this.sliderMinute * 5 / 100))
        time = time + '0'
        this.sliderSetMinute = time
        return time
      }
    },
    data() {
      return {
        selectedTime: '',
        sliderFlag: false,
        sliderHour: 0,
        sliderMinute: 0,
        sliderSetHour: '',
        sliderSetMinute: '',
        sliderBgFlag: false
      }
    },
    props: ['value'],
    methods: {
      setTime () {
        this.sliderBgFlag = true
        this.sliderFlag = true
        if (this.value !== '') {
          var time = this.value.split(':')
          this.sliderHour = Number(time[0]) / 23 * 100
          this.sliderMinute = Number(time[1]) / 60 * 100
        } else {
          this.sliderHour = 0
          this.sliderMinute = 0
        }
      },
      setOutTime (){
        this.sliderFlag = false
        this.sliderBgFlag = false
        this.$emit('input', this.sliderSetHour + ':' + this.sliderSetMinute)
      },
      deleteTime () {
        this.sliderFlag = false
        this.sliderBgFlag = false
        this.$emit('input', '')
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.timeWrapper{
  position: relative;
}

.icons{
  vertical-align: top;
  margin-right: 11px;
  margin-top: -1px;
}

input{
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px !important;
    -webkit-text-fill-color: #3c4858 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: none;
    background: none;
    -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transition-property: font-size,padding-top,color;
    transition-property: font-size,padding-top,color;
    font-family: inherit;
    line-height: 32px;
    cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sliderContainer{
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border:1px solid #cccccc;
}

.timeTitle{
  font-size: 18px;
}

.timeSet{
  font-size: 14px;
  margin-top: 30px;
  font-weight: bold;
}

.timeSet:before{
  content: '時刻';
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
}

.hourLabel,
.MinuteLabel{
  margin-top: 25px;
  padding: 0 5px 0 30px;
  position: relative;
  height: 20px;
}

.hourLabel{
  margin-top: 30px;
}


.hourLabel:before,
.minuteLabel:before{
  position: absolute;
  left: 0;
  top: -10px;
  font-size: 14px;
  font-weight: bold;
}

.hourLabel:before{
  content: '時';
  margin-right: 20px;
}

.minuteLabel:before{
  content: '分';
  margin-right: 20px;
}

.timeExplain{
  font-size: 12px;
  line-height:14px;
  margin-top: 20px;
  color: #aaaaaa;
}

.btnArea{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width:600px) {
  .sliderBg{
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }

  .sliderContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
  }
}
</style>
