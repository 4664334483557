<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">Push通知検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-xsmall-size-100 md-form-label">
              配信開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="startDay"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="startDay = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-xsmall-size-100 md-form-label">
              配信終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="endDay"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="endDay = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getNotice(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">Push通知一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">配信日時</div>
                  <div class="tHead_th">配信ステータス</div>
                  <div class="tHead_th taR">配信予定数</div>
                  <div class="tHead_th taR">配信完了数</div>
                  <div class="tHead_th">内容</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.deliveryDay | timeFormat2}}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR">{{ item.totalCount | numberWithDelimiter }} 件</div>
                  <div class="tItem_td taR">{{ item.sendCount | numberWithDelimiter }} 件</div>
                  <div class="tItem_td commentBody">{{ item.body }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg2"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg2"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getNotice">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import router from '../packs/router.js'
  import moment from 'moment'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      TimeSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        startDay: '',
        endDay: '',
        startTime: '00:00',
        endTime: '00:00',
        tableData: [],
        resultFlag: false,
        currentSort: '',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      getNotice (page) {
        var condition = {
          page: page,
          delivery_from: this.startDay === '' ?  '' : this.initDate(this.startDay) + ' ' + this.startTime,
          delivery_to: this.endDay === '' ?  '' : this.initDate(this.endDay) + ' ' + this.endTime,
        }

        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/notifications/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('Push通知情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            body: res[i].attributes.body,
            shopName: res[i].attributes['shop-id'],
            deliveryDay: res[i].attributes['delivery-at'],
            totalCount: res[i].attributes['total-count'],
            sendCount: res[i].attributes['send-count']
          }
          switch (res[i].attributes.status) {
            case 'not_sent':
              item.status = '配信前'
              break;
            case 'sending':
              item.status = '配信中'
              break;
            case 'error':
              item.status = 'エラー'
              break;
            case 'sent':
              item.status = '配信済'
              break;
            case 'pending':
              item.status = '保留'
              break;
            default:
              item.status = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleDelete (item) {
        swal({
          title: 'このPush通知を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/notifications/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`Push通知を削除しました`)
          this.getNotice(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('Push通知を削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      handleEdit (id) {
        router.push({ path: '/admin/notification/edit/' + id })
      },
      getToday () {
        this.selectedEnd = this.initDate(new Date() , 5);
        this.treatmentDay = this.initDate(new Date());
      }
    },
    mounted () {
      if (this.shopFlag || !this.opt_pushNotice) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    watch: {
      startDay () {
        this.$refs.startTimer.unlock()
      },
      endDay () {
        this.$refs.endTimer.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.wth100{
  width: 100px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-card{
  z-index: inherit;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
