<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">アプリバージョン登録</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              Androidバージョン
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('android')},
                {'md-valid': !errors.has('android') && touched.android}]">
                <md-input
                  v-model="android"
                  data-vv-name="android"
                  type="text"
                  v-validate= "modelValidations.android"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('android')">【必須】半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('android')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('android') && touched.android">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              iOSバージョン
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('ios')},
                {'md-valid': !errors.has('ios') && touched.ios}]">
                <md-input
                  v-model="ios"
                  data-vv-name="ios"
                  type="text"
                  v-validate= "modelValidations.ios"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('ios')">【必須】半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('ios')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('ios') && touched.ios">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダーバージョン
            </label>
            <div class="md-layout-item">
              <md-field style="width: 100px" :class="[
                {'md-error': errors.has('smartOrderVersion')},
                {'md-valid': !errors.has('smartOrderVersion') && touched.smartOrderVersion}]">
                <md-input
                  v-model="smartOrderVersion"
                  data-vv-name="smartOrderVersion"
                  type="text"
                  v-validate= "modelValidations.smartOrderVersion"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('smartOrderVersion')">【必須】10文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('smartOrderVersion')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('smartOrderVersion') && touched.smartOrderVersion">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt50 mx-auto">
          <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import swal from 'sweetalert2'
  import router from '../packs/router.js'
  import qs from 'qs'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components:{
      SubmitButton
    },
    computed: {
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        android: '',
        ios: '',
        smartOrderVersion: '',
        touched: {
          android: false,
          ios: false,
          smartOrderVersion: false,
        },
        modelValidations: {
          android: {
            required: true,
            numeric: true
          },
          ios: {
            required: true,
            numeric: true
          },
          smartOrderVersion: {
            max: 10,
            required: true
          },
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateVersion()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getversion () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/require_version/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.android = response.data.android
          this.ios = response.data.ios
          this.smartOrderVersion = response.data.smart_order_version
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('バージョン情報を正しく取得できませんでした')
          }
        })
      },
      updateVersion(){
        var condition = {
          require_version: {
            android: this.android,
            ios: this.ios,
            smart_order_version: this.smartOrderVersion,
          }
        }
        this.axios({
          method  : 'POST', 
          url: this.AdminApiUrl + '/require_version/',
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.successAndBackPage(`バージョン情報を`+this.buttonWord+`しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`バージョン情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
    },
    mounted () {
      console.log("mounted");

      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getversion()
    },
    watch: {
      number () {
        this.touched.number = true
      },
      code(){
        this.touched.code = true
      },
      name(){
        this.touched.name = true
      }
    }
  }
  
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
