<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">注文履歴検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              伝票番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="slipNo"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="this.superFlag">
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="this.superFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              事業者
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <company-select v-model="companyId" :noSelectFlag="true" :saveCondition="true"></company-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>事業者コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="smartOrderCompanyCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <brand-select ref="brand" v-model="brandId" :noSelectFlag="true" :saveCondition="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>ブランドコード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="smartOrderBrandCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :noSelectFlag="true" :saveCondition="true"></shop-select>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>店舗コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="smartOrderShopCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              開始注文日時
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="startOrder"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              最終注文日時
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastOrder"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.shopFlag">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払い金額
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="totalPrice"></md-input>
                <div>円</div>
              </md-field>
              
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              テーブル番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tableName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済種別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <payment-select ref="payment" v-model="paymentId" :noSelectFlag="true" :saveCondition="true"></payment-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="orderStatus" name="orderStatus">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="normal">決済済み</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getOrders(1)">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="handleCsvDownload()" v-show="this.superFlag">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">注文履歴一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable">
                <div class="tHead">
                  <div class="tHead_th">伝票番号</div>
                  <div class="tHead_th">テーブル番号</div>
                  <div class="tHead_th">店舗名</div>
                  <div class="tHead_th">支払い金額（税込）</div>
                  <div class="tHead_th">決済種別</div>
                  <div class="tHead_th">決済ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.slipNo }}</div>
                  <div class="tItem_td">{{ item.tableName }}</div>
                  <div class="tItem_td">{{ item.shopName }}</div>
                  <div class="tItem_td">¥{{ item.totalPrice | numberWithDelimiter }}</div>
                  <div class="tItem_td">{{ item.paymentName }}</div>
                  <div class="tItem_td">{{ item.orderStatus }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg2"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from}}件から {{to}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getOrders">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import PaymentSelect from '../components/additional/PaymentSelect.vue'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import role from "../mixins/role.js"
  import swal from 'sweetalert2'

  export default {
    mixins: [ role ],
    components: {
      CompanySelect,
      BrandSelect,
      ShopSelect,
      PaymentSelect,
      TimeSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
      //Vuexの情報をセット
      saveCondition() {
        return this.$store.getters['smartOrderList/condition']
      }
    },
    data () {
      return {
        companyId: '選択しない',
        companyName: '',
        brandId: '選択しない',
        shopCode: '',
        shopId: '選択しない',
        slipNo: '',
        smartOrderCompanyCode: '',
        smartOrderBrandCode: '',
        smartOrderShopCode: '',
        startOrder: '',
        lastOrder: '',
        totalPrice: '',
        tableName: '',
        kinds: '選択しない',
        paymentId: '選択しない',
        orderNum: '',
        startDay: '',
        startTime: '',
        endDay: '',
        endTime: '',
        orderStatus: '選択しない',
        tableData: [],
        resultFlag: false,
        currentSort: '',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      getOrders (page) {
        var condition = {
          page: !isNaN(page) ? page : 1,
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_code: this.shopCode,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          slip_no: this.slipNo,
          smart_order_company_code: this.smartOrderCompanyCode,
          smart_order_brand_code: this.smartOrderBrandCode,
          smart_order_shop_code: this.smartOrderShopCode,
          start_order: this.startOrder,
          last_order: this.lastOrder,
          total_price: this.totalPrice,
          table_name: this.tableName,
          kinds: this.kinds === '選択しない' ? '' : this.kinds,
          payment_id: this.paymentId === '選択しない' ? '' : this.paymentId,
          order_num: this.orderNum,
          updated_start: this.startDay === '' ? '' : this.initDate(this.startDay)+' '+this.startTime,
          updated_end: this.endDay === '' ? '' :  this.initDate(this.endDay)+' '+this.endTime,
          order_status: this.orderStatus === '選択しない' ? '' : this.orderStatus
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/smart_orders/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
          //Vuexに値を設定(dispatchで非同期で動く)
          this.$store.dispatch('smartOrderList/searchParamsAsync', condition)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報を取得できませんでした')
          }
        })
      },
      handleCsvDownload () {
        swal({
          title: 'CSVダウンロード処理を開始します。',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_code: this.shopCode,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          slip_no: this.slipNo,
          smart_order_company_code: this.smartOrderCompanyCode,
          smart_order_brand_code: this.smartOrderBrandCode,
          smart_order_shop_code: this.smartOrderShopCode,
          start_order: this.startOrder,
          last_order: this.lastOrder,
          table_name: this.tableName,
          total_price: this.totalPrice,
          kinds: this.kinds === '選択しない' ? '' : this.kinds,
          payment_id: this.paymentId === '選択しない' ? '' : this.paymentId,
          order_num: this.orderNum,
          updated_start: this.startDay === '' ? '' : this.initDate(this.startDay)+' '+this.startTime,
          updated_end: this.endDay === '' ? '' :  this.initDate(this.endDay)+' '+this.endTime,
          order_status: this.orderStatus === '選択しない' ? '' : this.orderStatus
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/smart_orders/export_csv',
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "ShopOrder not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            slipNo: res[i].attributes['slip-no'],
            shopName: res[i].attributes['shop-name'],
            tableName: res[i].attributes['table-name'],
            totalPrice: res[i].attributes['total-price'],
            paymentName: res[i].attributes['payment-name'],
            updatedAt: res[i].attributes['updated-at']
          }
          switch (res[i].attributes['order-status']) {
            case 'not_receive':
              item.orderStatus = '未処理'
              break;
            case 'normal':
              item.orderStatus = '決済済み'
              break;
            case 'alarm':
              item.orderStatus = 'アラーム'
              break;
            case 'not_write':
              item.orderStatus = '未書込'
              break;
            case 'unknown':
              item.orderStatus = '不明'
              break;
            case 'process':
              item.orderStatus = '処理中'
              break;
            case 'cancel':
              item.orderStatus = 'キャンセル'
              break;
            case 'refund':
              item.orderStatus = (res[i].attributes['kinds']=='credit_card') ? '取消' : '返金'
              break;
            case 'reserve_delete':
              item.orderStatus = '削除予定'
              break;
            case 'recovery':
              item.orderStatus = '復旧後決済済み'
              break;
            default:
              item.orderStatus = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleEdit (id) {
        router.push({ path: '/admin/smart_order/edit/' + id })
      },
      reloadPage(){
        //Vuexの情報を元に検索情報をセット
        this.companyId = this.saveCondition.company_id === '' ? '選択しない' : this.saveCondition.company_id
        this.brandId = this.saveCondition.brand_id === '' ? '選択しない' : this.saveCondition.brand_id
        this.shopCode = this.saveCondition.shop_code
        this.shopId = this.saveCondition.shop_id === '' ? '選択しない' : this.saveCondition.shop_id
        this.slipNo = this.saveCondition.slip_no
        this.smartOrderCompanyCode = this.saveCondition.smart_order_company_code
        this.smartOrderBrandCode = this.saveCondition.smart_order_brand_code
        this.smartOrderShopCode = this.saveCondition.smart_order_shop_code
        this.startOrder = this.saveCondition.start_order
        this.lastOrder = this.saveCondition.last_order
        this.tableName = this.saveCondition.table_name
        this.total_price = this.saveCondition.total_price
        this.kinds = this.saveCondition.kinds === '' ? '選択しない' : this.saveCondition.kinds
        this.paymentId = this.saveCondition.payment_id === '' ? '選択しない' : this.saveCondition.payment_id
        this.orderNum = this.saveCondition.order_num
        this.startDay = this.saveCondition.updated_start
        this.endDay = this.saveCondition.updated_end
        this.orderStatus = this.saveCondition.order_status === '' ? '選択しない' : this.saveCondition.order_status
        //最後にいたページをセット
        this.getOrders(this.saveCondition.page)
      }
    },
    mounted () {
      if (this.companyFlag) {
        this.companyId = this.$Cookies.get('companyId')
      } else if (this.shopFlag) {
        this.shopId = this.$Cookies.get('shopId')
      }
      //page reload
      if(this.saveCondition){
        this.reloadPage()
      }
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect') 
      sessionStorage.removeItem('shopSelect') 
      sessionStorage.removeItem('paymentSelect') 
    },
    watch: {
      companyId () {
        this.$refs.brand.companyId = this.companyId
        this.$refs.brand.resetBrand()
        this.$refs.shop.companyId = this.companyId
        this.$refs.shop.resetShop()
      },
      brandId () {
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
      },
      kinds () {
        this.$refs.payment.kinds = this.kinds
        this.$refs.payment.resetPayment()
      },
      startDay () {
        this.$refs.startTimer.unlock()
      },
      endDay () {
        this.$refs.endTimer.unlock()
      }      
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.wth100{
  width: 100px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.betweenTime{
  line-height: 59px;
}

.md-card{
  z-index: inherit;
}

.commentBody{
  max-width: 100px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
