<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">店舗カード検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗カード番号
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <md-field>
                <md-input v-model="number"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              事業者
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <company-select v-model="companyId" :noSelectFlag="true"></company-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ブランド
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <brand-select ref="brand" v-model="brandId" :noSelectFlag="true"></brand-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              利用店舗
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <shop-select ref="shop" v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              カード属性
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="kinds" name="kinds">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="factory">工場</md-option>
                  <md-option value="manager">店長</md-option>
                  <md-option value="staff">店員</md-option>
                  <md-option value="table_seat">テーブル</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getShopCards">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="handleCsvDownload()" v-show="this.superFlag">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">店舗カード一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable">
                <div class="tHead">
                  <div class="tHead_th">店舗カード番号</div>
                  <div class="tHead_th">利用店舗</div>
                  <div class="tHead_th">カード属性</div>
                  <div class="tHead_th">登録日</div>
                  <div class="tHead_th">更新日</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.number }}</md-label></div>
                  <div class="tItem_td commentBody">{{ item.shopName }}</div>
                  <div class="tItem_td">{{ item.kinds }}</div>
                  <div class="tItem_td">{{ item.createdat | dateFormat }}</div>
                  <div class="tItem_td">{{ item.updatedat | dateFormat }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      v-show="superFlag"
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getShopCards">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import BrandSelect from '../components/additional/BrandSelect.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'

  export default {
    mixins: [ role ],
    components: {
      CompanySelect,
      BrandSelect,
      ShopSelect,
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        number: '',
        companyId: '',
        brandId: '',
        shopId: '',
        shopName: '',
        kinds: '選択しない',
        status: '選択しない',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        statusRadio: 'all',
        opHousemoney: null,
        opSharing: null,
        opAPI: null
      }
    },
    methods: {
      getShopCards (page) {
        var condition = {
          page: page,
          number: this.number,
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          kinds: this.kinds === '選択しない' ? '' : this.kinds,
          status: this.status === '選択しない' ? '' : this.status,
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_cards/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗カード情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            shopId: res[i].attributes['shop-id'],
            shopName: res[i].attributes['shop-name'],
            number: res[i].attributes.number,
            status: res[i].attributes.status === "active" ? '有効' : '無効',
            createdat: res[i].attributes['created-at'],
            updatedat: res[i].attributes['updated-at']
          }
          switch (res[i].attributes.kinds) {
            case "factory":
              item.kinds = '工場'
              break;
            case "manager":
              item.kinds = '店長'
              break;
            case "staff":
              item.kinds = '店員'
              break;
            case "table_seat":
              item.kinds = 'テーブル'
              break;
            default:
              item.kinds = ''
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleCsvDownload () {
        swal({
          title: 'CSVダウンロード処理を開始します。',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          number: this.number,
          company_id: this.companyId === '選択しない' ? '' : this.companyId,
          brand_id: this.brandId === '選択しない' ? '' : this.brandId,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          kinds: this.kinds === '選択しない' ? '' : this.kinds,
          status: this.status === '選択しない' ? '' : this.status,
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/shop_cards/export_csv',
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "ShopCard not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      },
      handleEdit (id) {
        router.push({ path: '/admin/shop_card/edit/' + id })
      },
      handleDelete (item) {
        swal({
          title: `この店舗カード情報を削除してもよろしいですか?`,
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/shop_cards/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`店舗カード情報を削除しました`)
          this.getShopCards(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗カード情報を削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
    },
    mounted () {
    // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect')
      sessionStorage.removeItem('shopSelect')
    },
    watch: {
      companyId () {
        this.$refs.brand.companyId = this.companyId
        this.$refs.brand.resetBrand()
        this.$refs.shop.companyId = this.companyId
        this.$refs.shop.resetShop()
      },
      brandId () {
        this.$refs.shop.brandId = this.brandId
        this.$refs.shop.resetShop()
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }
}
</style>
