<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">注文詳細情報</h4>
        </md-card-header>
        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              伝票番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="slipNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              事業者名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="companyName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>事業者コード
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="smartOrderCompanyCode" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="brandName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>ブランドコード
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="smartOrderBrandCode" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="shopName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              スマートオーダー<br>店舗コード
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="smartOrderShopCode" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文開始日時
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="startOrder" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              最終注文日時
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="lastOrder" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払金額（税込）
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.totalPrice | numberWithDelimiter}}</md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              テーブル番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="tableName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getOrderDetails()">注文商品明細表示</md-button>
        </md-card-actions>
      </md-card>
    </div>
    
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">注文支払情報</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済種別
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="paymentName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderNum" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済ステータス
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100" v-if="(kinds === 'credit_card')">
              <md-field class="UserStatus">
                <md-select v-model="orderStatus" name="orderStatus">
                  <md-option value="normal">決済済み</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100" v-if="!(kinds === 'credit_card')">
              <md-field><md-input v-model="orderStatus" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native="validate" v-show="this.superFlag">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <smart-order-detail
      ref="smartOrderDetail"
    ></smart-order-detail>
  </div>
</template>

<script>
  import SmartOrderDetail from '../components/additional/SmartOrderDetail.vue'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SmartOrderDetail
    },
    computed: {
    },
    props: {
    },
    data () {
      return {         
        cashFlag: false,    
        qrFlag: false,
        orderDate: '',
        slipNo: '',
        companyName: '',
        smartOrderCompanyCode: '',
        brandName: '',
        smartOrderBrandCode: '',
        shopName: '',
        smartOrderShopCode: '',
        startOrder: '',
        lastOrder: '',
        shopTerminalCode: '',
        productTotalPrice: '',
        couponDiscount: '',
        pointDiscount: '',
        totalPrice: '',
        tableName: '',
        paymentName: '',
        orderNum: '',
        orderStatus: '',
        kinds: '',
        canceleable: '',
        isRefd: false,
        isRefdDisplay: false,
        isRefdEnable: false,
        refdDesableMessage: '',
        listData: [],
        shoppingCartTable: [],
        touched: {
          orderStatus: false,
        },
        modelValidations: {
          orderStatus: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/smart_order_list/' })
      },
      getOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/smart_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.orderDate= response.data.order_date
          this.slipNo= response.data.slip_no
          this.companyName= response.data.company_name
          this.smartOrderCompanyCode = response.data.smart_order_company_code
          this.brandName= response.data.brand_name
          this.smartOrderBrandCode = response.data.smart_order_brand_code
          this.shopName= response.data.shop_name
          this.smartOrderShopCode = response.data.smart_order_shop_code
          this.startOrder= response.data.start_order
          this.lastOrder= response.data.last_order
          this.totalPrice= response.data.total_price
          this.tableName= response.data.table_name
          this.paymentName= response.data.payment_name
          this.orderNum= response.data.order_num
          this.kinds= response.data.kinds
          this.orderStatus= response.data.order_status
          this.isRefdDisplay = response.data.refd_display
          this.isRefdEnable = response.data.refd_enable
          this.refdDesableMessage = response.data.refd_desable_message
        })
        .catch(error => {
          this.dangerNotify('注文情報の取得に失敗しました')
          })
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.handleUpdate()
          } else {
            this.validateError()
          }
        })
      },
      handleUpdate() {
        swal({
          title: `ステータスを更新しますか?`,
          text: `クレジット決済処理は１度のみ有効です`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.updateStatus()
          }
        });
      },
      updateStatus() {
        var param = {
          order_status: this.orderStatus,
          pay_status: ((this.kinds=='credit_card' && this.isRefd) && this.orderStatus=='refund' ) ? 'pay_cancel' : ''
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/smart_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            smart_order : param
          }
        }).then(response => {
          this.successAndBackPage(`決済情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('決済情報の更新に失敗しました')
          }
        })
      },
      getOrderDetails() {
        this.$refs.smartOrderDetail.getOrderDetails()
      }
    },
    mounted () {
      this.getOrders()
    },
    watch: {
      orderStatus(){
        this.touched.orderStatus = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.readonlyBg{
  background-color: rgb(211, 211, 211);
  padding-left: 5;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  max-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
