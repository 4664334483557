<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">決済明細基本情報</h4>
        </md-card-header>
        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              伝票番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="slipNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済明細番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="slipDetailNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              事業者名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="companyName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="brandName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="shopName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div> 
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メニウくんID
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="shopTerminalCode" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引日時
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderDate" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.couponDiscount!=0 || this.pointDiscount!=0">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              割引前金額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.productTotalPrice | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.couponDiscount!=0">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              クーポン割引額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.couponDiscount | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.pointDiscount!=0">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ポイント利用額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.pointDiscount | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払金額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.totalPrice | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.totalAmount!=0">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払総額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.totalAmount | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.userNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              かざPayアプリ会員番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="userNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">決済明細詳細情報</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              レジ番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="registerNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              レジ担当者
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="registerStaff" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              テーブル番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="tableName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.sprwId!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              上位端末ID/SPRWID
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="sprwId" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.receiptNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              レシート番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="receiptNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.posTradeNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              POS取引番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="posTradeNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.shopCardNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗カード番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="shopCardNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.orderTitle!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引タイトル
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderTitle" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.brandTradeNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ブランド取引通番
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="brandTradeNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.cardTradeNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              カード取引通番
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="cardTradeNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.terminalTradeNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              端末取引通番
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="terminalTradeNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.approvalNo!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              承認番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="approvalNo" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.beforeBalance!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引前残高
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.beforeBalance | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.afterBalance!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引後残高
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.afterBalance | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.autoChargeAmount!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オートチャージ金額
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field class="readonlyBg" style="padding:5; margin-bottom:10">¥{{this.autoChargeAmount | numberWithDelimiter}}</md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.addPoint!=0">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              今回ポイント
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="addPoint" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.totalPoint!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              累計ポイント
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="totalPoint" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.pointMessage!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ポイントメッセージ
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="pointMessage" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.goodsCode!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード（iD）
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="goodsCode" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click="getOrderDetails()">注文商品明細表示</md-button>
        </md-card-actions>
      </md-card>
    </div>
    
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">決済明細支払い情報</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済ブランド
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="paymentName" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.paymentKinds!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引種別コード
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="paymentKinds" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="orderNum" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-show="this.inquiryNumber!=null">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ThincaCloud取引番号
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field><md-input v-model="inquiryNumber" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済ステータス
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100" v-show="!this.superFlag || !this.canceleable || !this.orderChangeFlag">
              <md-field><md-input v-model="orderStatus" class="readonlyBg" :readonly="true"></md-input></md-field>
            </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" v-show="this.superFlag && this.canceleable && this.orderChangeFlag">
              <md-field :class="[
                {'md-error': errors.has('orderStatus')},
                {'md-valid': !errors.has('orderStatus') && touched.status}]">
                <md-select
                  v-model="orderStatus"
                  data-vv-name="orderStatus"
                  v-validate= "modelValidations.orderStatus"
                  required>
                <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('orderStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('orderStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('orderStatus') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-10 md-xsmall-size-100" v-show="this.superFlag && this.canceleable && this.isRefdDisplay && this.orderStatus=='refund'">
              <md-tooltip md-direction="bottom" v-show="!this.isRefdEnable">{{this.refdDesableMessage}}</md-tooltip>
              <md-checkbox v-model="isRefd" :disabled="!this.isRefdEnable">REFD</md-checkbox>              
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native="validate" v-show="this.superFlag && this.canceleable">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <shop-order-detail
      ref="shopOrderDetail"
    ></shop-order-detail>
  </div>
</template>

<script>
  import ShopOrderDetail from '../components/additional/ShopOrderDetail.vue'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      ShopOrderDetail
    },
    computed: {
    },
    props: {
    },
    data () {
      return {         
        cashFlag: false,    
        qrFlag: false,
        orderDate: '',
        slipNo: '',
        slipDetailNo: '',
        companyName: '',
        brandName: '',
        shopName: '',
        shopTerminalCode: '',
        productTotalPrice: '',
        couponDiscount: '',
        pointDiscount: '',
        totalPrice: '',
        totalAmount: '',
        userNo: '',
        registerNo: '',
        registerStaff: '',
        tableName: '',
        sprwId: '',
        receiptNo: '',
        posTradeNo: '',
        shopCardNo: '',
        orderTitle: '',
        brandTradeNo: '',
        cardTradeNo: '',
        terminalTradeNo: '',
        approvalNo: '',
        beforeBalance: '',
        afterBalance: '',
        autoChargeAmount: '',
        addPoint: '',
        totalPoint: '',
        pointMessage: '',
        goodsCode: '',
        paymentName: '',
        paymentKinds: '',
        orderNum: '',
        inquiryNumber: '',
        orderStatus: '',
        kinds: '',
        canceleable: '',
        isRefd: false,
        isRefdDisplay: false,
        isRefdEnable: false,
        refdDesableMessage: '',
        listData: [],
        shoppingCartTable: [],
        touched: {
          orderStatus: false,
        },
        modelValidations: {
          orderStatus: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/shop_order_list/' })
      },
      getOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.orderDate= response.data.order_date
          this.slipNo= response.data.slip_no
          this.slipDetailNo= response.data.slip_detail_no
          this.companyName= response.data.company_name
          this.brandName= response.data.brand_name
          this.shopName= response.data.shop_name
          this.shopTerminalCode= response.data.shop_terminal_code
          this.productTotalPrice= response.data.product_total_price
          this.couponDiscount= response.data.coupon_discount
          this.pointDiscount= response.data.point_discount
          this.totalPrice= response.data.total_price
          this.totalAmount= response.data.total_amount
          this.userNo= response.data.user_no
          this.registerNo= response.data.register_no
          this.registerStaff= response.data.register_staff
          this.tableName= response.data.table_name
          this.sprwId= response.data.sprw_id
          this.receiptNo= response.data.receipt_no
          this.posTradeNo= response.data.pos_trade_no
          this.shopCardNo= response.data.shop_card_no
          this.orderTitle= response.data.order_title
          this.brandTradeNo= response.data.brand_trade_no
          this.cardTradeNo= response.data.card_trade_no
          this.terminalTradeNo= response.data.terminal_trade_no
          this.approvalNo= response.data.approval_no
          this.beforeBalance= response.data.before_balance
          this.afterBalance= response.data.after_balance
          this.autoChargeAmount= response.data.auto_charge_amount
          this.addPoint= response.data.add_point
          this.totalPoint= response.data.total_point
          this.pointMessage= response.data.point_message
          this.goodsCode= response.data.goods_code
          this.paymentName= response.data.payment_name
          this.paymentKinds= response.data.payment_kinds
          this.orderNum= response.data.order_num
          this.inquiryNumber= response.data.inquiry_number
          this.kinds= response.data.kinds
          this.canceleable = response.data.canceleable
          this.orderStatus = response.data.order_status
          this.isRefdDisplay = response.data.refd_display
          this.isRefdEnable = response.data.refd_enable
          this.refdDesableMessage = response.data.refd_desable_message
          this.setOrderStatus()
        })
        .catch(error => {
          this.dangerNotify('決済情報の取得に失敗しました')
          })
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.handleUpdate()
          } else {
            this.validateError()
          }
        })
      },
      setOrderStatus() {
          if (this.superFlag && this.canceleable && this.orderChangeFlag) {
              this.listData = [{id: "not_receive", name: "未処理"}, {id: "normal", name: "決済済み"}]
              switch (this.kinds) {
                case 'cash':
                  this.listData.push({id: "refund", name: "返金"})
                  break;
                case 'point':
                  this.listData.push({id: "refund", name: "返金"})
                  break;
                case 'credit_card':
                  this.listData.push({id: "refund", name: "取消"})
                  if( this.orderStatus=='refund'){
                    this.listData = [{id: "refund", name: "取消"}]
                  }
                  break;
                case 'qr_code':
                  this.listData.push( {id: "process", name: "処理中"} )
                  if( this.orderStatus=='process'){
                    this.listData.push( {id: "cancel", name: "キャンセル"} )
                  }
                  if( this.orderStatus=='normal'){
                    this.listData.push( {id: "refund", name: "返金"} )
                  }
                  if( this.orderStatus=='cancel'){
                    this.listData = [{id: "cancel", name: "キャンセル"}]
                  }
                  if( this.orderStatus=='refund'){
                    this.listData = [{id: "refund", name: "返金"}]
                  }
                  break;
                case 'e_money':
                  this.listData.push({id: "alarm", name: "アラーム"}, {id: "not_write", name: "未書込"}, {id: "unknown", name: "不明"}, {id: "recovery", name: "復旧後決済済み"},)
                  break;
              }            
          } else {
            switch (this.orderStatus) {
              case 'not_receive':
                this.orderStatus = '未処理'
                break;
              case 'normal':
                this.orderStatus = '決済済み'
                break;
              case 'alarm':
                this.orderStatus = 'アラーム'
                break;
              case 'not_write':
                this.orderStatus = '未書込'
                break;
              case 'unknown':
                this.orderStatus = '不明'
                break;
              case 'process':
                this.orderStatus = '処理中'
                break;
              case 'cancel':
                this.orderStatus = 'キャンセル'
                break;
              case 'refund':
                this.orderStatus = (this.kinds=='credit_card') ? '取消' : '返金'
                break;
              case 'reserve_delete':
                this.orderStatus = '削除予定'
                break;
              case 'recovery':
                this.orderStatus = '復旧後決済済み'
                break;
              default:
                this.orderStatus = '識別不明'
            }
          }
      },
      handleUpdate() {
        swal({
          title: `ステータスを更新しますか?`,
          text: `クレジット決済やQRコード決済の返金処理は１度のみ有効です（何度も返金処理はできません）`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.updateStatus()
          }
        });
      },
      updateStatus() {
        var param = {
          order_status: this.orderStatus,
          pay_status: ((this.kinds=='credit_card' || (this.kinds=='qr_code' && this.isRefd)) && this.orderStatus=='refund' ) ? 'pay_cancel' : ''
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/shop_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            shop_order : param
          }
        }).then(response => {
          this.successAndBackPage(`決済情報を更新しました`)
          this.setOrderStatus()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('決済情報の更新に失敗しました')
          }
        })
      },
      getOrderDetails() {
        this.$refs.shopOrderDetail.getOrderDetails()
      }
    },
    mounted () {
      this.getOrders()
    },
    watch: {
      orderStatus(){
        this.touched.orderStatus = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.readonlyBg{
  background-color: rgb(211, 211, 211);
  padding-left: 5;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  max-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
