<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">事業者サービス情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              サービス名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('serviceName')},
                {'md-valid': !errors.has('serviceName') && touched.serviceName}]">
                <md-input
                  v-model="serviceName"
                  data-vv-name="serviceName"
                  type="text"
                  v-validate= "modelValidations.serviceName">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('serviceName')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('serviceName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('serviceName') && touched.serviceName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アプリURL
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('apiUrl')},
                {'md-valid': !errors.has('apiUrl') && touched.apiUrl}]">
                <md-input
                  v-model="apiUrl"
                  data-vv-name="apiUrl"
                  type="text"
                  v-validate= "modelValidations.apiUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('apiUrl')">半角英数記号のURL形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('apiUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('apiUrl') && touched.apiUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アナリティクストラッキングID
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('trackingId')},
                {'md-valid': !errors.has('trackingId') && touched.trackingId}]">
                <md-input
                  v-model="trackingId"
                  data-vv-name="trackingId"
                  type="text"
                  v-validate= "modelValidations.trackingId">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('trackingId')">255文字以内の半角英数記号で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('trackingId')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('trackingId') && touched.trackingId">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              Firebase認証鍵
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('fcmKey')},
                {'md-valid': !errors.has('fcmKey') && touched.fcmKey}]">
                <md-input
                  v-model="fcmKey"
                  data-vv-name="fcmKey"
                  type="text"
                  v-validate= "modelValidations.fcmKey">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('fcmKey')">255文字以内の半角英数記号で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('fcmKey')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('fcmKey') && touched.fcmKey">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              サービスロゴ
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageCompany">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageCompany" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageCompany" data-id="imageCompany"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageCompany">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageCompany">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特定商取引法
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="commercialLaw"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              プライバシーポリシー
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="servicePolicy"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              利用規約
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="serviceTerms"></froala>
            </div>
          </div>


        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack" v-show="superFlag">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native="validate">登録</md-button>
        </md-card-actions>
      </md-card>

      <div class="linkCont">
        <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
        <div class="bottomLink" @click="goDelivery">配信情報を編集する</div>
        <div class="bottomLink" @click="goApp">アプリ用画像情報を編集する</div>
      </div>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        apiUrl: '',
        serviceName: '',
        trackingId: '',
        fcmKey: '',
        imageCompany: '',
        commercialLaw: '',
        serviceTerms: '',
        servicePolicy: '',
        basicLock: false,
        touched: {
          serviceName: false,
          apiUrl: false,
          trackingId: false,
          fcmKey: false
        },
        modelValidations: {
          serviceName: {
            max: 255
          },
          apiUrl: {
            url: true,
            max: 255
          },
          trackingId: {
            regex: '^[a-zA-Z0-9!-/:-@¥[-`{-~]+$',
            max: 255
          },
          fcmKey: {
            regex: '^[a-zA-Z0-9!-/:-@¥[-`{-~]+$',
            max: 255
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateService()
          } else {
            this.validateError()
          }
        })
      },
      goSetting () {
        router.push({ path: '/admin/company/edit/' + this.$route.params.id })
      },
      goDelivery () {
        router.push({ path: '/admin/company/delivery/edit/' + this.$route.params.id })
      },
      goApp () {
        router.push({ path: '/admin/company/application/edit/' + this.$route.params.id })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      getService () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.serviceName = response.data.name
          this.trackingId = response.data.id
          this.fcmKey = response.data.fcm_key
          this.imageCompany = response.data.logo_url
          this.commercialLaw = response.data.law
          this.serviceTerms = response.data.terms_of_service
          this.servicePolicy = response.data.privacy_policy
          this.apiUrl = response.data.app_url
          this.trackingId = response.data.ga
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('サービス情報を正しく取得できませんでした')
          }
        })
      },
      updateService(){
        var condition = {
          service_setting: {
            company_id: this.$route.params.id,
            name: this.serviceName,
            law: this.commercialLaw,
            terms_of_service: this.serviceTerms,
            privacy_policy: this.servicePolicy,
            app_url: this.apiUrl,
            ga: this.trackingId,
            fcm_key: this.fcmKey
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageCompany === '' || this.imageCompany === null){
          condition.service_setting.logo = ''
        } else if (this.imageCompany.indexOf('/uploads/') == -1) {
          condition.service_setting.logo = this.imageCompany
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`サービス情報を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('サービス情報を正しく登録できませんでした')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.companyFlag) {
        this.basicLock = true
        var pt = this.$route.path
        pt = pt.split('/edit/')
        if (pt[1] !== this.$Cookies.get('companyId')) {
          router.push({ path: '/admin/login'})
          return false
        }
      } else if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getService()
    },
    watch: {
      serviceName(){
        this.touched.serviceName = true
      },
      apiUrl(){
        this.touched.apiUrl = true
      },
      trackingId(){
        this.touched.trackingId = true
      },
      fcmKey(){
        this.touched.fcmKey = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.pt20{
  padding-top: 20px;
}

.is-l-none{
  display: none;
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
