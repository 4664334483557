<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{titleWord}}履歴</h4>
        </md-card-header>
        <md-card-content class="tipsVisible">
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">決済日</div>
                <div class="tHead_th">金額</div>
                <div class="tHead_th">トランザクション番号</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td">{{ item.paymentDay | dateFormat }}</div>
                <div class="tItem_td">¥{{ item.price | numberWithDelimiter }}</div>
                <div class="tItem_td">{{ item.number }}</div>
                <div class="tItem_td taR td_actionTw">
                  <md-button
                    class="md-just-icon md-warning mg2"
                    @click.native="accessDetail(item.id)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter}}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getHistory">
          </Pagination>
        </md-card-actions>
      </md-card>
      <div class="btnArea">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
      </div>
    </div>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + this.tableData.length
        }
      },
      from () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + 1
        }
      }
    },
    data () {
      return {
        titleWord: '支払',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      accessDetail (param) {
        router.push({ path: '/admin/house_money_detail/' + this.$route.params.type + '/' + param  })
      },
      returnBack () {
        router.go(-1)
      },
      getHistory (page) {
        var condition = {
          page: page,
          user_id: this.$route.params.id,
          history_type: this.$route.params.type === 'charge' ? 'charge' : 'payment'
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/payments/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            swal({
              title: `履歴データがありませんでした`,
              buttonsStyling: false,
              type: 'warning',
              confirmButtonClass: 'md-button md-success'
            }).then(function () {
              router.push({ path: '/admin/house_money_list/'})
            })
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('履歴情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            paymentDay: res[i].attributes['created-at'],
            price:  res[i].attributes['total-price'],
            number:  res[i].attributes['transaction-id']
          }
          this.tableData.push(item)
        }
      }
    },
    mounted () {
      // role check
      if (!this.opt_houseMoney) {
        router.push({ path: '/admin/login'})
        return false
      }

      if (this.$route.params.type === 'charge') {
        this.titleWord = 'チャージ'
      } else {
        this.titleWord = '支払'
      }
      this.getHistory()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.btnSpace{
  margin: 0 2px;
}

.btnArea{
  margin-top: 60px;
  text-align: center;
}
</style>
